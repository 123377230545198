import React, { useEffect } from 'react'
import images from '../../services/images'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default function About() {

  useEffect(() => {
    handleScrollToTop()
  }, [])

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className='bg-purplegray'>
      <div className=''>
        <div className='lg:p-10 md:p-10 sm:p-10 p-0 relative w-full'>
          <img
            src={images.aboutimg}
            alt=''
            className="w-full lg:h-55vh md:h-55vh sm:h-55vh h-55vh object-cover rounded"
          />
          <div className='absolute lg:bottom-20 md:bottom-20 sm:bottom-20 bottom-10 lg:left-20 md:left-20 sm:left-20 left-10 text-white '>
            <div className='lg:text-6xl  md:text-6xl  sm:text-6xl  text-5xl  font-bold '>
            About
            </div>   
            <div>
              <div className='w-66p  lg:text-lg  md:text-lg sm:text-lg text-sm   mt-1'>
              We redefine the standards of companionship, offering a seamless and secure way to connect with captivating individuals who are dedicated to making your moments unforgettable
              </div>

            </div>
          </div>
        </div>

        <div className='px-7 lg:pl-20 md:pl-20 sm:pl-20 pl-10 flex flex-col items-start pt-20'>
          <div className='text-purple lg:text-5xl md:text-5xl sm:text-5xl text-3xl font-bold'>
          Unveil the Allure of Live Cam Experiences
          </div>
          <div className='flex items-center text-white pt-5 pb-20 w-full justify-between lg:text-base  md:text-base sm:text-base text-sm' >
            <div className='w-75p '>
            Enter the captivating world of live streaming intimacy, where our performers redefine the art of connection. Immerse yourself in real-time experiences that bring passion and authenticity directly to your screen. Whether you crave engaging conversations or visually stunning moments, our live streamers are here to turn your desires into reality. Join the excitement and indulge in the irresistible allure of live streaming like never before
            </div>
          </div>
        </div>
        <div className='px-7 flex flex-col  lg:pl-20 md:pl-20 sm:pl-20 pl-10 items-start   '>
          <div className='text-purple  font-bold lg:text-5xl md:text-5xl sm:text-5xl text-3xl'>
          Elevate Every Encounter
          </div>
          <div className='flex items-center text-white pt-5 pb-20 w-full justify-between lg:text-base  md:text-base sm:text-base text-sm' >
            <div className='w-75p mb-40 '>
            Indulge in the epitome of companionship with our exquisite escorts who redefine the art of connection. Immerse yourself in the company of captivating individuals dedicated to making every moment memorable. Our escorts embody sophistication, warmth, and a genuine commitment to fulfilling your desires. Whether you seek engaging conversation, companionship for social events, or intimate encounters, our curated selection of escorts ensures a bespoke experience tailored to your preferences. Elevate your social engagements and intimate moments with the enchanting presence of our escorts, turning every encounter into a story of unparalleled indulgence.     </div>
          </div>
        </div>
      </div>
    </div>
  )
}
