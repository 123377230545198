import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RulesAndPosting() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Rules & Posting Guidelines
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-4">
              LeoList seeks to create a safe and welcoming environment for users
              to post and respond to ads. We have provided the following
              guidelines to make sure LeoList users may interact freely, be
              assured of respect, and harmful and illegal activities are
              avoided.
            </div>

            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              In your interaction with others, always be respectful. Follow the
              golden rule: if you don't have anything nice to say, don't say
              anything at all.
            </div>
            <div className="mt-4  text-xl">
              <b>Reviews</b>
            </div>

            <div className=" border-b pb-10  border-whitepink border-opacity-40">
              LeoList is NOT a review board. Users may NOT link to reviews on
              other sites
            </div>
            <div className="mt-4 text-xl">
              <b>Content Violations</b>
            </div>
            <div className="">
              The content you post -- words, pictures, videos, images, etc. --
              should not violate the rights of others and should not contain or
              condone defamatory, fraudulent, deceptive, abusive, drug-related
              or illegal activities.
            </div>
            <div className="mt-4 ">
              Given the amount of content that is posted, we are unable to
              monitor it all. We ask that you as a community assist us in making
              LeoList a pleasant environment. If you have a reasonable belief
              any posted content would violate our Terms of Service, gives
              misleading information, or serves the purpose of harassing or
              endangering yourself or others -- please report it to us here.
            </div>
            <div className="mt-4 ">
              If you suspect that content posted breaks the law by violating
              copyright law, please report that here.
            </div>
            <div className="mt-4 ">
              If you have a reason to suspect that content distributed might be
              of suspected criminal activity -- please report it immediately to
              the appropriate law enforcement agency.
            </div>
            <div className="mt-4 ">
              Once contacted by the proper authorities, LeoList will cooperate
              to the fullest extent possible. However, LeoList does not have
              capability to investigate or offer meaningful resolution – if you
              suspect or believe you have been the victim of a crime – please
              report it to the proper law enforcement agency. Only by reporting
              illegal activity to the appropriate authorities, can you assist us
              by effectively ensuring that LeoList remains a safe environment.
            </div>
            <div className="mt-4 border-b pb-10  border-whitepink border-opacity-40">
              We cannot act on allegations or suspicions. Bear in mind when
              reporting a violation of our Terms of Service that the information
              must be truthful. If we detect on-going patterns of harassment in
              our reporting system, this may be treated as abuse of our Terms of
              Service
            </div>
            <div className="mt-4 text-xl">
              <b>Ad Rules</b>
            </div>
            <div>• Always post ads in the correct category</div>
            <div>
              • Users can post as many paid ads as they like. No one likes spam
              though, please respect your fellow advertisers by not over doing
              it.
            </div>
            <div>
              • You can only post 1 free ad per day. If you want to post more
              ads, please purchase an upgrade, or when you try to post your 3rd
              ad, follow the ‘get back in action’ steps. *Only applicable to the
              free sections of the site.
            </div>
            <div>
              • Free users are permitted to have 1 account. If you are a paid
              user, you can have more than 1 account provided they are all paid
              accounts. Creating multiple accounts to post free ads in unpaid
              categories is not permitted.
            </div>
            <div>
              • We encourage you to promote your website on LeoList to increase
              your traffic. You may link to your website as allowed by the Terms
              of Service. However we ask that you do NOT use LeoList to promote
              competing websites.
            </div>
            <div>• Always use your common sense and better judgement.</div>
            <div>
              • The advertisement of goods such as weapons, prescription and
              illegal drugs, used or recalled food and cosmetics; ID cards,
              counterfeit or pirated items, and child pornography, or the
              advertisement of services related to offers to provide sexual
              services for consideration, or human trafficking, or the
              exploitation or endangerment of minors is strictly prohibited.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
