import React, { useEffect, useState } from "react";
import images from "../../services/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faL, faLeaf } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setUsereData } from "../../store/userData";
import {
  changePasswordEscort,
  changePasswordUser,
  deleteAccount,
  escortSignup,
  updateEscortProfile,
  updateUserProfile,
  uploadProfile,
} from "../../services/API";
import { selectLogedIn } from "../../store/logedIn";
import { removeAuthToken, selectToken, setAuthToken } from "../../store/token";
import { ClipLoader } from "react-spinners";

export default function Setting() {
  const dispatch = useDispatch();
  const userLogin = useSelector(selectLogedIn);

  const authToken = useSelector(selectToken);

  const userData = useSelector(selectUserData);

  const [changepassshow, setchangeshow] = useState(true);
  const [selectdelete, setselectdelete] = useState(false);
  const [paymentinfo, setpaymentinfo] = useState(false);
  const [Privacypolicy, setprivacypolicy] = useState(false);
  const [DeleteAccount, setDeleteAccount] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [about, setAbout] = useState("");
  const [changeprofilepic, setchangeprofilepic] = useState();
  const [confirmPasswordDelete, setConfirmPasswordDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const { isLoggedIn, logout, userloggedin } = useAuth();
  const [showone, setshowone] = useState("Add");
  const [error, setError] = useState("validated");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);

  const token = useSelector(selectToken);

  useEffect(() => {
    setFirstname(userData?.firstname);
    setLastname(userData?.lastname);
    setAbout(userData?.about);
    setchangeprofilepic(userData?.profilePhoto);
  }, [userData]);

  const islogout = () => {
    logout();
  };

  const toggleprofileimage = async (e) => {
    try {
      const selectedFile = e.target.files[0];
      const formData = new FormData();
      formData.append("image", selectedFile);
      const response = await uploadProfile(formData);
      setchangeprofilepic(response?.url);
    } catch (error) {
      console.log(error);
    }
    // const file = e.target.files[0];

    // if (file) {
    //   const imageURL = URL.createObjectURL(file);

    //   setchangeprofilepic(imageURL);
    // }
  };

  const handleUserProfileUpdate = async () => {
    try {
      const body = {
        firstname,
        lastname,
        profilePhoto: changeprofilepic,
      };
      const response = await updateUserProfile(body, authToken);

      if (response.success) {
        dispatch(setUsereData(response?.updatedUser));
      } else {
        console.log(response?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEscortProfileUpdate = async () => {
    try {
      const body = {
        firstname,
        lastname,
        profilePhoto: changeprofilepic,
        about,
      };
      const response = await updateEscortProfile(body, authToken);
      if (response.success) {
        dispatch(setUsereData(response?.updatedEscort));
      } else {
        console.log(error.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const close = () => {
    setshowone(null);
  };

  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (confirmPasswordDelete) {
      setLoader(true);
      const data = await deleteAccount(
        { password: confirmPasswordDelete },
        token
      );
      if (data.success) {
        setSuccessModal(true);
        setLoader(false);
        setDeleteModal(false);
        setErrorMessage(data.message);
        dispatch(removeAuthToken());
        logout();
        navigate("/");
      } else {
        setErrorMessage(data.message);
        setLoader(false);
        setDeleteModal(false);
      }
    } else {
      setErrorMessage("Please fill out the field");
      setLoader(false);
      setDeleteModal(false);
    }
  };

  const validatePasswords = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      setPasswordError("All fields are required.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("New password and confirm password do not match.");
      return false;
    }
    setPasswordError(""); // Clear any previous error message
    return true;
  };
  const handleChangePassword = async () => {
    if (validatePasswords()) {
      if (userLogin) {
        try {
          setPasswordLoader(true);

          let response = await changePasswordUser(
            { oldPassword, newPassword },
            authToken
          );
          console.log(response);
          if (!response.success) {
            setPasswordError(response.message);
            setPasswordLoader(false);
          } else {
            setShowModal(true);
            setPasswordLoader(false);
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
          }
        } catch (error) {
          console.log(error);
          setPasswordLoader(false);
        }
      } else {
        try {
          setPasswordLoader(true);

          let response = await changePasswordEscort(
            { oldPassword, newPassword },
            authToken
          );
          console.log(response);
          if (!response.success) {
            setPasswordError(response.message);
            setPasswordLoader(false);
          } else {
            setShowModal(true);
            setPasswordLoader(false);

            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
          }
        } catch (error) {
          console.log(error);
          setPasswordLoader(false);
        }
      }
    }
  };
  const manageDelete = () => {
    if (!confirmPasswordDelete) {
      setErrorMessage("Please fill out the field");
    } else {
      setErrorMessage("");
      setDeleteModal(true);
    }
  };
  return (
    <div className="bg-purplegray">
      <div className="pb-20">
        <div className="flex items-center justify-between  pb-10 pt-10">
          <div className="lg:w-[59.77px] lg:h-[60px] md:w-12 md:h-10 sm:w-7 sm:h-7 w-10 h-7  relative mx-[2%] my-[2%]">
            <Link to={"/"}>
              <div className="w-[100%] h-[100%] flex items-center justify-center cursor-pointer acitve:opacity-50 bg-fuchsia-100">
                <img src={images.arrowleft} className="w-5" />
              </div>
            </Link>
          </div>
          <div className="text-center text-fuchsia-100 lg:text-5xl md:text-3xl sm:text-2xl text-xl font-semibold uppercase absolute left-[42%]">
            Settings
          </div>
        </div>
        <div className="flex md:flex-row flex-col lg:justify-center md:justify-center sm:justify-start justify-start md:items-start items-center md:gap-0 gap-4 ">
          <div className="lg:w-[28%] md:w-40p w-90p md:mx-0   bg-footercolor rounded-3xl flex flex-col items-center justify-start h-50p">
            <div className="">
              <div className="bg-footercolor w-full rounded-2xl">
                <div className="w-full pt-4">
                  <div className="flex flex-col items-center justify-center pt-10">
                    {userLogin ? (
                      <div>
                        <div className="border-2 rounded-full  lg:w-32 lg:h-32 md:w-16 md:h-16 sm:w-16 sm:h-16 w-16 h-16  flex items-center justify-center cursor-pointer active:opacity-50">
                          <img
                            src={changeprofilepic}
                            className="w-28 h-28 cursor-pointer active:opacity-50  rounded-full "
                            alt="Profile Pic"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="border-2 flexflex-col rounded-full border-purple w-32 h-32 flex items-center justify-center cursor-pointer active:opacity-50">
                          <img
                            src={changeprofilepic}
                            className="w-28 h-28  cursor-pointer active:opacity-50  rounded-full "
                            alt="Profile Pic"
                          />
                          <div className="absolute bg-purple mt-28 w-24 text-sm py-1 text-center text-white rounded-lg">
                            Model
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="pt-4 flex items-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-fuchsia-100 lg:text-lg md:text-base sm:text-sm text-xs  ">
              {`${userData?.firstname} ${userData?.lastname}`}
            </div>
            <div className="w-[75%] my-[3%] text-center text-fuchsia-100 lg:text-sm md:text-sm sm:text-xs text-xxs ">
              {userData?.about}
            </div>
            {!userLogin && (
              <>
                <div className="my-[3%]   justify-evenly w-[100%] items-center  inline-flex">
                  <div className="flex-col border-r border-whitepink border-opacity-40 w-35p justify-start items-center gap-[5px] inline-flex">
                    <div className="pr-20">
                      <div className="text-center text-fuchsia-100  text-[100%] font-medium ">
                        2.5K
                      </div>
                      <div className="text-center text-fuchsia-600 text-[100%] font-medium ">
                        Follower
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-center  inline-flex ">
                    <div className="text-center text-fuchsia-100 text-[100%] font-medium ">
                      2.5K
                    </div>
                    <div className="text-center text-fuchsia-600 text-[100%] font-medium ">
                      Subscriber
                    </div>
                  </div>
                </div>
              </>
            )}

            <div
              className="flex flex-warp   items-center justify-center gap-4 w-full   
            biggerlaptop:flex-row flex-col  pb-5   "
            >
              <div className="lg:w-48 lg:h-36 md:w-40 sm:w-40 w-26 flex flex-col lg:pt-0 lg:pb-0 md:pt-2 sm:pt-2  sm:pb-2 pt-2 pb-2  items-center justify-center rounded-3xl border  border-whitepink border-opacity-60 ">
                <img
                  src={images.discount}
                  alt=""
                  className="lg:w-auto  md:w-7 sm:w-5 w-5 "
                />
                <div className="px-1 text-center lg:text-base md:text-sm sm:text-sm text-xs text-white pt-5 w-40 ">
                  Subscription Live Models
                </div>
              </div>
              <div className="lg:w-48 lg:h-36 md:w-40 sm:w-40 w-26 flex flex-col lg:pt-0 lg:pb-0 md:pt-2 sm:pt-2  sm:pb-2 pt-2 pb-2  items-center justify-center rounded-3xl border  border-whitepink border-opacity-60 ">
                <img
                  src={images.neaHeart}
                  alt=""
                  className="lg:w-8  md:w-7 sm:w-5 w-5 "
                />
                <div className="px-1  text-center lg:text-base md:text-sm sm:text-sm text-xs text-white pt-5 w-40 ">
                  Subscription Live Models (live models)
                </div>
              </div>
              {/* <div className="lg:w-48  lg:h-36 md:w-40 sm:w-40 w-32 lg:pt-0 lg:pb-0 md:pt-2 md:pb-2 sm:pt-2 sm:pb-2 pt-2 pb-2 flex flex-col items-center justify-center rounded-3xl border mt-2 border-whitepink border-opacity-60 ">
                <img
                  src={images.neaHeart}
                  alt=""
                  className="lg:w-8 md:w-7 sm:w-5 w-5 "
                />
                <div className="text-center lg:text-base md:text-sm sm:text-sm text-xs text-white pt-5 w-70p ">
                  Following/Favorites (live models)
                </div>
              </div> */}
            </div>
            <div className="border-b w-90p  pt-4 pb-4 border-whitepink border-opacity-40 mx-auto"></div>
            <div className=" w-full  pt-10 space-y-5">
              {/* Addbutton */}
              <>
                <div
                  className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50"
                  onClick={() => setshowone("Add")}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        showone == "Add" ? images.purpleadd : images.whiteadd
                      }
                      alt=""
                      className="w-4 h-4"
                    />
                    <div
                      className={`ml-5  lg:flex md:flex   ${
                        showone === "Add" ? "text-purple" : "text-white"
                      }`}
                    >
                      Edit profile
                    </div>
                  </div>
                  <img
                    src={
                      showone === "Add"
                        ? images.purplearrowright
                        : images.Vector4
                    }
                    alt=""
                    className="w-3 h-5"
                  />
                </div>
              </>
              {/* Changepass */}
              <>
                <div
                  className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50"
                  onClick={() => setshowone("password")}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        showone === "password" ? images.Lock : images.whitelock
                      }
                      alt=""
                      className="w-3 h-4"
                    />
                    <div className="">
                      <div
                        className={`ml-6 lg:flex md:flex sm:   ${
                          showone === "password" ? "text-purple" : "text-white"
                        }`}
                      >
                        Password
                      </div>
                    </div>
                  </div>
                  <img
                    src={
                      showone === "password"
                        ? images.purplearrowright
                        : images.Vector4
                    }
                    alt=""
                    className="w-3 h-5"
                  />
                </div>
                {/* {
                  !userLogin &&
                  <div className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50">
                    <div className="flex items-center">
                      <img src={images.Category} alt="" className="w-4 h-4" />
                      <div className="text-white ml-5  lg:flex md:flex sm:  ">
                        Check Listings
                      </div>
                    </div>
                    <img src={images.Vector4} alt="" className="w-3 h-5" />
                  </div>
                } */}
              </>
              {/* PaymentInfo */}

              <>
                <div
                  className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50"
                  onClick={() => setshowone("payment")}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        showone === "payment"
                          ? images.purplewallet
                          : images.Wallet
                      }
                      alt=""
                      className="w-4 h-4"
                    />
                    <div>
                      <div
                        className={`ml-5   lg:flex md:flex sm:  ${
                          showone === "payment" ? "text-purple" : "text-white"
                        }`}
                      >
                        Payment Info
                      </div>
                    </div>
                  </div>
                  <img
                    src={
                      showone === "payment"
                        ? images.purplearrowright
                        : images.Vector4
                    }
                    alt=""
                    className="w-3 h-5"
                  />
                </div>
              </>
              {/* Privacy policy */}
              <>
                <div
                  className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50"
                  // onClick={() => setshowone("privacy")}
                  onClick={() => navigate("/policy")}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        showone === "privacy"
                          ? images.purpledocument
                          : images.Document
                      }
                      alt=""
                      className="w-4 h-4"
                    />
                    <div>
                      <div
                        className={`ml-5   lg:flex md:flex sm:  ${
                          showone === "privacy" ? "text-purple" : "text-white"
                        }`}
                        onClick={() => navigate("/policy")}
                      >
                        Privacy Policy
                      </div>
                    </div>
                  </div>
                  <img
                    src={
                      showone === "privacy"
                        ? images.purplearrowright
                        : images.Vector4
                    }
                    alt=""
                    className="w-3 h-5"
                  />
                </div>
              </>
              {/* Delete account */}
              <>
                <div
                  className="flex w-full justify-between pl-10 pr-10 cursor-pointer active:opacity-50"
                  onClick={() => setshowone("deleteaccount")}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        showone === "deleteaccount"
                          ? images.purpledelete
                          : images.Delete
                      }
                      alt=""
                      className="w-4 h-4"
                    />
                    <div>
                      <div
                        className={`ml-5   lg:flex md:flex sm:  ${
                          showone === "deleteaccount"
                            ? "text-purple"
                            : "text-white"
                        }`}
                      >
                        Delete Account
                      </div>
                    </div>
                  </div>
                  <img
                    src={
                      showone === "deleteaccount"
                        ? images.purplearrowright
                        : images.Vector4
                    }
                    alt=""
                    className="w-3 h-5"
                  />
                </div>
              </>
            </div>
            <Link to={"/"} className="w-full" onClick={islogout}>
              <div className=" pt-10 w-full flex items-center justify-center pb-10">
                <div className="bg-purple flex  lg:w-28 md:w-28 sm:w-28 w-20 lg:text-base md:text-base sm:text-sm text-xs items-center justify-evenly h-12 text-white font-semibold  cursor-pointer active:opacity-50">
                  <img
                    src={images.gottobutton}
                    alt=""
                    className="lg:w-8 lg:h-8 md:w-5 md:h-5 sm:w-5 sm:h-5 w-5 h-5"
                  />
                  Signout
                </div>
              </div>
            </Link>
          </div>

          {/* ALL the stuff */}
          <div className="flex flex-col lg:w-40p md:w-50p w-90p">
            {showone === "Add" ? (
              <div>
                <div className="bg-footercolor md:ml-5 w-full rounded-2xl  ">
                  <div className="flex w-full items-center justify-between lg:pl-10 lg:pr-16 pl-4 pr-4 pt-10">
                    <div className="font-semibold text-white lg:text-xl md:text-base sm:text-sm text-xs cursor-pointer active:opacity-50">
                      Edit Profile
                    </div>
                    <div
                      className="text-purple lg:text-xl md:text-base sm:text-sm text-sm cursor-pointer active:opacity-50 font-semibold"
                      // onClick={handleEscortupdate()}
                      onClick={close}
                    >
                      Close
                    </div>
                  </div>
                  <div className="w-full pt-4  ">
                    <div className="flex flex-col items-center justify-center ">
                      {userLogin ? (
                        <div>
                          <div className="border-2 rounded-full  lg:w-28 lg:h-28 md:w-16 md:h-16 sm:w-16 sm:h-16 w-16 h-16  flex items-center justify-center cursor-pointer active:opacity-50">
                            <label htmlFor="fileInput">
                              <img
                                src={changeprofilepic || userData?.profilePhoto}
                                className="lg:w-24 lg:h-24 md:w-13 md:h-13 sm:w-13 h:h-13 w-13 h-13 cursor-pointer active:opacity-50  rounded-full "
                                alt="Profile Pic"
                              />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="border-2 rounded-full border-purple lg:w-28 lg:h-28 md:w-16 md:h-16 sm:w-16 sm:h-16 w-16 h-16 flex items-center justify-center cursor-pointer active:opacity-50">
                            <label htmlFor="fileInput">
                              <img
                                src={changeprofilepic || userData?.profilePhoto}
                                className="lg:w-24 lg:h-24 md:w-13 md:h-13 sm:w-13 h:h-13 w-13 h-13 cursor-pointer active:opacity-50  rounded-full "
                                alt="Profile Pic"
                              />
                            </label>
                          </div>
                        </div>
                      )}
                      <label htmlFor="fileInput">
                        <div className="pt-4 flex items-center">
                          <img
                            src={images.uploadimg2}
                            alt=""
                            className="w-5 h-5"
                          />
                          <div className="text-white lg:text-base md:text-sm sm:text-xs text-xs border-b-2 ml-4  cursor-pointer active:opacity-50">
                            Upload Photo
                          </div>
                        </div>
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/"
                        style={{ display: "none" }}
                        onChange={toggleprofileimage}
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="w-full flex lg:flex-row md:flex-col flex-col justify-center pt-5">
                        <div className="lg:w-40p md:w-80p w-80p ml-4">
                          <div className="w-14 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                            <div className="text-center text-fuchsia-100 text-xxs opacity-70  ">
                              First name
                            </div>
                          </div>
                          <input
                            type="text"
                            className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                            placeholder="Enter Email"
                            onChange={(e) => setFirstname(e.target.value)}
                            value={firstname}
                          />
                        </div>
                        <div className="lg:w-40p md:w-80p w-80p ml-4">
                          <div className="w-14 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                            <div className="text-center text-fuchsia-100 text-xxs opacity-70 ">
                              last name
                            </div>
                          </div>
                          <input
                            type="text"
                            className="bg-transparent remove-arrow focus:outline-none text-white lg:text-lg md:text-base sm:text-sm text-xs w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                            placeholder="Last name"
                            onChange={(e) => setLastname(e.target.value)}
                            value={lastname}
                          />
                        </div>
                      </div>
                      <div className="w-full ">
                        <div className="pt-10 justify-center flex items-center">
                          {!userLogin && (
                            <div className="w-full lg:mr-16 mr-10  lg:ml-20 ml-5 ">
                              <textarea
                                name="message"
                                id="message"
                                cols="30"
                                rows="10"
                                className="resize-none w-full pt-2 rounded-xl border border-whitepink border-opacity-20  block bg-transparent text-white opacity-60 h-24 text-xs focus:outline-none pl-4"
                                placeholder=""
                                onChange={(e) => setAbout(e.target.value)}
                                value={about}
                              ></textarea>
                              <div className="w-14 h-6 justify-center bg-footercolor relative bottom-28 left-4 items-center inline-flex">
                                <div className="text-center text-whitepink text-xxs opacity-80  ">
                                  About
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex w-full justify-center pt-10 pb-5 lg:text-base md:text-sm sm:text-sm text-xs">
                        <div
                          className="bg-purple text-white w-20 flex justify-center items-center h-10 cursor-pointer active:opacity-50"
                          onClick={() =>
                            userLogin
                              ? handleUserProfileUpdate()
                              : handleEscortProfileUpdate()
                          }
                          // onClick={close}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : showone === "password" ? (
              <div className="mb-5">
                <div className="w-full bg-footercolor md:ml-5 rounded-3xl">
                  <div className="w-full flex justify-between sm:pl-4 sm:pt-5 sm:pr-4 pl-4 pt-5 pr-4 ">
                    <div className="lg:text-xl md:text-base sm:text-sm text-xs text-whitepink font-bold">
                      Change password
                    </div>
                    <div
                      className="text-purple lg:text-xl md:text-base sm:text-sm text-xs cursor-pointer active:opacity-50 font-semibold"
                      onClick={close}
                    >
                      Close
                    </div>
                  </div>
                  <div className="flex flex-col lg:pl-6">
                    <div className="lg:w-40p w-80p ml-4">
                      <div className="w-20 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xxs opacity-70 ">
                          Old password
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent remove-arrow focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Enter Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                    <div className="lg:w-40p w-80p ml-4">
                      <div className="w-20 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                          New password
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent remove-arrow focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Enter New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="lg:w-40p w-80p ml-4">
                      <div className="w-24 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                          Confirm password
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent remove-arrow focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Re-enter New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    {passwordError && (
                      <div className="mt-2 text-red-500 pl-6">
                        *{passwordError}
                      </div>
                    )}
                  </div>
                  {/*  */}
                  <div className="w-full flex justify-center pt-10 pb-10">
                    {passwordLoader ? (
                      <div className="bg-purple w-24 h-10 flex justify-center items-center text-white cursor-pointer active:opacity-50 ">
                        <ClipLoader
                          color="#FFFF"
                          cssOverride={{
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="bg-purple w-24 h-10 flex justify-center items-center text-white cursor-pointer active:opacity-50 "
                        onClick={handleChangePassword}
                      >
                        Save
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : showone === "payment" ? (
              <div className=" mb-5 ">
                <div className="w-full bg-footercolor md:ml-5 rounded-3xl lg:p-10 md:p-10  ">
                  <div className="w-full flex justify-between sm:pl-4 sm:pt-5 sm:pr-4 pl-4 pt-5 pr-4 ">
                    <div className="font-semibold text-white lg:text-xl md:text-xl sm:text-sm text-xs">
                      Payment Info
                    </div>
                    <div
                      className="text-purple lg:text-xl md:text-xl sm:text-sm text-xs cursor-pointer active:opacity-50 font-semibold"
                      onClick={close}
                    >
                      Close
                    </div>
                  </div>
                  <div className="pt-10">
                    <div className="bg-buttoncolor flex lg:flex-row md:flex-row flex-col w-full justify-between rounded-3xl">
                      <div className="flex">
                        <div className="flex flex-col text-whitepink opacity-40 p-4 ">
                          <div className="lg:text-2xl md:text-xl sm:text-lg ">
                            Credit / Debit Cards
                          </div>
                          <div className="text-xxs">
                            Save and pay via card (Safe & Secure)
                          </div>
                        </div>
                      </div>
                      <div className="bg-purple lg:ml-0 lg:mb-0  ml-5  lg:mr-10 md:mr-5 h-8 flex items-center justify-center lg:mt-5 md:mt-5 mb-5 w-28 cursor-pointer active:opacity-50">
                        <div className="text-white text-xs">Add New Card</div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-10">
                    <div className="bg-buttoncolor rounded-3xl">
                      <div className="flex justify-between pl-4 pt-4 lg:pr-4  items-center ">
                        <div className="text-whitepink opacity-40 lg:text-xl md:text-base sm:text-sm text-xs ">
                          {" "}
                          Add Card{" "}
                        </div>
                        <div className="flex flex-wrap gap-1">
                          <img
                            src={images.Visa}
                            alt=""
                            className=" lg:h-5 h-4   "
                          />
                          <img
                            src={images.Visa2}
                            alt=""
                            className="lg:h-6 h-5"
                          />
                          <img
                            src={images.amex}
                            alt=""
                            className="lg:h-5 h-4"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="w-90p ml-4">
                          <div className="w-20 h-6 bg-buttoncolor justify-center relative top-3 left-3 items-center  inline-flex">
                            <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                              Card Holder
                            </div>
                          </div>
                          <input
                            type="text"
                            className="bg-transparent remove-arrow focus:outline-none text-white lg:text-base md:text-sm sm:text-xs text-xxs w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                            placeholder="Name"
                          />
                        </div>
                        <div className="w-90p ml-4">
                          <div className="w-20 h-6 bg-buttoncolor justify-center relative top-3 left-3 items-center  inline-flex">
                            <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                              Card Number
                            </div>
                          </div>
                          <input
                            type="Number"
                            className="bg-transparent remove-arrow lg:text-base md:text-sm sm:text-xs text-xxs focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                            placeholder="Number"
                          />
                        </div>
                        <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col">
                          <div className="lg:w-50p md:w-50p w-80p ml-4">
                            <div className="w-16 h-6 bg-buttoncolor justify-center relative top-3 left-3 items-center  inline-flex">
                              <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                                Expiry Date
                              </div>
                            </div>
                            <input
                              type="Number"
                              className="bg-transparent remove-arrow lg:text-base md:text-sm sm:text-xs text-xxs focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                              placeholder="Date"
                            />
                          </div>
                          <div className="lg:w-38p md:w-38p w-80p ml-4">
                            <div className="w-10 h-6 bg-buttoncolor justify-center relative top-3 left-3 items-center  inline-flex">
                              <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                                CVV
                              </div>
                            </div>
                            <input
                              type="Number"
                              className="bg-transparent remove-arrow lg:text-base md:text-sm sm:text-xs text-xxs focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                              placeholder="CVV"
                            />
                          </div>
                        </div>
                        <div className="w-full flex justify-center pt-10 pb-5">
                          <div
                            className="bg-purple flex h-7   lg:h-10 justify-center items-center lg:text-base md:text-sm sm:text-xs text-xs w-20 lg:w-44 text-white cursor-pointer active:opacity-50"
                            onClick={close}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Delete payment */}
                  <div className="pt-10">
                    <div className="bg-buttoncolor  lg:p-10 md:p-3 p-3  flex lg:flex-row flex-col items-center   justify-between rounded-3xl">
                      <div className="flex lg:flex-row md:flex-row flex-row">
                        <img
                          src={images.Visa3}
                          alt=""
                          className=" lg:w-auto lg:h-10 h-7 w-10"
                        />
                        <div className="flex flex-col lg:pl-4 pl-4 lg:pt-0 md:pt-0  text-whitepink">
                          <div className="opacity-40 text-xs">
                            Florence J. Smith
                          </div>
                          <div className="font-semibold lg:text-md sm:text-sm text-xs text-md">
                            ***********6489
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className="flex"
                          onClick={() => setselectdelete(!selectdelete)}
                        >
                          {selectdelete ? (
                            <div className="border-1  rounded-full w-5 h-5 flex items-center justify-center bg-purple">
                              <div className="  ">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="1x"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    height: "10px",
                                    width: "10px",
                                    cursor: "pointer",
                                    color: "transparent",
                                    border: "2px solid #4c2f4d ",
                                  }}
                                />{" "}
                              </div>
                            </div>
                          ) : (
                            <div className="border-2 rounded-full w-5 h-5 flex items-center  justify-center">
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-10 w-full flex justify-center items-center">
                    <div className="bg-purple flex h-7   lg:h-10 justify-center items-center lg:text-base md:text-sm sm:text-xs text-xs w-20 lg:w-44 text-white cursor-pointer active:opacity-50">
                      Delete Card
                    </div>
                  </div>
                </div>
              </div>
            ) : showone === "privacy" ? (
              <div>
                <div className="w-full bg-footercolor md:ml-5 rounded-3xl lg:p-10 pl-4 pt-10 pr-4 pb-10">
                  <div className="w-full flex justify-between ">
                    <div className="text-white lg:text-xl md:text-lg sm:text-sm text-xs  font-semibold">
                      Privacy Policy
                    </div>
                    <div
                      className="text-purple lg:text-xl md:text-lg sm:text-sm text-xs cursor-pointer active:opacity-50 font-semibold"
                      onClick={close}
                    >
                      {" "}
                      Close
                    </div>
                  </div>
                  <div className="pt-10 ">
                    <div className="text-purple lg:text-2xl md:text-xl sm:text-sm text-xs font-bold pb-2">
                      Lorem ipsum
                    </div>
                    <div className="text-white lg:text-sm md:text-xs sm:text-xxs text-xxs">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </div>
                  </div>
                  <div className="pt-10 ">
                    <div className="text-purple lg:text-2xl md:text-xl sm:text-sm text-xs font-bold pb-2">
                      Lorem ipsum
                    </div>
                    <div className="text-white lg:text-sm md:text-xs sm:text-xxs text-xxs">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </div>
                  </div>
                </div>
              </div>
            ) : showone === "deleteaccount" ? (
              <div className="">
                <div className="w-full bg-footercolor md:ml-5 rounded-3xl lg:p-10 pl-4 pt-10 pb-10 pr-4">
                  <div className="flex w-full justify-between pb-5">
                    <div className="text-white lg:text-xl md:text-lg sm:text-sm text-xs font-semibold">
                      Delete Account
                    </div>
                    <div
                      className="text-purple lg:text-xl md:text-lg sm:text-sm text-xs cursor-pointer active:opacity-50"
                      onClick={close}
                    >
                      Close
                    </div>
                  </div>
                  <div className="lg:w-42p w-full ">
                    <div className="w-28 h-6 bg-footercolor justify-center relative top-3 left-3 items-center  inline-flex">
                      <div className="text-center text-fuchsia-100 text-xxs opacity-70 text-nowrap ">
                        Confirm password
                      </div>
                    </div>
                    <input
                      type="text"
                      className="bg-transparent remove-arrow focus:outline-none text-white text-base w-full border rounded-xl border-whitepink border-opacity-20 h-12 pl-5 "
                      placeholder=""
                      value={confirmPasswordDelete}
                      onChange={(e) => setConfirmPasswordDelete(e.target.value)}
                    />
                    {errorMessage && (
                      <div className="mt-2 text-red-500">*{errorMessage}</div>
                    )}
                  </div>
                  <div className="w-full flex justify-center items-center pt-10">
                    <div
                      className="bg-purple flex h-7   lg:h-10 justify-center items-center lg:text-base md:text-sm sm:text-xs text-xs w-17 lg:w-44 text-white cursor-pointer active:opacity-50"
                      onClick={manageDelete}
                    >
                      Delete Account
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {deleteModal && (
              <div className="fixed z-10 items-center justify-center flex  top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 text-white">
                <div className="bg-footercolor w[90%] sm:w[80%] md:w[60%] lg:w[30%] h-20p rounded-lg items-center justify-center flex flex-col gap-6 px-4">
                  <div className="">
                    Are you sure you want to delete account?
                  </div>
                  <div className="flex gap-4 items-center">
                    {loader ? (
                      <div className="cursor-pointer active:opacity-50 border border-whitepink border-opacity-20 bg-purple flex  justify-center  py-1 px-5">
                        <ClipLoader
                          color="#FFFF"
                          cssOverride={{
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer active:opacity-50 border border-whitepink border-opacity-20 bg-purple flex  justify-center  py-1 px-5"
                        onClick={handleDeleteAccount}
                      >
                        Yes
                      </div>
                    )}

                    <div
                      className="cursor-pointer active:opacity-50 border border-whitepink border-opacity-20 py-1 px-5 flex  justify-center"
                      onClick={() => setDeleteModal(false)}
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
            )}
            {successModal && (
              <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                  <img src={images.tickmarkdone} className="w-[50%]" />
                  <div className="font-semibold font-lg">{errorMessage}</div>
                  <div
                    className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                    onClick={islogout}
                  >
                    Done
                  </div>
                </div>
              </div>
            )}
            {showModal && (
              <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                  <img src={images.tickmarkdone} className="w-[50%]" />
                  <div className="font-semibold font-lg">
                    Password change successfully.
                  </div>
                  <div
                    className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                    onClick={() => setShowModal(false)}
                  >
                    Done
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
