import React, { useRef, useState } from "react";
import images from "../../services/images";
import { useModal } from "../../screens/Layout";
import { upload } from "@testing-library/user-event/dist/upload";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { baseURL, createInitialListing } from "../../services/API";
import { useDispatch } from "react-redux";
import { setUsereData } from "../../store/userData";

export default function Uplaodphotoscomponent() {

  const dispatch = useDispatch()

  const { Modalcomponent, setmodalcomponent } = useModal();
  const fileInputRef = useRef(null);

  const {
    location,
    age,
    height,
    hourlyRate,
    description,
    number,
    takeUsername,
    UserEmail,
  } = useAuth();

  const handleCreateListing = async () => {
    try {
      const urls = await handleUpload(uploadimg)
      const body = {
        location,
        age,
        height,
        hourlyRate,
        description,
        number,
        photourl: urls,
        email: UserEmail,
        name: takeUsername,
        listingEmail: UserEmail,
      };
      const response = await createInitialListing(body)
      if (response.success) {
        // setError("validated")
        dispatch(setUsereData(response?.updatedEscort))
        return true
      }
      else {
        // setError(response.message)
        return false
      }
    } catch (error) { }
  };
  const handleUpload = async (files) => {
    const uploadedUrls = [];

    // Iterate over each image in the array
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("image", files[i]);

      try {
        const response = await fetch(`${baseURL}auth/uploadProfile`, {
          method: "POST",
          body: formData,
          redirect: "follow",
        });

        const result = await response.json();
        const { url } = result;

        uploadedUrls.push(url); // Store the URL in the array
      } catch (error) {
        console.error("Error:", error);
      }
    }

    // Return the array of uploaded URLs
    return uploadedUrls;
  };

  const uploadimages = [
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
    {
      image1: images.uploadedimg,
    },
  ];

  const [uploadimg, setuploadedimg] = useState([]);

  const toggleBack = () => {
    setmodalcomponent("Editlist");
  };

  const toggleForward = async () => {
    const success = await handleCreateListing()
    if (success) {
      setmodalcomponent("done");
    }
    else {
      console.log("didnt work")
    }
  };

  const [image, setImage] = useState("");
  const inputFile = useRef(null);

  const [numberOfImagesAdded, setNumberOfImagesAdded] = useState(0); // State to track number of images added

  const handleImageChange = (e) => {
    const files = e.target.files;
    const uploadimgArray = Array.from(files);
    const addedImagesCount = uploadimgArray.length; // Calculate number of images added
    const totalImagesCount = uploadimg.length + addedImagesCount; // Calculate total number of images

    if (totalImagesCount <= 9) {
      setuploadedimg((prevuploadimg) => [...prevuploadimg, ...uploadimgArray]);
      setNumberOfImagesAdded(totalImagesCount); // Update state with total number of images
    } else {
      alert("You can select up to 9 images.");
    }
  };
  const handleDeleteImage = (index) => {
    setuploadedimg((prevuploadimg) =>
      prevuploadimg.filter((_, i) => i !== index)
    );
    setNumberOfImagesAdded((prevCount) => prevCount - 1); // Decrease total count when deleting an image
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="text-white     w-full ">
      <div className="bg-footercolor h-full p-4 w-full  ">
        <div className="text-3xl  font-semibold">Escort Listing Details</div>

        <div className="w-full mt-5  space-y-10  ">
          <div
            className="w-full cursor-pointer  gap-4 flex h-40 justify-center items-center border border-dashed rounded-2xl"
            onClick={onButtonClick}
          >
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleImageChange}
              ref={inputFile}
              multiple
              accept="image/png,image/jpeg"
            />
            <img src={images.picture} className="h-14" />
            <div className="text-2xl text-whitepink">Upload Photos</div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-2">
            {uploadimg.map((image, index) => (
              <div key={index}>
                <div className="h-20" onClick={() => handleImageClick(index)}>
                  <img
                    onClick={() => handleDeleteImage(index)}
                    src={URL.createObjectURL(image)}
                    className="h-20 w-20"
                    alt={`Uploaded ${index + 1}`}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full sm:text-2xl text-sm flex justify-center">
            Pic {numberOfImagesAdded}/9
          </div>
        </div>

        <div className=" flex   p-2 flex w-full justify-between">
          <div
            className="sm:text-lg font-semibold flex border border-whitepink cursor-pointer active:opacity-50
            sm:w-40 sm:h-14  sm:p-0 p-2 text-sm items-center  justify-center gap-4"
            onClick={toggleBack}
          >
            <img src={images.smallarrowwhiteleft} className="h-4" />
            Back
          </div>
          <div
            className="sm:text-lg text-sm font-semibold flex bg-purple cursor-pointer active:opacity-50
            sm:w-40 sm:h-14  sm:p-0 p-2  items-center  justify-center gap-4"
            // onClick={toggleForward}
            onClick={() => {
              // console.log(handleUpload(uploadimg));
              toggleForward()
            }}
          >
            Complete
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="h-96 gap-10 mt-10 flex flex-col justify-center">
          <div
            className="lg:w-full    w-full rounded-xl flex items-center justify-center h-40  border border-dashed border-2 cursor-pointer active:opacity-50 "
            onClick={onButtonClick}
          >
            <div className="">
              <img src={images.picture} alt="" className="w-10 flex " />
            </div>
            <input
              style={{ display: "none" }}
              ref={inputFile}
              onChange={handleFileUpload}
              type="file"
            />
            <div className="text-white">Upload Photos</div>
          </div>
          <div className="flex w-full  mb-10 gap-2 ">
            {uploadimages.map((item, index) => (
              <div key={index} className="">
                <img
                  src={uploadimg ? item.image1 : images.Liveimgmodel1}
                  alt=""
                  className="w-16  cursor-pointer acitve:opacity-50"
                  onClick={() => {
                    setuploadedimg(!uploadimages);
                  }}
                />
              </div>
            ))}
          </div>

          <div className="w-full flex justify-center text-2xl">
            Pic 1/9
          </div>
        </div> */
}
