import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/userData";
import { deleteAd, getMyAds, toggleHideAds } from "../../services/API";
import { selectToken } from "../../store/token";
import images from "../../services/images";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default function MyAds() {
  const [hide, setHide] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [upgrades_id, setUpgrades_id] = useState("")
  const [loader, setLoader] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate();

  const userData = useSelector(selectUserData);
  const authToken = useSelector(selectToken);

  const [ads, setAds] = useState([]);
  const [dropDown, setDropDown] = useState();

  const handleGetMyAds = async () => {
    setLoader(true);
    try {
      const response = await getMyAds(userData?._id, authToken);
      setAds(response?.ads);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetMyAds();
  }, []);

  const toggleDropDown = (index) => {
    setDropDown((prevIndex) => {
      if (index === prevIndex) {
        return null;
      } else {
        return index;
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!dateString) {
      dateString = new Date();
    }

    // Short form arrays for days and months
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPM = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    // Function to determine the correct day suffix
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedTime = `${hours}:${minutes} ${amPM}`;
    const formattedDate = `${dayOfWeek}, ${month} ${day}`;
    return formattedDate;
  };

  const getSelectedValue = (item) => {
    setDeleteItem(item._id);
    setUpgrades_id(item?.upgrades?._id)
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      setDeleteModal(false);
      setLoader(true);
      let response = await deleteAd(deleteItem, upgrades_id);
      console.log("response" , response);
      if (response.success) {
        let updatedAds = ads.filter((e) => e._id !== deleteItem);
        setAds(updatedAds);
        setLoader(false);
        setSuccessModal(true);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (loader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loader]);

  const handleHide = async (adId) => {
    try {
      let response = await toggleHideAds({ adId }, authToken);
      if (response.success) {
        handleGetMyAds();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalScheduleBumps = (options) => {
    return options?.reduce((total, option) => total + option.bumps, 0);
  };
  return (
    <div className="bg-purplegray w-full pb-20">
      {loader ? (
        <div
          className="w-full pt-40 
         flex items-center justify-center"
        >
          <div
            className="fixed  flex justify-center items-center text-white bg-purplegray opacity-90"
          // style={{ height: "100vh"   }}
          >
            <ClipLoader
              color="#BE42DE"
              cssOverride={{
                height: "80px",
                width: "80px",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative">
          {ads?.length > 0 ? (
            <div className="flex flex-col gap-3 py-5">
              {ads
                ?.map((item, index) => {
                  return (
                    <div
                      className="bg-aboutcolor relative w-[95%] sm:w-[85%] md:w-[75%] lg:w-[40%] mx-auto p-4"
                      key={index}
                    >
                      <div className="text-white border-b border-whitepink  border-opacity-40 ">
                        <div className="font-bold text-md ">
                          {item?.ad_title}
                        </div>
                        <div>{formatDate(item?.createdAt)}</div>
                        <div className="pb-2">{`${item?.age} - ${item?.name} - ${item?.city}`}</div>
                      </div>

                      <div className="mt-4 flex flex-row ">
                        <div className="h-52  w-40 sm:w-42 border border-whitepink border-opacity-40 flex justify-center items-center ">
                          <div className="relative">
                            <img
                              src={item?.pictures[0]}
                              className={
                                item.hidden
                                  ? `w-42 h-48 opacity-20 `
                                  : `w-42 h-48 `
                              }
                            />
                            {item.hidden && (
                              <img
                                src={images.hide}
                                className="w-10 h-10  absolute top-[30%] left-[42%]"
                              />
                            )}
                            {item.hidden && (
                              <div className="flex items-center justify-center">
                                <div className="bg-purple mt-4 px-4 py-1  text-whitepink">
                                  Hidden
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" ml-2">
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-3 text-whitepink ">
                              <img
                                src={images.bumps}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Bumps
                                </div>
                                {item?.upgrades?.bumps ? (
                                  <div>{`${item?.upgrades?.bumps?.totalBumps}`}</div>
                                ) : (
                                  <div>-</div>
                                )}
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.callentime}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Scheduled Bumps
                                </div>
                                {item?.upgrades?.bumps
                                  ?.selectedScheduleBumps ? (
                                  <div>{`${calculateTotalScheduleBumps(
                                    item?.upgrades?.bumps?.selectedScheduleBumps
                                  )} `}</div>
                                ) : (
                                  <div>-</div>
                                )}
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.bumpDocu}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Page 1
                                </div>
                                {item?.upgrades?.pageOne
                                  ? ` ${item.upgrades.pageOne.hour === "five"
                                    ? `5`
                                    : item.upgrades.pageOne.hour === "three"
                                      ? `3`
                                      : `7`
                                  } Hours
                                
                                - $ ${item.upgrades.pageOne.cost} 
                                 `
                                  : "-"}
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.paint}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Theme
                                </div>
                                <div>
                                  {item?.upgrades?.premiumTheme
                                    ? ` ${item.upgrades.premiumTheme.hour ===
                                      "five"
                                      ? `5`
                                      : item.upgrades.premiumTheme.hour ===
                                        "three"
                                        ? `3`
                                        : `7`
                                    } Hours
                                
                                - $${item.upgrades.premiumTheme.cost} 
                                 `
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.multiImg}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Carousel
                                </div>
                                <div>
                                  {item?.upgrades?.carousel
                                    ? `${item.upgrades.carousel.hour === "three"
                                      ? `3`
                                      : item.upgrades.carousel.hour ===
                                        "six"
                                        ? `6`
                                        : `9`
                                    } Hours - $${item.upgrades.carousel.cost
                                    }  `
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.ladki}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Featured Ad
                                </div>
                                <div>
                                  {item?.upgrades?.featuredAdd
                                    ? `${item.upgrades.featuredAdd.hour === "one"
                                      ? `1`
                                      : item.upgrades.featuredAdd.hour ===
                                        "three"
                                        ? `3`
                                        : `7`
                                    } Hours - $${item.upgrades.featuredAdd.cost
                                    }  `
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.smiling}
                                className="w-6 h-6 white-filter"
                              />
                              <div className=" ">
                                <div className="text-sm font-semibold">
                                  Emojis
                                </div>
                                <div>
                                  {item?.upgrades?.emoji
                                    ? `${item.upgrades.emoji.hour} - $${item.upgrades.emoji.cost}  `
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 text-whitepink">
                              <img
                                src={images.callender}
                                className="w-6 h-6 white-filter"
                              />
                              <div>
                                <div className="text-sm font-semibold">
                                  Available Now
                                </div>
                                <div>
                                  {item?.upgrades?.availableNow
                                    ? `${item.upgrades.availableNow.hour ===
                                      "one"
                                      ? `1`
                                      : item.upgrades.availableNow.hour ===
                                        "two"
                                        ? `2`
                                        : item.upgrades.availableNow.hour ===
                                          "three"
                                          ? `3`
                                          : `4`
                                    } Hours - $${item.upgrades.availableNow.cost
                                    }  `
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-whitepink flex gap-2 pt-4">
                        <div
                          className="flex bg-footercolor w-full text-xs sm:text-base  py-2 justify-center items-center  cursor-pointer active:opacity-50"
                          onClick={() =>
                            navigate(`/MyAdsDetails/${item?._id}`, {
                              state: { item, isEdit: false },
                            })
                          }
                        >
                          View
                        </div>
                        <div
                          className="flex bg-footercolor w-full py-2 text-xs sm:text-base justify-center items-center  cursor-pointer active:opacity-50"
                          onClick={() =>
                            navigate(`/MyAdsDetails/${item?._id}`, {
                              state: { item, isEdit: true },
                            })
                          }
                        >
                          Edit
                        </div>
                        <div
                          className="flex bg-footercolor w-full text-xs sm:text-base py-2 justify-center items-center  cursor-pointer active:opacity-50"
                          onClick={() => getSelectedValue(item)}
                        >
                          Delete
                        </div>
                        <div
                          className="flex bg-footercolor w-full text-xs sm:text-base py-2 justify-center items-center  cursor-pointer active:opacity-50"
                          onClick={() => handleHide(item?._id)}
                        >
                          {item.hidden ? "Unhide" : "Hide"}
                        </div>
                      </div>
                      <div className="flex gap-2 justify-between mt-2">
                        <div
                          onClick={() =>
                            navigate(
                              `/manage-upgrades/${item?.upgrades?._id}`,
                              {
                                state: { item },
                              }
                            )
                          }
                          className="mt-3 text-xs sm:text-lg w-full flex items-center justify-center h-10 cursor-pointer
                    active:opacity-50  text-purple bg-lighterPurple font-bold border border-purple  "
                        >
                          Buy / Manage Upgrades
                        </div>
                        <div className="mt-3 text-sm sm:text-lg    text-whitepink bg-purple gap-2  flex items-center w-60 justify-center ">
                          Bump{" "}
                          <span className="bg-lighterPurple h-5 w-5  sm:w-6 sm:h-6 text-xxs sm:text-xs items-center justify-center flex  rounded-full text-purple font-bold">
                            {item?.upgrades?.bumps
                              ? `${item?.upgrades?.bumps?.remainingBumps}`
                              : `0`}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
                .reverse()}
              {deleteModal && (
                <div className="fixed z-10 items-center justify-center flex  top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 text-white">
                  <div className="bg-footercolor w[90%] sm:w[80%] md:w[60%] lg:w[30%] h-20p rounded-lg items-center justify-center flex flex-col gap-6 px-4">
                    <div className="">
                      Are you sure you want to delete this Ad?
                    </div>
                    <div className="flex gap-4 items-center">
                      <div
                        className="cursor-pointer active:opacity-50 border border-whitepink border-opacity-20 bg-purple flex  justify-center  py-1 px-5"
                        onClick={handleDelete}
                      >
                        Yes
                      </div>
                      <div
                        className="cursor-pointer active:opacity-50 border border-whitepink border-opacity-20 py-1 px-5 flex  justify-center"
                        onClick={() => setDeleteModal(false)}
                      >
                        No
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {successModal && (
                <div className="fixed z-10 text-black top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="text-white rounded-xl py-4 bg-purplegray flex items-center justify-center px-5 flex flex-col gap-5">
                    <img src={images.tickmarkdone} className="w-[50%]" />
                    <div className="font-semibold font-lg">
                      Ad Deleted Successfully.
                    </div>
                    <div
                      className="bg-purple rounded-md px-4 py-2 cursor-pointer active:opacity-50"
                      onClick={() => setSuccessModal(false)}
                    >
                      Done
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center font-semibold text-lg mt-10 text-white">
              No ads have been posted.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
