import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { useModal } from "../../screens/Layout";
import { escortSignin, userSignin } from "../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, setAuthToken, setToken } from "../../store/token";
import { setUsereData } from "../../store/userData";
import { selectLogedIn } from "../../store/logedIn";
import { ClipLoader, FadeLoader, ScaleLoader } from "react-spinners";

export default function Signin({ onCloseModal }) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("validated");
  const [loader, setLoader] = useState(false);
  // const [response ,setResponse] = useState(null)
  // const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const handleSignin = async () => {
    try {
      setLoader(true)
      const body = {
        email,
        password,
      };
      const response = await userSignin(body);
      if (response.success) {
        setLoader(false)
        setError("validated");
        // setToken(response.token)
        // console.log('token from redux',token)
        // dispatch(setToken(response.token))
        setEmail("");
        setPassword("");
        dispatch(setUsereData(response.userData));
        dispatch(setAuthToken(response.token));
        return true;
      } else {
        setLoader(false)
        setError(response.message);
        return false;
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  };

  const userLogin = useSelector(selectLogedIn);

  const handleescortSignin = async () => {

    try {
      const body = {
        email,
        password,
      };
      setLoader(true)
      const response = await escortSignin(body)
      if (response.success) {
        setError("validated")
        setEmail('')
        setPassword('')
        dispatch(setUsereData(response.escortData))
        dispatch(setAuthToken(response.token))
        setLoader(false)
        return true
      }
      else {
        console.log(response, body)
        setError(response.message)
        setLoader(false)
        return false
      }
    } catch (error) {
      setLoader(false)
     }
  };

  const opennewscreen = () => {
    setotherscreen(!otherscreen);
  };

  const handleAgreeToggle = () => {
    setIsAgreed(!isAgreed);
  };

  const { setmodalcomponent, Modalcomponent } = useModal();

  const validateForm = () => {
    // const { username, email, password, confirmPassword, privacySelected } = formData;

    // Check if any required fields are empty
    if (!email || !password) {
      setError("Please fill out all required fields.");
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      return false;
    }

    // Clear error if all validations pass
    setError("validated");

    return true;
  };

  const {
    isLoggedIn,
    login,
    setisLoggedIn,
    userloggedin,
    escortloggedin,
    setToken,
    token,
  } = useAuth();

  const handleSignInClick = () => {
    setmodalcomponent("forgotPass");
  };
  const handlealreadyhaveacc = () => {
    // if(validateForm){
    setmodalcomponent("signup");
    // }
  };

  const HandleJoinnow = async () => {
    if (validateForm()) {
      try {
        if (userLogin) {
          const loggedin = await handleSignin();
          if (loggedin) {
            onCloseModal();
          }
        }
        if (!userLogin) {
          const escortSignedin = await handleescortSignin();
          if (escortSignedin && !userLogin) {
            onCloseModal();
            // setisLoggedIn(false)
          }
        }
      } catch (error) {}
      // setisLoggedIn(false);
    }
  };
  

  return (
    <div className="lg:w-50p biggerlaptop:p-8 lg:p-3  md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
        <div className="flex w-full justify-between lg:flex-row md:flex-row md:flex-row sm:flex-row flex-col-reverse   ">
          <div className="1470:text-5xl lg:text-3xl md:text-4xl sm:text-2xl text-2xl text-white flex font-bold text-nowrap lg:pb-0 md:pb-0  sm:pb-0 pb-2  lg:pt-0 md:pt-0  sm:pt-0 pt-2      ">
            Sign in
          </div>
          <div className="w-full flex items-center">
            <div className="w-50p flex justify-start lg:hidden md:hidden sm:hidden flex   ">
              <img src={images.arrowwhiteleft} alt="" className="w-6 h-5 " />{" "}
            </div>
            <div className="w-full flex lg:justify-end md:justify-end sm:justify-end  justify-center">
              <img
                src={images.logo}
                alt=""
                className="lg:w-20 md:w-20  w-20 mb-2 max-w-40 lg:hidden md:flex sm:flex flex  "
              />
            </div>

            <div className="w-50p  flex justify-end" onClick={onCloseModal}>
              <img
                src={images.X}
                alt=""
                className=" cursor-pointer md:h-7 sm:h-6 h-5"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full space-y-10  text-white lg:text-lg md:text-lg sm:text-md text-md ">
          <input
            className=" text-white lg:text-lg md:text-lg sm:text-md text-md bg-transparent opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
            type="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="text-white lg:text-lg md:text-lg sm:text-md text-md text-white pb-1  bg-transparent w-50p opacity-50 border-b-2 w-full focus:outline-none placeholder-white pl-1 mt-2"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            className="lg:text-lg md:text-md sm:text-sm text-sm text-white active:opacity-50 cursor-pointer"
            onClick={handleSignInClick}
          >
            Forgot password?
          </div>
          <div
            className={
              error === "validated"
                ? "error cursor-pointer opacity-0 "
                : "error cursor-pointer lg:text-base md:text-sm sm:text-sm text-xs lg:pb-0 md:pb-4 sm:pb-4 pb-4"
            }
          >
            *{error}
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center">
          <div
            className="bg-purple font-semibold lg:text-lg md:text-lg sm:text-sm text-sm text-white text-center lg:py-5 md:py-5 sm:py-3 py-3 lg:w-full md:w-full sm:w-full w-70p  cursor-pointer mb-2  active:opacity-50"
            onClick={HandleJoinnow}
          >
            {loader ? (
              <ClipLoader
                color="#FFFF"
                cssOverride={{
                  // backgroundColor: "red",
                  height: "22px",
                  width: "22px",
                }}
                // height={10}
                // margin={3}
                // radius={2}
                // width={4}
              />
            ) : (
              "Log in"
            )}
          </div>
          <div
            className="text-white cursor-pointer active:opacity-50 lg:text-base md:text-base sm:text-sm text-xs "
            onClick={handlealreadyhaveacc}
          >
            Don’t have an account? Sign Up
          </div>
        </div>
      </div>
      {}
    </div>
  );
}
