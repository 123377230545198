import { combineReducers } from "@reduxjs/toolkit";
// import SaveData from "./reducer/addReduer";
import tokenReduces from './token'
import userDataReducer from "./userData"
import logedInReducer from "./logedIn"

const rootReducer = combineReducers({
    token:tokenReduces,
    user:userDataReducer,
    logedIn:logedInReducer,
});

export default rootReducer;