import React, { useEffect, useRef, useState } from "react";
import images from "../../services/images";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/userData";
import { getAllUserDetails } from "../../services/API";
import { selectToken } from "../../store/token";

export default function Modelcards({ models, setModels }) {
  const navigate = useNavigate();
  const ImageModels = useRef();
  const [lowPaymentModal, setLowPaymentModal] = useState(false);
  const [userData, setUserData] = useState("");
  // const userData = useSelector(selectUserData)
  const authToken = useSelector(selectToken);
  console.log("userData arha hai bro", userData);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = authToken; // Assuming token is stored in localStorage
        const data = await getAllUserDetails(token);
        if (data) {
          // setWallet(data);
          // dispatch(setUserData(data));
          setUserData(data);
        } else {
          console.log("Failed to fetch User details.");
        }
      } catch (error) {
        console.log("Failed to fetch User details.");
      }
    };

    fetchUserData();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      maxWidth: "500px", // Ensures a max width for larger screens
      transform: "translate(-50%, -50%)",
      // backgroundColor: "rgba(128, 0, 128, 0.5)",
      backgroundImage: `url(${images.settingbackground})`,
      // padding: "20px",
      // borderRadius: '8px',
      textAlign: "center",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    },
  };

  const closeModal = () => {
    setLowPaymentModal(false);
  };

  // const userBalance = () => {
  //   if(userData == 0){
  //     setLowPaymentModal(true);
  //   }
  // }

  const handleClick = (item) => {
    if (userData.wallet === 0) {
      setLowPaymentModal(true);
    } else {
      const _id = item._id;
      navigate(`/userlivestream/${_id}`, {
        state: { escort: item },
      });
    }
  };

  Modal.setAppElement("#root");

  return (
    <div className="bg-purplegray flex flex-row pl-8 pr-8 sm:pl-6 md:pl-8 lg:pl-8 gap-2 overflow-hidden pb-40">
      <div
        ref={ImageModels}
        className="flex flex-wrap sm:justify-start justify-center w-full"
      >
        {models?.map((item, index) => (
          <div
            key={index}
            className="pt-2 w-[90%] sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pr-4 pl-4 pb-4 relative"
          >
            <div className="relative">
              <img
                src={item?.profilePhoto}
                alt={`Image ${index + 1}`}
                className="w-full sm:h-[300px] h-[400px]  object-content  rounded cursor-pointer sm:h-64 md:h-72 lg:h-80 xl:h-96"
                // className="max-w-full h-auto object-cover rounded cursor-pointer"
                // style={{ height: "450px", width: "100%" }} // Apply consistent dimensions
                // onClick={() => {
                //   const _id = item._id;
                //   navigate(`/userlivestream/${_id}`, {
                //     state: { escort: item },
                //   });
                // }}
                onClick={() => handleClick(item)}
              />
              <div className="absolute font-semibold top-0  ml-4 mt-4 sm:ml-5 md:mt-8 lg:px-7 md:px-5 sm:px-6  px-4 py-1 text-center bg-purple text-white lg:text-base md:text-base sm:text-sm text-sm ">
                Live
              </div>
              <div className="absolute w-full text-white bottom-[2%]  ">
                <div className="flex  w-full  justify-between pl-4 pr-2 sm:pl-5 sm:pr-5 items-center">
                  <div className="bg-green-500 round rounded-full w-[14px] h-[14px]  sm:w-[17px] sm:h-[17px] items-center "></div>
                  <img
                    src={item.image3}
                    alt=""
                    className=" w-2 sm:w-4 sm:h-4 mr-5 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={lowPaymentModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Low Balance Warning"
      >
        <h2 className="text-lg font-bold text-white mb-5">
          Insufficient Balance
        </h2>
        <p className="text-white w-[80%] mx-auto text-center">
          Unfortunately, your current balance is insufficient to join this
          stream. Please top up your account to continue.
        </p>
        <div className="flex flex-row justify-center ">
          <button
            // onClick={closeModal}
            className="mt-4 px-10 py-2 bg-purple text-white rounded active:opacity-50 mr-4"
            onClick={() => navigate("/giftcarduser")}
          >
            Gift Card
          </button>
          {/* <button
          onClick={closeModal}
          className="mt-4 px-4 py-2 bg-purple text-white rounded active:opacity-50"
        >
          Close
        </button> */}
        </div>
      </Modal>
    </div>
  );
}
