import React from "react";
import images from "../../services/images";

export default function PaymentTutorial() {
  return (
    <div className=" text-whitepink  flex flex-col items-center jusitfy-center w-full pb-40 ">
      <div className="flex justify-between items-center lg:items-start  gap-4 flex-col lg:flex-row pt-10 md:w-full lg:w-60p h-full  ">
        <div className="flex flex-col w-[95%] lg:w-60p ">
          <div className="bg-graymore   w-full  ">
            <div className="p-3 border-b border-whitepink border-opacity-30">
              How to pay with Litecoin
            </div>
          </div>
          <div className="bg-footercolor p-3 text-sm ">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-3">
                <img src={images.paybis} className="h-20 w-20 " />
                Apple Pay
              </div>
              <div className="bg-graymore  px-2 py-1 cursor-pointer rounded-3xl">
                options
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[95%] lg:w-full ">
          <div className="bg-graymore   w-full ">
            <div className="pl-5 py-3 border-b border-whitepink border-opacity-30">
              How to pay with Litecoin
            </div>
          </div>
          <div className="bg-footercolor px-5 py-3 flex flex-col gap-3 ">
            <div className="text-xl font-bold">
              Apple Pay payments with Paybis
            </div>
            Nov 23, 2022
            <div className=" flex items-center justify-center">
              <img src={images.paybisLogo} />
            </div>
            Using Paybis, you can top up your Pleasury Gift Card using Apple Pay
            from the comfort of your own home. You will need to create an
            account if this is your first time using Paybis, don't worry,
            registration is fast and easy!
            <div className="flex justify-center">
              <img src={images.paybisTutorial} className="w-[65%] " />
            </div>
            <p className="text-center">
              Once registered, you will need your Pleasury Litecoin (LTC)
              address
            </p>
            <p className="text-center underline-offset-4	underline ">
              How to send LTC to Pleasury using Paybis in 3 easy steps!
            </p>
            <p>
              1. Visit the Paybis LTC page and enter the dollar or LTC value you
              wish to buy and click on the “Buy Litecoin” button.
            </p>
            <div className="   flex justify-center">
              <img src={images.tutorial2} className="w-80p" />
            </div>
            2. Select the “external wallet” option and enter your Pleasury LTC
            Address. You can watch our video tutorial for more information on
            how to locate your Pleasury LTC Address.
            <div className="   flex justify-center">
              <img src={images.paymentLogin} className="w-80p" />
            </div>
            3. If your method of payment is using Apple Pay, please select
            ‘Apple Pay’ as the choice of payment proceed by confirming with the
            “pay button”.
            <div className="   flex justify-center">
              <img src={images.paymentTutorial3} className="w-80p" />
            </div>
            Your LTC will reach your Pleasury Gift Card within 10 minutes and
            you’ll receive an email from us once it has arrived!
            <div className="   flex justify-center">
              <img src={images.paymentTutorial3} className="w-80p" />
            </div>
            <p>
              Your LTC will reach your pleasury Gift Card within 10 minutes and
              you’ll receive an email from us once it has arrived! Having
              trouble with this? Try a different method including the likes of
              Interac and Cash. Our Customer Support team is also on hand 24/7
              to handle any queries you may have. Please reach out to us using
              support@pleasury if you need any assistance, we’re always here for
              you.
            </p>
            <p>Cheers, </p>
            <p> The pleasury Team</p>
            <p>
              <span className="font-bold underline-offset-4	underline ">
                Please note:
              </span>{" "}
              pleasury is in no way affiliated with Paybis. All materials and
              contents found in this blog are strictly for general circulation
              and informational purposes only and should not be considered as an
              offer, or solicitation, to deal in any of the services or products
              found/identified in this site.
              <p>
                Any opinion or estimates contained in this blog are made on a
                general basis and neither pleasury nor any of its employees or
                agents have given any consideration to nor have they or any of
                them made any investigation of the objectives, financial
                situation or particular need of any user or reader, any specific
                person or group of persons. You should consider carefully if the
                services or products are suitable for your objectives,
                experience, risk tolerance and other personal circumstances.
                Opinions expressed here in are subject to change without notice.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
