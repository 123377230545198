import React from "react";
import PropTypes from "prop-types";
import { FaStar, FaRegStar } from "react-icons/fa";

const StarRating = ({ rating, totalStars, size, fillColors, outlineColor, inline, starSpacing }) => {
  const stars = Array.from({ length: totalStars }, (_, index) => {
    const isFilled = index < Math.floor(rating);
    const fillColor = isFilled ? fillColors[Math.min(index, fillColors.length - 1)] : outlineColor;

    return isFilled ? (
      <FaStar key={index} size={size} color={fillColor} style={{ marginRight: starSpacing }} />
    ) : (
      <FaRegStar key={index} size={size} color={outlineColor} style={{ marginRight: starSpacing }} />
    );
  });

  return (
    <div style={{ display: inline ? "inline-flex" : "block", fontSize: size }}>
      {stars}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  totalStars: PropTypes.number.isRequired,
  size: PropTypes.string,
  fillColors: PropTypes.arrayOf(PropTypes.string),
  outlineColor: PropTypes.string,
  inline: PropTypes.bool,
  starSpacing: PropTypes.string,
};

StarRating.defaultProps = {
  size: "0.9em",
  fillColors: ["#BE42DE"],
  outlineColor: "#8a2be2",
  inline: false,
  starSpacing: "0.1em",
};

export default StarRating;
