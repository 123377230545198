import React, { useRef, useState } from "react";
import images from "../../services/images";
import FormCarousel from "../FormCarousel";

export default function AdCard({
  available,
  age,
  name,
  ethnicity,
  ethnicOrigin,
  selectedcity,
  uploadimg,
  adTitle,
  textAreadata,
  carousel,
}) {
  const [data, setData] = useState([
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
  ]);

  const imageGridRef = useRef();

  return (
    <div className="bg-buttoncolor text-whitepink  gap-2 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-4 items-center justify-center  flex flex-col">
      <div className="flex  w-full ">
        {carousel ? (
          <div className="w-80 overflow-hidden">
            <FormCarousel
              images={uploadimg}
              ref={imageGridRef} />
          </div>
        ) : (
          <div className=" flex gap-2 flex-wrap w-full ">
            {uploadimg ? (
              <div className=" flex  gap-2  max-w-[240px] overflow-hidden  ">
                {uploadimg?.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt=""
                    className="w-10 h-10  md:w-8 md:h-8"
                  />
                ))}
              </div>
            ) : (
              <div className="flex gap-2 flex-wrap  md:w-full">
                {data.map((pic, index) => (
                  <img
                    key={index}
                    src={pic.image}
                    alt=""
                    className="w-10 h-10  md:w-8 md:h-8"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="w-full gap-2  flex flex-col">
          <div className="flex w-full  font-bold  text-sm sm:text-sm  max-w-[200px] overflow-hidden">
            <div>{textAreadata}</div>
          </div>
          <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-full justify-between  font-semibold  text-xxs sm:text-sm">
            {available ? (
              <div>{age} - {name} - {ethnicity} - {ethnicOrigin} - {selectedcity}</div>
            ) : (
              <div className="">23 - Britny Spears - Africa- Black American- California</div>
            )}
            <div className="flex flex-col gap-1 items-end">
              <div className="font-semibold text-xs sm:text-xs rounded-2xl gap-1 sm:gap-1 py-1  bg-whitepink flex items-center justify-center w-20 sm:w-24   text-textdarkerpurpel ">
                <img src={images.verified1} className="h-4 sm:h-4 w-4" />
                Verified
              </div>
              {available && (
                <div className="font-semibold text-xs sm:text-xs rounded-2xl gap-1 sm:gap-1 py-1  bg-whitepink flex items-center justify-center w-20 sm:w-24   text-textdarkerpurpel ">
                  <img src={images.verified1} className="h-4 sm:h-4 w-4" />
                  Available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
