import React, { useEffect, useState } from "react";
import userData, {
  selectUserData,
  setUsereData,
  updateUserWallet,
  updateWallet,
} from "../../store/userData";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../store/token";
import {
  getAllTransactions,
  setPaymentDetailsForVerify,
  updateTransactionStatus,
} from "../../services/API";

const getTimestampFromObjectId = (objectId) => {
  const timestamp = new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  return timestamp.toLocaleString(); // Convert to locale string for better readability
};

export default function VerifyPayment() {
  const userData = useSelector(selectUserData);
  const [transactions, setTransactions] = useState([{}]);
  const [toggleMap, setToggleMap] = useState("pending");

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getAllTransactions(token);
    setTransactions(result.transactions);
    console.log(result);
  };

  console.log(transactions);

  const handleStatusChange = async (id, newStatus) => {
    try {
      const data = await updateTransactionStatus(id, newStatus, token);
      if (data.success) {
        setTransactions(
          transactions.map((transaction) =>
            transaction._id === id
              ? { ...transaction, status: newStatus }
              : transaction
          )
        );
      } else {
        console.error("Error updating transaction status:", data.message);
      }
    } catch (error) {
      console.error("Error updating transaction status:", error);
    }
  };

  const handleWalletUpdate = async (amount, token, escortData) => {
    try {
      const body = {
        wallet: amount, // Assuming the wallet amount is the transaction amount
        escortData: escortData, // Assuming the wallet amount is the transaction amount
      };

      // Include token in the payload data
      const paymentUpdateResponse = await setPaymentDetailsForVerify(
        body,
        token
      );

      if (paymentUpdateResponse.success) {
        console.log(`Wallet updated successfully. Updated amount: ${amount}`);
      } else {
        console.error("Error updating wallet:", paymentUpdateResponse.message);
      }
    } catch (error) {
      console.error(
        "Error updating wallet:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div className="text-whitepink w-full flex flex-col h-full">
      <div className="flex w-full flex-col items-center pt-20 gap-10 pb-40 h-full">
        <div className="flex flex-col md:flex-row bg-footercolor py-3 px-5 items-center  gap-5 md:gap-14">
          <div
            className={
              toggleMap === "pending"
                ? `bg-purple px-4 py-1 cursor-pointer active:opacity-50`
                : `px-4 py-2
              
              cursor-pointer active:opacity-50`
            }
            onClick={() => setToggleMap("pending")}
          >
            Pending
          </div>
          <div
            className={
              toggleMap === "accepted"
                ? `bg-purple px-4 py-1 cursor-pointer active:opacity-50`
                : `px-4 py-2 cursor-pointer active:opacity-50`
            }
            onClick={() => setToggleMap("accepted")}
          >
            Accepted
          </div>
          <div
            className={
              toggleMap === "declined"
                ? `bg-purple px-4 py-1 cursor-pointer active:opacity-50`
                : `px-4 py-2 cursor-pointer active:opacity-50`
            }
            onClick={() => setToggleMap("declined")}
          >
            Declined
          </div>
        </div>
        <div className="text-3xl">Verify Payment</div>
        {transactions.map((transaction, index) => (
          <>
            {transaction.status === toggleMap ? (
              <>
                <div
                  key={index}
                  className="w-90p md:w-40p h-auto bg-footercolor flex flex-col justify-between mb-4"
                >
                  <div className="w-full border border-whitepink border-opacity-40 p-4 flex justify-between">
                    <div>Name</div>
                    <div>
                      {transaction.firstName} {transaction.lastName}
                    </div>
                  </div>
                  <div className="w-full p-4 justify-between flex border border-whitepink border-opacity-40">
                    <div>Email</div>
                    <div>{transaction.escortEmail}</div>
                  </div>
                  <div className="w-full p-4 justify-between flex border border-whitepink border-opacity-40">
                    <div>Time</div>
                    <div>{getTimestampFromObjectId(transaction._id)}</div>
                  </div>
                  <div className="bg-graymore px-4 py-4 flex justify-between flex border border-whitepink border-opacity-40">
                    <div>Payment</div>
                    <div>{transaction.amount}</div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div
                    className="bg-purple px-8 py-2 cursor-pointer active:opacity-50"
                    onClick={() => {
                      handleStatusChange(transaction._id, "accepted");
                      handleWalletUpdate(
                        transaction.amount,
                        token,
                        transaction.escortData
                      );
                    }}
                  >
                    Accept
                  </div>
                  <div
                    className="bg-slightydarkerBackgorund px-8 py-2 cursor-pointer active:opacity-50"
                    onClick={() =>
                      handleStatusChange(transaction._id, "declined")
                    }
                  >
                    Decline
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
