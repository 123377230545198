import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useNavigate } from "react-router-dom";
import "./ResponsiveLivestreamcards.css"; // Import your stylesheet
import images from "../../services/images";

const ResponsiveImageGrid = forwardRef(({ images }, ref) => {
  const navigate = useNavigate();
  const [visibleImages, setVisibleImages] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const breakpoints = [
        { width: 1280, visibleImages: 4 },
        { width: 1024, visibleImages: 4 },
        { width: 768, visibleImages: 3 },
        { width: 556, visibleImages: 2 },
        { width: 544, visibleImages: 1 },
      ];

      const { visibleImages: newVisibleImages } =
        breakpoints.find(
          (breakpoint) => window.innerWidth >= breakpoint.width
        ) || breakpoints[breakpoints.length - 1]; // Fallback to the last breakpoint

      setVisibleImages(newVisibleImages);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    if (startIndex + visibleImages < images.length) {
      setStartIndex(startIndex + 1);
    } else {
      setStartIndex(0);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    } else {
      setStartIndex(images.length - visibleImages);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDiff = touchEnd - touchStart;

    // Set a threshold for swipe distance to prevent accidental swipes
    const swipeThreshold = 50;

    if (touchDiff > swipeThreshold) {
      // Swipe right, go to the previous image
      handlePrevious();
    } else if (touchDiff < -swipeThreshold) {
      // Swipe left, go to the next image
      handleNext();
    }
  };

  useImperativeHandle(ref, () => ({
    handleNext,
    handlePrevious,
  }));

  return (
    <div
      className="bg-purplegray overflow-hidden transition-transform duration-500 ease-in-out"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="flex flex-row gap-2 ">
        {images
          .slice(startIndex, startIndex + visibleImages)
          .map((item, index) => (
            <div
              key={index}
              className={`w-full ${
                visibleImages === 8
                  ? "xl:w-1/8 relative"
                  : visibleImages === 6
                  ? "lg:w-1/6 relative"
                  : visibleImages === 4
                  ? "md:w-1/2 relative"
                  : "sm:w-1/2 relative"
              }`}
              onClick={() => {
                const _id = item._id;
                navigate(`/Modeldetails/${_id}`, { state: { item } });
              }}
            >
              <div className=" cursor-pointer">
                <img
                  src={item?.profilePhoto}
                  alt={`Image ${index + startIndex + 1}`}
                  className="w-[800px] h-[450px] sm:h-[400px]  md:h-[400px] active:opacity-50 cursor-pointer"
                  // style={{ height: "450px", width: "100%" }}
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="absolute text-white lg:text-4xl md:text-2xl sm:text-2xl lg:pt-0 md:pt-0 sm:pt-0 pt-20 text-2xl font-bold w-1/2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-center">
                    {item?.listingDetails?.name}
                    <img
                      src={item.dots}
                      alt=""
                      className="pt-3 w-80p pl-13 pr-2"
                    />
                    <div className="relative text-white font-bold lg:text-lg md:text-xs sm:text-xs text-sm p-2 ">
                      {item?.listingDetails?.location}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default ResponsiveImageGrid;

// ${
//   visibleImages === 8
//     ? "xl:w-1/8 relative cursor-pointer"
//     : visibleImages === 6
//     ? "lg:w-1/6 relative cursor-pointer"
//     : visibleImages === 4
//     ? "md:w-1/4 relative cursor-pointer"
//     : "sm:w-1/2 relative cursor-pointer"
// }
