// import React, { useState,useEffect } from 'react';
// import images from '../../services/images';

// const ImageSlider = ({ imagesArray, displayedImages }) => {
//   const [visibleImages, setVisibleImages] = useState(5);
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 1280) {
//         setVisibleImages(6);
//       } else if (window.innerWidth >= 1024) {
//         setVisibleImages(5);
//       } else if (window.innerWidth >= 768) {
//         setVisibleImages(4);
//       } else {
//         setVisibleImages(2);
//       }
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (

//     <div className='w-full justify-end '
//       style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
//     >
//       {displayedImages.map((image, index) => (
//         <div key={index}

//         style={{  display: 'flex', width: '250px', alignItems: 'flex-end' }}  className={`w-full ${
//           visibleImages === 8
//             ? "xl:w-1/8 relative"
//             : visibleImages === 6
//             ? "lg:w-1/6 relative"
//             : visibleImages === 4
//             ? "md:w-1/4 relative"
//             : "sm:w-1/2 relative"
//         }`}>
//           <img src={image} alt={`Image ${index + 0}`}
//             className='w-65'
//           />
//         </div>
//       ))}
//     </div>
//   );
// };
// export default ImageSlider;

import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useNavigate } from "react-router-dom";

const ImageSlider = forwardRef(({ images }, ref) => {
  const navigate = useNavigate();
  const [visibleImages, setVisibleImages] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setVisibleImages(5); // Show 5 images on large screens
      } else if (window.innerWidth >= 768) {
        setVisibleImages(4);
      } else if (window.innerWidth >= 320) {
        setVisibleImages(2);
      } else if (window.innerWidth >= 1) {
        setVisibleImages(1);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    if (startIndex + visibleImages < images.length) {
      setStartIndex(startIndex + 1);
    } else {
      setStartIndex(0);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    } else {
      setStartIndex(images.length - visibleImages);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDiff = touchEnd - touchStart;

    // Set a threshold for swipe distance to prevent accidental swipes
    const swipeThreshold = 50;

    if (touchDiff > swipeThreshold) {
      // Swipe right, go to the previous image
      handlePrevious();
    } else if (touchDiff < -swipeThreshold) {
      // Swipe left, go to the next image
      handleNext();
    }
  };

  useImperativeHandle(ref, () => ({
    handleNext,
    handlePrevious,
  }));

  return (
    <div
      className="flex lg:justify-start md:justify-start sm:justify-start justify-start"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {/* <div className="flex flex-row gap-2 lg:w-70p sm:w-80p w-full overflow-hidden items-baseline    items-center justify-center"> */}
      <div className="flex flex-row gap-2 lg:w-100p sm:w-80p w-full overflow-hidden items-baseline    items-center justify-center">
        {images
          .slice(startIndex, startIndex + visibleImages)
          .map((item, index) => (
            <div
              key={index}
              className={` w-full ${
                visibleImages === 8
                  ? "xl:w-1/8 relative cursor-pointer"
                  : visibleImages === 6
                  ? "lg:w-1/6 relative cursor-pointer"
                  : visibleImages === 4
                  ? "md:w-1/4 relative cursor-pointer "
                  : visibleImages === 2
                  ? "w-full relative cursor-pointer"
                  : "w-full relative cursor-pointer"
              }`}
              //  onClick={() => {
              //     const _id = item._id;
              //     navigate(`/Modeldetails/${_id}`, { state: { item } });
              //   }}
            >
              <div className="w-full  ">
                <img
                  src={item.images}
                  alt={`Image ${index + startIndex + 1}`}
                  className="w-full  active:opacity-50 cursor-pointer "
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default ImageSlider;
