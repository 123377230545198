import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  return (
    <div className="flex text-whitepink flex justify-center  gap-10 pt-20 pb-20">
      <div className="w-full md:w-[60%] ">
        <div className=" bg-footercolor  flex flex-col rounded-md">
          <div className="text-2xl pl-3 p-3" id="rules">
            Privacy Policy
          </div>
          <div className="bg-buttoncolor w-full pl-5 pr-5 flex flex-col gap-2  pb-10 rounded-md">
            <div className="mt-4">
              This privacy policy applies between you, the User of this Website
              and PleasuryList. PleasuryList takes the privacy of your
              information very seriously. This privacy policy applies to our use
              of any and all Data collected by us or provided by you in relation
              to your use of the Website.
            </div>
            <div className="mt-4">
              This privacy policy should be read alongside, and in addition to,
              our Terms of Service, which can be found at:
              <br />
              <span
                className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple"
                onClick={() => navigate("/terms")}
              >
                https://www.pleasurylist.com/terms
              </span>
            </div>
            <div className="mt-2">
              <b> Please read this privacy policy carefully.</b>
            </div>
            <div className="mt-2">
              <b>Definitions and interpretation</b>
            </div>
            <div className="mt-2">
              1. In this privacy policy, the following definitions are used:
            </div>
            <div className="mt-2">
              <b>Data </b>
              collectively all information that you submit to PleasuryList via
              the Website. This definition incorporates, where applicable, the
              definitions of “personal information” provided in the Personal
              Information and Electronic Documents Act (S.C. 2000, c.5),
              Alberta's Personal Information Protection Act (R.S.A. 2003, c.
              P-6.5, British Columbia's Personal Information Protection Act
              (R.S.B.C. 2003, c. 63), and Québec's Act respecting the protection
              of personal information in the private sector (CQLR, c. P-39.1),
              or any other applicable laws relating to the collection, use or
              disclosure of personal information (Collectively, the “Privacy
              Laws”).
            </div>
            <div className="mt-2">
              <b>Cookies </b>a small text file placed on your computer by this
              Website when you visit certain parts of the Website and/or when
              you use certain features of the Website. Details of the cookies
              used by this Website are set out in the clauses below under the
              heading “Cookies”;
            </div>
            <div className="mt-2">
              <b>User </b>or <b>you </b> any party that accesses the Website and
              is not either (i) employed by PleasuryList and acting in the
              course of their employment or (ii) engaged as a consultant or
              otherwise providing services to or for PleasuryList and accessing
              the Website in connection with the provision of such services; and
            </div>
            <div className="mt-2">
              <b>Website </b>
              the website that you are currently using, PleasuryList.cc, and any
              sub-domains of this site unless expressly excluded by their own
              terms and conditions.
            </div>
            <div className="mt-2">
              2. In this privacy policy, unless the context requires a different
              interpretation:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>the singular includes the plural and vice versa;</li>
              <li>
                references to sub-clauses, clauses, schedules or appendices are
                to sub-clauses, clauses, schedules or appendices of this privacy
                policy;
              </li>
              <li>
                a reference to a person includes firms, companies, government
                entities, trusts and partnerships;
              </li>
              <li>
                "including" is understood to mean "including without
                limitation";
              </li>
              <li>
                reference to any statutory provision includes any modification
                or amendment of it;
              </li>
              <li>
                the headings and sub-headings do not form part of this privacy
                policy.
              </li>
            </ol>
            <div className="mt-2">
              <b>Scope of this privacy policy</b>
            </div>
            <div className="mt-2">
              3. This privacy policy applies only to the actions of PleasuryList
              and Users with respect to this Website. It does not extend to any
              websites that can be accessed from this Website including, but not
              limited to, any links provided to social media websites.
            </div>
            <div className="mt-2">
              <b>Data collected</b>
            </div>
            <div className="mt-2">
              4. We may collect the following Data, which includes personal
              Data, from you:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>name;</li>
              <li>date of birth;</li>
              <li>gender;</li>
              <li>ethnicity</li>
              <li>
                contact Information such as email addresses and telephone
                numbers;
              </li>
              <li>
                demographic information such as postcode, preferences and
                interests;
              </li>
              <li>payment methods;</li>
              <li>IP address (automatically collected);</li>
              <li>web browser type and version (automatically collected);</li>
              <li>operating system (automatically collected);</li>
            </ol>
            <div className="mt-2">
              in each case, in accordance with this privacy policy.
            </div>
            <div className="mt-2">
              <b>How we collect Data</b>
            </div>
            <div className="mt-2">
              5. PleasuryList will collect Data directly from you in a number of
              ways, including:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                when you contact us, or others, through the Website, by
                telephone, post, e-mail or through any other means;
              </li>
              <li>
                when you register with us and set up an account to receive our
                products/services;
              </li>
              <li>
                when you make payments to us, through this Website or otherwise;
              </li>
              <li>
                when you elect to receive marketing communications from us;
              </li>
              <li>when you use our services;</li>
              <li>payment processors;</li>
            </ol>
            <div className="mt-2">
              in each case, in accordance with this privacy policy.
            </div>
            <div className="mt-2">
              6. To the extent that you access the Website, we will collect your
              Data automatically, including:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                we automatically collect some information about your visit to
                the Website. This information helps us to make improvements to
                Website content and navigation, and includes your IP address,
                the date, times and frequency with which you access the Website
                and the way you use and interact with its content.
              </li>
              <li>
                we will collect your Data automatically via cookies, in line
                with the cookie settings on your browser. For more information
                about cookies, and how we use them on the Website, see the
                section below, headed "Cookies".
              </li>
            </ol>
            <div className="mt-2">
              <b>How we use your Data</b>
            </div>
            <div className="mt-2">
              7. Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>internal record keeping;</li>
              <li>improvement of our products / services;</li>
              <li>
                transmission by email of marketing materials that may be of
                interest to you, but only if you consent to receiving such
                materials or as permitted by applicable law;
              </li>
              <li>
                contact for market research purposes which may be done using
                email, telephone, fax or mail. Such information may be used to
                customise or update the Website;
              </li>
            </ol>
            <div className="mt-2">
              in each case, in accordance with this privacy policy.
            </div>
            <div className="mt-2">
              8. We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed "Your rights" below).
            </div>
            <div className="mt-2">
              9. For the delivery of direct marketing to you via e-mail, we’ll
              need your consent, whether via an express opt-in or soft-opt-in
              permitted by applicable law:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                soft opt-in consent is a specific type of consent which applies
                in certain circumstances when you have previously engaged with
                us (for example, you contact us to ask us for more details about
                a particular product/service, and we are responding with the
                requested details). Under “soft opt-in” consent, we will take
                your consent as given unless you opt-out.
              </li>
              <li>
                for other types of e-marketing, when you provide us with your
                explicit consent; that is, you need to take positive and
                affirmative action when consenting by, for example, checking a
                tick box that we’ll provide.
              </li>
              <li>
                if you are not satisfied about our approach to marketing, you
                have the right to withdraw your consent at any time. To find out
                how to withdraw your consent, see the section headed “Your
                rights” below.
              </li>
            </ol>
            <div className="mt-2">
              10. When you register with us and set up an account to receive our
              services, we will contact you by email or otherwise in association
              with the performance of a contract between you and us and/or
              taking steps, at your request, to enter into such a contract.
            </div>
            <div className="mt-2">
              11. We may use your Data to show you PleasuryList adverts and
              other content on other websites. If you do not want us to use your
              Data to show you PleasuryList adverts and other content on other
              websites, please turn off the relevant cookies (please refer to
              the section headed “Cookies” below).
            </div>
            <div className="mt-2">
              <b>Who we share Data with</b>
            </div>
            <div className="mt-2">
              12. We may share your Data with the following groups of people for
              the following reasons:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                any of our group companies or affiliates - for the purpose of
                marketing where relevant;
              </li>
              <li>
                our employees, service providers, agents and/or professional
                advisors - for the purpose of administration and operation of
                the Services, training, customer support and on-going product or
                service improvements;
              </li>
              <li>
                any of our group companies or affiliates, our customers or the
                public – for the purpose of protecting against harm to their
                rights, property or safety;
              </li>
              <li>
                relevant authorities, such as law enforcement - to facilitate
                the detection, prevention, or investigation of a crime or
                offence, or in connection with other lawful requests for your
                Data;
              </li>
            </ol>
            <div className="mt-2">
              in each case, in accordance with this privacy policy.
            </div>
            <div className="mt-2">
              <b>Keeping Data secure</b>
            </div>
            <div className="mt-2">
              13. We will use technical and organisational measures to safeguard
              your Data, for example:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                access to your account is controlled by a password and a user
                name that is unique to you.
              </li>
              <li>we store your Data on secure servers.</li>
              <li>
                payment details are encrypted using SSL technology (typically
                you will see a lock icon or green address bar (or both) in your
                browser when we use this technology).
              </li>
            </ol>
            <div className="mt-2">
              Please be aware though that, despite these efforts, no measures
              are perfect and no systems are impenetrable. You should carefully
              consider which Data you choose to share with us or make available
              via the Website. You should take care to use suitably strong
              passwords that others cannot guess, that are kept safe by you, and
              that are not re-used elsewhere. If you believe your password has
              been compromised, you should change it immediately.
            </div>
            <div className="mt-2">
              14.Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail
              address:support@pleasurylist.com
            </div>
            <div className="mt-2">
              <b>Transferring your Data</b>
            </div>
            <div className="mt-2">
              15. We may transfer Data that we collect or that you provide as
              described in this policy to contractors, service providers, and
              other third parties we use to support our business (such as
              analytics and search engine providers that assist us with website
              improvement and optimization) and who are contractually obligated
              to keep Data confidential, use it only for the purposes for which
              we disclose it to them, and to process the Data with the same
              standards set out in this policy.
            </div>
            <div className="mt-2">
              16. We may process, store, and transfer your Data outside of
              Canada. These other countries may have different privacy laws or
              may or may not have privacy laws as comprehensive as Canadian law.
              In these circumstances, the governments, courts, law enforcement,
              or regulatory agencies of that country may be able to obtain
              access to your Data through the laws of the foreign country.
              Whenever we engage a service provider, we require that its privacy
              and security standards adhere to this policy and applicable
              Canadian privacy legislation.
            </div>
            <div className="mt-2">
              17. By submitting your Data or engaging with the Website, you
              consent to this transfer, storage, and processing.
            </div>
            <div className="mt-2">
              <b>Data retention</b>
            </div>
            <div className="mt-2">
              18. Unless a longer retention period is required or permitted by
              law, we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </div>
            <div className="mt-2">
              19. Even if we delete your Data, it may persist on backup or
              archival media for legal, tax or regulatory purposes.
            </div>
            <div className="mt-2">
              <b>Your rights</b>
            </div>
            <div className="mt-2">
              20. You have the following rights in relation to your Data:
            </div>
            <ol className="list-decimal list-inside ml-4">
              <li>
                <b>Right to access </b>- the right to request (i) copies of the
                information we hold about you at any time, or (ii) that we
                modify, update or delete such information. If we provide you
                with access to the information we hold about you, we will not
                charge you for this, unless your request is “manifestly
                unfounded or excessive.” Where we are legally permitted to do
                so, we may refuse your request. If we refuse your request, we
                will tell you the reasons why.
              </li>
              <li>
                <b>Right to correct </b>- the right to have your Data rectified
                if it is inaccurate or incomplete.
              </li>
              <li>
                <b>Right to restrict our use of your Data </b>- the right to
                “block” us from using your Data or limit the way in which we can
                use it.
              </li>
              <li>
                <b>Right to data portability </b>- the right to request that we
                move, copy or transfer your Data.
              </li>
              <li>
                <b>Right to object </b>- the right to object to our use of your
                Data including where we use it for our legitimate interests
              </li>
            </ol>
            <div className="mt-2">
              21. To make enquiries, exercise any of your rights set out above,
              or withdraw your consent to the processing of your Data please
              contact us via this e-mail address: support@pleasurylist.com
            </div>
            <div className="mt-2">
              <b>Links to other websites</b>
            </div>
            <div className="mt-2">
              22. This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </div>
            <div className="mt-2">
              <b>Changes of business ownership and control</b>
            </div>
            <div className="mt-2">
              23. PleasuryList may, from time to time, expand or reduce our
              business and this may involve the sale and/or the transfer of
              control of all or part of PleasuryList. Data provided by Users
              will, where it is relevant to any part of our business so
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this privacy
              policy, be permitted to use the Data for the purposes for which it
              was originally supplied to us.
            </div>
            <div className="mt-2">
              24. We may also disclose Data to a prospective purchaser of our
              business or any part of it for the purpose of evaluating the
              viability of the transaction.
            </div>
            <div className="mt-2">
              25. In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </div>
            <div className="mt-2">
              <b>Cookies</b>
            </div>
            <div className="mt-2">
              26. This Website may place and access certain Cookies on your
              computer. PleasuryList uses Cookies to improve your experience of
              using the Website and to improve our range of products.
              PleasuryList has carefully chosen these Cookies and has taken
              steps to ensure that your privacy is protected and respected at
              all times.
            </div>
            <div className="mt-2">
              27. This Website may place the following Cookies:
            </div>
            <div className="mt-2">
              <b> Analytical/performance cookies</b>
            </div>
            <div>
              These allow us to recognise and count the number of visitors and
              to see how visitors move around our website when they are using
              it. This helps us to improve the way our website works, for
              example, by ensuring that users are finding what they are looking
              for easily.
            </div>
            <div className="mt-2">
              <b> Functionality cookies</b>
            </div>
            <div>
              These are used to recognise you when you return to our website.
              This enables us to personalise our content for you, greet you by
              name and remember your preferences (for example, chat preferences,
              your choice of language or region).
            </div>
            <div className="mt-2">
              <b> Targeting cookies</b>
            </div>
            <div>
              These cookies record your visit to our website, the pages you have
              visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on
              it more relevant to your interests. We may also share this
              information with third parties for this purpose.
            </div>
            <div className="mt-2">
              28. You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser
            </div>
            <div className="mt-2">
              29. You can choose to delete Cookies at any time; however you may
              lose any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </div>
            <div className="mt-2">
              30. It is recommended that you ensure that your internet browser
              is up-to-date and that you consult the help and guidance provided
              by the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </div>
            <div className="mt-2">
              31. For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </div>
            <div className="mt-2">
              <b>General</b>
            </div>
            <div className="mt-2">
              32. You may not transfer any of your rights under this privacy
              policy to any other person. We may transfer our rights under this
              privacy policy where we reasonably believe your rights will not be
              affected.
            </div>
            <div className="mt-2">
              33. If any court or competent authority finds that any provision
              of this privacy policy (or part of any provision) is invalid,
              illegal or unenforceable, that provision or part-provision will,
              to the extent required, be deemed to be deleted, and the validity
              and enforceability of the other provisions of this privacy policy
              will not be affected.
            </div>
            <div className="mt-2">
              34. Unless otherwise agreed, no delay, act or omission by a party
              in exercising any right or remedy will be deemed a waiver of that,
              or any other, right or remedy.
            </div>
            <div className="mt-2">
              <b>Changes to this privacy policy</b>
            </div>
            <div className="mt-2">
              35. PleasuryList reserves the right to change this privacy policy
              as we may deem necessary from time to time or as may be required
              by law. Any changes will be immediately posted on the Website and
              you are deemed to have accepted the terms of the privacy policy on
              your first use of the Website following the alterations.
            </div>
            <div className="mt-2">
              You may contact PleasuryList by email at{"   "}
              <span className="font-bold cursor-pointer active:opacity-40  pr-1 text-purple">
                support@pleasurylist.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
