import React, { useEffect, useRef, useState } from "react";
import images from "../../services/images.js";
import StarRating from "../../components/Starating/index.jsx";
import Escortslider from "../../components/Escortslider/index.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import Escortmodal from "../../components/Escortmodal/index.jsx";
import { getEscorts } from "../../services/API/index.js";

export default function Modeldetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const imageGridRef = useRef();
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const [isEscortModalOpen, setIsEscortModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null)

  const toggleEscortModal = () => {
    setIsEscortModalOpen(!isEscortModalOpen);
  };

  useEffect(() => {
    getEscortsData();
  }, []);

  const [imageUrl, setImageurlcards] = useState([
    {
      images: images.card1,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card2,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card3,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card2,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card4,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card1,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
    },
    {
      images: images.card2,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
    },
    {
      images: images.card3,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
    },
    {
      images: images.card1,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
    },
    {
      images: images.card2,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
    },
    {
      images: images.card3,
      name: "KATONA",
      location: "KATONA BEATRIX",
      age: 24,
      dots: images.dots,
      rating: 3,
      Search: "Bandi1",
      price: 20,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
    },
  ]);

  const getEscortsData = async () => {
    const data = await getEscorts();
    setImageurlcards(data.escorts);
    return data;
  };

  const [excortData, setExcortData] = useState();

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  const [imageUrl1, setImageurl] = useState([
    {
      images: images.escortimg1,
      name: "Florence J. Smi th",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.escortimg2,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      Email: "florencej.smith@gmail.com",
      _id: 154894561218,
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.escortimg3,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      Email: "florencej.smith@gmail.com",
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.asiangir,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      Email: "florencej.smith@gmail.com",
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
  ]);

  const handleImageClick = (index) => {
    setExcortData(imageUrl[index]);
    setSelectedImageIndex(index);
  };

  useEffect(() => {
    const _id = location.pathname.split("/").pop();

    if (_id) {
      // setId(_id);
    }
    if (location.state && location.state.item) {
      setSelectedPhoto(null)
      setExcortData(location.state.item);
      setImageurl(location?.state?.item?.listingDetails?.photourl)
    }
    handleScrollToTop();
  }, [location.pathname, location.state]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scrolling effect
    });
  };



  return (
    <div className="bg-purplegray">
      <div className="flex pt-20 items-center text-center pb-10">
        <div
          className="text-sm lg:ml-10 ml-2 active:bg-purple bg-whitepink biggerlaptop:w-14 lg:w-10 md:w-10 sm:w-10 w-8 biggerlaptop:h-12 lg:h-10 md:h-10 sm:h-10 h-8 font-semibold flex items-center justify-center cursor-pointer active:opacity-50"
          onClick={() => {
            navigate("/Escorts/All");
          }}
        >
          <img
            src={images.arrowleft}
            className="w-4 max-w-4"
            alt="Arrow Left"
          />
        </div>
        <div className="flex justify-center w-90p text-nowrap flex text-white font-bold text-3xl sm:text-3xl md:text-3xl lg:text-3xl  ">
          Escort Details
        </div>
      </div>
      <div className="grid  pb-60 mt-10 lg:mt-0  ">
        <div className=" flex flex-col  lg:flex-row justify-center md:items-center sm:items-center w-full  lg:pl-20   md:pl-20 sm:pl-20 pl-4 ">
          <img
            src={selectedPhoto || excortData?.profilePhoto}
            alt=""
            className="lg:w-96  md:w-96 sm:w-60 w-60"
          />
          <div className="lg:hidden  w-76p  justify-center gap-3">
            <div className="lg:w-40p md:w-full  sm:w-full w-full h-20  gap-2 flex lg:justify-center md:justify-center sm:justify-center justify-start">
              {imageUrl1 && imageUrl1.map((photo, index) => {
                return <img key={index} src={photo} alt={`Photo ${index}`} />;
              })}
            </div>
          </div>
          <div className="border-b-2 flex lg:hidden mt-10 border-whitepink opacity-25 pl-4 ml-2 w-80p"></div>
          <div className="flex flex-col items-start lg:pl-10 pl-4 space-y-5 pt-10 ">
            <div className="">
              <div className="text-white opacity-30 flex lg:text-sm md:text-xl sm:text-lg text-sm items-start">
                Name
              </div>
              <div className="text-whitepink opacity-90  lg:text-xl md:text-2xl sm:text-lg text-sm">
                {excortData?.firstname} {excortData?.lastname}
              </div>
            </div>
            <div className="">
              <div className="text-white opacity-30 lg:text-xl md:text-xl sm:text-lg text-sm items-start flex items-start">
                Email
              </div>
              <div className="text-whitepink opacity-90  lg:text-xl md:text-2xl sm:text-lg text-base">
                {excortData?.email}
              </div>
            </div>
            <div className="">
              <div className="text-white opacity-30 lg:text-xl md:text-xl sm:text-lg text-sm items-start flex items-start">
                Phone No
              </div>
              <div className="text-whitepink opacity-90  lg:text-xl md:text-2xl sm:text-lg text-base">
                + {excortData?.listingDetails?.number}
              </div>
            </div>
            <div className="">
              <div className="text-white opacity-30 lg:text-xl md:text-xl sm:text-lg text-sm items-start flex items-start">
                Location
              </div>
              <div className="text-whitepink opacity-90  lg:text-xl md:text-2xl sm:text-lg text-base">
                {excortData?.location}
              </div>
            </div>
            <div className="flex">
              <div className="text-white flex flex-col border-r pr-10 ">
                <div className="opacity-30">Age</div>
                <div className="text-xl">{excortData?.listingDetails?.age}</div>
              </div>
              <div className="text-white pl-10">
                <div className="opacity-30">height</div>
                <div className="text-xl">
                  {excortData?.listingDetails?.height}
                </div>
              </div>
            </div>
            <div className="flex pb-1 flex-col">
              <div className="text-white opacity-30">Hourly Rate</div>
              <div className="lg:text-5xl md:text-5xl sm:text-3xl text-2xl   text-white font-bold">
                {excortData?.listingDetails?.hourlyRate} $
              </div>
            </div>
            {/* <div className="flex mt-4  lg:flex-row w-full">
              <div className="text-white bg-purple lg:w-48 md:w-48 sm:w-48 w-32  lg:text-xl md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center    cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50">
                Contact
              </div>
              <div
                className="text-purple bg-white lg:w-48 md:w-48 sm:w-48 w-32  lg:text-xl md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center font-semibold  ml-2 cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                onClick={() => {
                  setIsEscortModalOpen(!isEscortModalOpen);
                }}
              >
                Book Now
              </div>
            </div> */}
          </div>
        </div>
        <div className=" w-full pt-3 ">
          <div className="lg:flex hidden w-76p  justify-center gap-3 ">
            <div
              className="lg:w-40p md:w-40p sm:w-full  w-full lg:h-32 md:h-28 sm:w-12 w-12
              gap-2 flex lg:justify-center md:justify-center sm:justify-center justify-start"
            >
              {imageUrl1 && imageUrl1.map((item, index) => (
                <div
                  key={index}
                  className="flex drop-shadow-lg "
                // onClick={() => {
                //   const _id = item._id;
                //   navigate(`/Modeldetails/${_id}`, { state: { item } });
                // }}
                onClick={() => setSelectedPhoto(item)}
                >
                  <img
                    src={item}
                    alt=""
                    className="cursor-pointer active:opacity-50 w-18 h-full"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full items-center flex flex-col pt-10  text-white text-lg font-semibold  justify-center">
            <div className="opacity-30 w-38p">Description</div>
            <div className="text-white lg:w-50p md:w-50p sm:w-50p pt-4 lg:pl-28 md:pl-28 sm:pl-2 pl-4 pr-4 ">
              {excortData?.listingDetails?.description}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-whitepink border-opacity-10  mx-auto mt-10 "></div>
      <div className="">
        <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col justify-between pt-20 ">
          <div className="text-white font-bold lg:text-5xl md:text-5xl sm:text-5xl xs:text-4xl text-3xl lg:pl-10 md:pl-10 sm:pl-10 pl-1 text-nowrap lg:pb-0 md:pb-0 sm:pb-0 pb-10  ">
            Recommended Escort
          </div>
          <div className="flex lg:flex md:flex sm:flex flex lg:w-40p md:w-40p sm:w-40p   lg:justify-end md:justify-end sm:justify-end pr-2  sm:pr-12 w-full justify-end   ">
            <div
              className=" text-sm bg-purple rounded-sm px-4 py-4 mr-1 font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handlePrevious}
            >
              <img src={images.arrowleft} className="w-4 max-w-4 " />
            </div>
            <div
              className="text-sm bg-whitepink text-black rounded-sm px-4  lg:py-0 md:py-0 sm:py-0 py-4  font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handleNext}
            >
              <img src={images.arrowright} className="w-4 max-w-4" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:pl-10 md:pl-10 sm:pl-10 lg:pr-10 md:pr-10 sm:pr-10 pb-20 ml-4 mr-4">
        {imageUrl && <Escortslider images={imageUrl} ref={imageGridRef} />}
      </div>

      {isEscortModalOpen && (
        <Escortmodal excortData={excortData} toggleModal={toggleEscortModal} />
      )}
    </div>
  );
}
