import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../../screens/Home";
import About from "../../screens/About";
import Layout from "../../screens/Layout";
import Escort from "../../screens/Escort";
import Livemodels from "../../screens/LiveModels";
import Modeldetails from "../../screens/Modeldetails";
import Livestream from "../../components/Livestream";
import Sublivemodels from "../../screens/SubLivemodels";
// import Settingspage from "../../screens/Settinpage"
// import Register from "../../components/Register"
// import AuthModal from "../../components/AuthModal";
// import Signin from "../../components/Login";
import Settings from "../../screens/Setting";
// import Settingspage from "../../screens/Settinpage";
import Register from "../../components/Register";
// import AuthModal from "../../components/AuthModal";
// import Signin from "../../components/Login";
import Escortform from "../../screens/Escortform";
import Editlist from "../../screens/Editlist";
import Viewlisting from "../../screens/Viewlisting";
import EditListing from "../../screens/EditListing";
// import Setting from "../../screens/Setting";
import Setting from "../../screens/Setting";
import Joinstream from "../../screens/Joinstream";
import Forms from "../../screens/Forms";
import Golive from "../../screens/Golive";
import Clickedadd from "../../screens/Clickedadd";
import VerifyEscort from "../../screens/VerifyEscort";
import MyAds from "../../screens/MyAds";
import MyAdsDetails from "../../screens/MyAdsDetails";
import ApplyVerify from "../../screens/ApplyVerify";
import Contact from "../../screens/Contact";
import ContactUs from "../../screens/ContactUs";
import ProtectedRoute from "./protectedRouting";
import ReportOffense from "../../screens/ReportOffense";
import ReportTOS from "../../screens/ReportTOS";
import ReportTOSViolation from "../../screens/ReportCopyright";
import ReportCopyright from "../../screens/ReportCopyright";
import PrivacyPolicy from "../../screens/PrivacyPolicy";
import Terms from "../../screens/Terms";
import RulesAndPosting from "../../screens/RulesAndPosting";
import Favorites from "../../screens/Favorites";
import ManageUpgrades from "../../screens/ManageUpgrades";
import LiveStreamingTest from "../../screens/LiveStreamingTest";
import UserLiveStream from "../../screens/UserLiveStream";
import GiftCard from "../../screens/GiftCard";
import PaymentTutorial from "../../screens/PaymentTutorial";
import VerifyPayment from "../../screens/VerifyPayment";
import GiftCardUser from "../../screens/GiftCardUser";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/About",
        element: <About />,
      },
      {
        path: "/Settings",
        element: <Settings />,
      },
      {
        path: "/Escorts",
        element: <Escort />,
        children: [
          {
            path: "/Escorts/All",
            element: <Escort />,
          },
          {
            path: "/Escorts/AvailableToday",
            element: <Escort />,
          },
          {
            path: "/Escorts/AvailableNow",
            element: <Escort />,
          },
        ],
      },
      {
        path: "/Escorts/escortform",
        element: <Escortform />,
      },
      {
        path: "/Escorts/editlist",
        element: <Editlist />
      },
      {
        path: "/Escorts/viewlisting",
        element: <Viewlisting />
      },
      {
        path: "/LiveStream/Joinstream",
        element: <Joinstream />
      },
      {
        path: "/golive",
        element: <Golive />
      },
      {
        path: "/modeladd/:_id",
        element: <Clickedadd />
      },
      {
        path: "/forms",
        element: <Forms />
      },
      {
        path: "/LiveModels",
        element: <Livemodels />,
        // element: <ProtectedRoute Compunent={Livemodels} />,
        // element: <Livemodels />,
        children: [
          {
            path: "/LiveModels/Newest",
            element: <Livemodels />,
          },
          {
            path: "/LiveModels/Popular",
            element: <Livemodels />,
          },
          {
            path: "/LiveModels/Favorites",
            element: <Livemodels />,
          },
        ],
      },
      {
        path: "/Modeldetails/:_id",
        element: <Modeldetails />,
      },
      {
        path: "/LiveStream/:_id",
        element: <Sublivemodels />,
      },
      {
        path: "/applyverify",
        element: <ApplyVerify />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/contactus",
        element: <ContactUs />
      },
      {
        path: "/giftcard",
        element: <GiftCard/>
      },
      {
        path: "/giftcarduser",
        element: <GiftCardUser/>
      },
      {
        path: "/paymenttutorial",
        element: <PaymentTutorial/>
      },
      {
        path: "/MyAds",
        element: <MyAds />,
      },
      {
        path: "/MyAdsDetails/:_id",
        element: <MyAdsDetails />,
      },
      {
        path: "/favorites",
        element: <Favorites/>,
      },
      {
        path: "/VerifyEscort/:_id",
        // element: <VerifyEscort />,
        element: <ProtectedRoute Compunent={VerifyEscort} />,
      },
      {
        path: "/VerifyPayment",
        // element: <VerifyEscort />,
        element: <ProtectedRoute Compunent={VerifyPayment} />,
      },
      {
        path: "/report-offense",
        element: <ReportOffense />,
      },
      {
        path: "/report-tos",
        element: <ReportTOS />,
      },
      {
        path: "/report-copyright",
        element: <ReportCopyright />,
      },
      {
        path: "/policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/rules-and-posting-guidelines",
        element: <RulesAndPosting />,
      },
      {
        path: "/manage-upgrades/:_id",
        element: <ManageUpgrades />,
      },
      {
        path: "/liveStreamingTest",
        element: <LiveStreamingTest />,
      },
      {
        path: "/userlivestream/:_id",
        element: <UserLiveStream />,
      },
      
    ],
  },
]);

const Routing = () => {
  return <RouterProvider router={router} />;
};

export default Routing;
