import React, { useEffect, useMemo, useRef, useState } from "react";
import images from "../../services/images";
import Livemessages from "../../components/LiveMessages";
import ResponsiveLivestreamcards from "../../components/livecards";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Webcam from "react-webcam";
// import { useRef } from "react";
import Livestreammodal from "../../components/Livestreamodal";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { ClipLoader } from "react-spinners";
import {
  getEscorts,
  streamingToken,
  updateStreamingId,
} from "../../services/API/index.js";

import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import token, { selectToken } from "../../store/token/index.js";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/userData/index.js";

export default function Sublivemodels() {
  const navigate = useNavigate();
  const location = useLocation();
  const imageGridRef = useRef();
  const webRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showjoinow, setshowjoinow] = useState(false);
  const [Subscribe, setsubscribe] = useState(false);
  const [Subscribedmodel, setSubscribedmodel] = useState(false);
  const [testId, setMeetingId] = useState(false);
  const { meetingId } = location.state || {};
  const { isLoggedIn, escortloggedin } = useAuth();
  const [liveData, setLiveData] = useState();
  const authToken = useSelector(selectToken);
  const escort = useSelector(selectUserData);
  const getEscortsData = async () => {
    const data = await getEscorts();
    return data;
  };

  useEffect(() => {
    getEscortsData();
  }, []);

  const subscribetomodal = () => {
    setSubscribedmodel(!Subscribedmodel);
  };

  useEffect(() => {
    const _id = location.pathname.split("/").pop();

    if (_id) {
      // setId(_id);
    }
    if (location.state && location.state.item) {
      // setSelectedPhoto(null)
      setLiveData(location.state.item);
      // setImageurl(location?.state?.item?.listingDetails?.photourl)
    }
    handleScrollToTop();
  }, [location.pathname, location.state]);

  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  const handleDiamondClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handlejoinnow = () => {
    setshowjoinow(!showjoinow);
  };

  const openclosemodal = () => {
    setsubscribe(!Subscribe);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scrolling effect
    });
  };

  function ParticipantView(props) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
      useParticipant(props.participantId);

    const videoStream = useMemo(() => {
      if (webcamOn && webcamStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
      if (micRef.current) {
        if (micOn && micStream) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);

          micRef.current.srcObject = mediaStream;
          micRef.current
            .play()
            .catch((error) =>
              console.error("videoElem.current.play() failed", error)
            );
        } else {
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);

    return (
      <div style={{ transform: "scaleX(-1)" }}>
        {webcamOn && (
          <ReactPlayer
            //
            playsinline // extremely crucial prop
            pip={false}
            light={false}
            controls={false}
            muted={true}
            playing={true}
            url={videoStream}
            height={600}
            width={1300}
            onError={(err) => {}}
          />
        )}
      </div>
    );
  }

  const [joined, setJoined] = useState(null);
  function MeetingView(props) {
    const { join, participants, disableWebcam } = useMeeting({
      onMeetingJoined: () => {
        setJoined("JOINED");
      },
      onMeetingLeft: () => {
        props.onMeetingLeave();
      },
    });
    const joinMeeting = async () => {
      try {
        let response = await updateStreamingId(
          {
            live: true,
            meetingId,
          },
          authToken
        );
        if (response.success) {
          setJoined("JOINING");
          join();
        }
      } catch (error) {
        console.log(error);
      }
    };

    function Controls() {
      const { leave, toggleMic, toggleWebcam } = useMeeting();
      return <></>;
    }

    return (
      <div className="flex justify-center">
        {joined && joined == "JOINED" ? (
          <div>
            <Controls />
            {[...participants.keys()].map((participantId) => (
              <ParticipantView
                participantId={participantId}
                key={participantId}
              />
            ))}
          </div>
        ) : joined && joined == "JOINING" ? (
          <p>Going Live...</p>
        ) : (
          <button
            onClick={joinMeeting}
            className="bg-purple text-nowrap lg:w-44 sm:w-40  w-32 py-3  flex items-center lg:text-md md:text-sm  text-xs justify-center  text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
          >
            Stream Now
          </button>
        )}
      </div>
    );
  }

  const onMeetingLeave = () => {
    setMeetingId(null);
    handleLeave();
  };

  const handleLeave = async () => {
    try {
      let response = await updateStreamingId(
        {
          live: false,
          meetingId: "",
        },
        authToken
      );
      if (response.success) {
        // console.log(response, "=========>leave");
        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-purplegray">
      <div className="pt-20 pb-10 lg:ml-10 md:ml-0 sm:ml-0 ml-0 ">
        <div className="flex w-full items-center justify-between ">
          <div
            className="bg-white  ml-2 flex justify-center items-center p-3  sm:p-4 cursor-pointer active:oapcity-50"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src={images.arrowleft}
              className="lg:w-5 md:w-4 sm:w-3 w-4"
              alt="Arrow Left"
            />
          </div>
          {isLoggedIn && (
            <>
              <div className="flex w-full items-center  justify-start ">
                <div className="lg:mr-12 md:pl-1 sm:pl-1 pl-1  text-white lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold text-nowrap  ">
                  Live Stream
                </div>
              </div>
            </>
          )}
          {!isLoggedIn && (
            <>
              <div className="flex w-full items-center  justify-center ">
                <div className="lg:mr-28 md:pl-1 sm:pl-1 pl-1 h-16  w-40 text-white lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold text-nowrap ">
                  Live Stream
                </div>
              </div>

              {!escortloggedin && joined == "JOINED" && (
                // <Link to={"/"}>
                <div
                  onClick={onMeetingLeave}
                  className="bg-purple  text-nowrap lg:mr-20 mr-4 bg-purple bg-purple lg:h-16 md:h-8  sm:h-8 h-7 flex items-center   lg:text-sm md:text-xs sm:text-xs text-xxs justify-center w-40 text-whitepink font-semibold  pl-4 pr-4 cursor-pointer active:opacity-50"
                >
                  End Stream
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" flex lg:flex-row flex-col w-full lg:pl-10 lg:mb-8">
        <div className="mt-10 flex flex-col w-full p-4 ">
          {escortloggedin ? (
            <Webcam ref={webRef} className="w-full h-auto mb-4 md:mb-0 " />
          ) : (
            <MeetingProvider
              config={{
                meetingId,
                micEnabled: true,
                webcamEnabled: true,
              }}
              token={streamingToken}
            >
              <MeetingView
                meetingId={meetingId}
                onMeetingLeave={onMeetingLeave}
              />
            </MeetingProvider>
          )}
          {joined && (
            <>
              <div className="flex items-center justify-between flex-col sm:flex-row w-full pl-3 ">
                <div className="flex pl-2 mt-10">
                  <div
                    className="flex items-center cursor-pointer text-white"
                    style={{
                      width: "49px",
                    }}
                  >
                    <div className="flex relative w-full mb-10  right-5 ">
                      <div className="absolute  w-16 h-16 rounded-full  border-2 border-purple ">
                        <div className="relative pl-1 pr-1 pt-1     ">
                          <img
                            src={escort?.profilePhoto}
                            alt=""
                            className="h-14 rounded-full w-14 "
                          />
                        </div>

                        <div
                          className="absolute top-12 w-10 left-2 text-sm text-center"
                          style={{
                            background: "rgb(190,66,222)",
                            backgroundImage:
                              "linear-gradient(90deg, rgba(190,66,222,1) 0%, rgba(74,3,141,1) 67%)",
                          }}
                        >
                          Live
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:ml-2 md:ml-2 sm:ml-2 ml-1">
                    <div className="text-white pt-5 lg:text-xl md:text-lg sm:text-md text-xs font-semibold cursor-pointer whitespace-nowrap">
                      {escort?.firstname} {escort?.lastname}
                    </div>
                  </div>
                </div>
                <div className="biggerlaptop:flex gap-2 lg:flex md:flex flex w-full items-center lg:justify-end md:justify-end sm:justify-end justify-end lg:mr-0 md:mr-2 sm:mr-2 mr-2  lg:mt-0 md:mt-0 sm:mt-0  mt-5"></div>
              </div>

              <div className="mt-10 lg:px-0 md:px-1 sm:px-1 px-4">
                <div className="flex  md:flex-row bg-aboutcolor w-full rounded-t-2xl ">
                  <div className="text-white text-center lg:text-xl md:text-2xl sm:text-lg text-lg pl-2 lg:pt-4 md:pt-4 sm:pt-5 pt-5 ">
                    <span className="font-bold p-1"> About</span>{" "}
                    {escort?.about}
                  </div>
                </div>
                <div className="flex lg:text-lg md:text-lg sm:text-base text-sm text-left  flex-col  bg-aboutcolor w-full rounded-b-2xl pb-10">
                  <div className="text-white pl-4 pt-2 ">
                    {escort?.Description}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {Subscribe && (
        <Livestreammodal
          openclosemodal={openclosemodal}
          subscribetomodal={subscribetomodal}
        />
      )}
    </div>
  );
}
