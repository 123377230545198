import React, { useEffect, useRef, useState } from "react";
import images from "../../services/images.js";
import StarRating from "../../components/Starating/index.jsx";
import Escortslider from "../../components/Escortslider/index.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import Escortmodal from "../../components/Escortmodal/index.jsx";
import {
  getEscorts,
  getAds,
  toggleFavoriteAd,
} from "../../services/API/index.js";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../store/token/index.js";
import { ClipLoader } from "react-spinners";
import { selectUserData, setUsereData } from "../../store/userData/index.js";
import { selectLogedIn } from "../../store/logedIn/index.js";

export default function Clickedadd() {
  useEffect(() => {
    handleScrollToTop();
  }, []);

  const [removeFavorite, setRemoveFavorite] = useState();

  const userLogin = useSelector(selectLogedIn);

  const user = useSelector(selectUserData);

  useEffect(() => {
    let fav = user?.favorites;
    const isFavorite = fav?.some((ad) => ad._id === bigEscortData?._id);
    setRemoveFavorite(isFavorite);
  });

  const [isEscortModalOpen, setIsEscortModalOpen] = useState(false);
  const [onlineServiceData, setOnlineServiceData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [modalEscortdata, setEscortData] = useState([]);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const toggleEscortModal = () => {
    setIsEscortModalOpen(!isEscortModalOpen);
  };

  const getAdsData = async () => {
    const addData = await getAds();
    // setConstdata(addData.advertisements);
    return addData;
  };
  const getEscortsData = async () => {
    const data = await getEscorts();
    setImageurlcards(data.escorts);
    return data;
  };
  useEffect(() => {
    getAdsData();
    getEscortsData();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const imageGridRef = useRef();

  const [imageUrl, setImageurlcards] = useState([
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card4,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card1,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card2,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
    // {
    //   images: images.card3,
    //   name: "KATONA",
    //   location: "KATONA BEATRIX",
    //   age: 24,
    //   dots: images.dots,
    //   rating: 3,
    //   Search: "Bandi1",
    //   price: 20,
    //   Phone: +165489652,
    //   location1: "London",
    //   Height: "5.2ft",
    //   Hourly: "$20",
    //   Email: "florencej.smith@gmail.com",
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    // },
  ]);

  const [bigEscortData, setExcortData] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [videos, setVideos] = useState([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [selectedImgIndex, setSelectedImgIndex] = useState();

  const handleImageClick = (index) => {
    setExcortData(imageUrl[index]);
    setSelectedImageIndex(index);
  };

  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  useEffect(() => {
    const _id = location.pathname.split("/").pop();

    if (_id) {
      // setId(_id);
    }
    if (location.state && location.state.addData) {
      console.log("------------>>><<<", location.state.addData.ad_description);
      setExcortData(location.state.addData);
      setSelectedPhoto(location?.state?.addData?.pictures[0]);
      setOnlineServiceData(location?.state?.addData?.online_services);
      setLanguageData(location?.state?.addData?.language_spoken);
      setAvailabilityData(location?.state?.addData?.availability);
      setEscortData(location?.state?.addData?.escortData);
      setVideos(location?.state?.addData?.videos);
    }
    handleScrollToTop();
  }, [location.pathname, location.state]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scrolling effect
    });
  };

  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  const userData = useSelector(selectUserData);

  // const handleFavoriteAd = async () => {
  //   setLoader(true);
  //   try {
  //     let response = await toggleFavoriteAd({ adId: bigEscortData?._id }, token);
  //     dispatch(setUsereData(response?.user));
  //     let fav = response?.user?.favorites;
  //     const filteredAdIDs = fav.filter((adID) => adID._id == bigEscortData?._id);

  //     console.log(filteredAdIDs, "fav==================?>?????");
  //     if (filteredAdIDs) {
  //       console.log("=====??worlss");
  //       setLoader(false);
  //       setRemoveFavorite(true);
  //     } else {
  //       setLoader(false);
  //       setRemoveFavorite(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };

  const handleFavoriteAd = async () => {
    setLoader(true);
    try {
      let response = await toggleFavoriteAd(
        { adId: bigEscortData?._id },
        token
      );
      dispatch(setUsereData(response?.user));
      let fav = response?.user?.favorites;

      // Check if the current ad is in the favorites array
      const isFavorite = fav.some((ad) => ad._id === bigEscortData?._id);

      console.log(isFavorite, "isFavorite");

      if (isFavorite) {
        console.log("=====??worlss");
        setLoader(false);
        setRemoveFavorite(true);
      } else {
        setLoader(false);
        setRemoveFavorite(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <div className="bg-purplegray">
      <div className="flex pt-20 items-center text-center pb-10">
        <div
          className="text-sm lg:ml-10 ml-2 active:bg-purple bg-whitepink biggerlaptop:w-14 lg:w-10 md:w-10 sm:w-10 w-6 biggerlaptop:h-12 lg:h-10 md:h-10 sm:h-10 h-6 font-semibold flex items-center justify-center cursor-pointer active:opacity-50"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={images.arrowleft}
            className="w-4 max-w-4"
            alt="Arrow Left"
          />
        </div>
        {bigEscortData?.ad_title ? (
          <div className="flex justify-center w-90p max-w-full text-white font-bold text-xl sm:text-3xl md:text-3xl lg:text-3xl">
            {bigEscortData?.ad_title}
          </div>
        ) : (
          <div className="flex justify-center w-90p max-w-full text-white font-bold text-xl sm:text-3xl md:text-3xl lg:text-3xl">
            Escort Details
          </div>
        )}
      </div>
      <div className=" flex  w-full  ">
        <div className=" w-full  flex  lg:flex-row flex-col items-center ">
          <div className="gap-2 lg:gap-0  lg:w-60p w-90p flex flex-col  items-center justify-center     items-end ">
            <div className=" w-full  flex justify-center">
              {showVideo ? (
                <video
                  autoPlay
                  controls
                  key={`${videos[selectedVideoIndex]}_${selectedVideoIndex}`}
                  className="w-[95%] mx-auto h-[50vh] md:h-[60vh]"
                >
                  <source src={videos[selectedVideoIndex]} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={selectedPhoto}
                  alt=""
                  className="1470:w-[500px] 1470:max-w-[500px] 1300:w-[400px] 1300:max-w-[400px] sm:h-[40vh] h-[30vh]  lg:w-full md:w-90 sm:w-50 w-60"
                />
              )}
            </div>
            <div className=" w-full pt-3 flex flex-col ">
              <div className="lg:flex flex   w-full  justify-center gap-3 ">
                <div
                  className="lg:w-full md:w-full  sm:w-full  w-full lg:h-32 md:h-full sm:w-12 w-12 gap-2
                   flex lg:flex-row   lg:justify-center md:justify-center sm:justify-center justify-start"
                >
                  {bigEscortData?.pictures.map((url, index) => (
                    <div
                      key={index}
                      className="flex  drop-shadow-lg "
                      onClick={() => {
                        setShowVideo(false);
                        handlePhotoClick(url);
                      }}
                    >
                      <img
                        src={url}
                        alt={`Image ${index}`}
                        className="cursor-pointer  active:opacity-50 w-28 h-full sm:w-32 md:w-40 lg:w-48 xl:w-56"
                      />
                    </div>
                  ))}
                  {videos?.map((item, index) => {
                    return (
                      <video
                        key={`${item}_${index}`}
                        className="w-28 h-full cursor-pointer"
                        onClick={() => {
                          setShowVideo(true);
                          setSelectedVideoIndex(index);
                        }}
                      >
                        <source src={item} />
                        Your browser does not support the video tag.
                      </video>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full flex flex-col sm:flex-row items-center justify-center ">
            <div className="flex-col  flex  w-96p ">
              <div className="flex flex-col sm:flex-row items-center justify-center ">
                <div className="w-full sm:mb-0">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal">
                      Name
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none text-white  text-xs  sm:text-base md:text-lg sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    value={bigEscortData?.name}
                    disabled
                  />
                </div>
                {bigEscortData?.phone_number === "" ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className=" h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Phone Number
                        </div>
                      </div>
                      <input
                        type="number"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.phone_number}
                        disabled
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center ">
                <div className="w-full">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      Email
                    </div>
                  </div>
                  <input
                    type="email"
                    className="bg-transparent focus:outline-none text-white text-xs sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    placeholder="Enter Email"
                    value={bigEscortData?.escortData?.email}
                    disabled
                  />
                </div>
                {bigEscortData?.height_inches === "in" ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-19 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Height inch
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Enter Height"
                        value={bigEscortData?.height_inches}
                        disabled
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                {bigEscortData?.height_feet === "ft" ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full ">
                      <div className="w-19 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Height
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder=""
                        value={`${bigEscortData?.height_feet}`}
                        disabled
                      />
                    </div>
                  </>
                )}
                {bigEscortData?.weight === "lb" ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Weight
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.weight}
                        disabled
                      />
                    </div>
                  </>
                )}
                {bigEscortData?.chest === "..." ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Chest
                        </div>
                      </div>
                      <input
                        type="number"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.chest}
                        disabled
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                {bigEscortData?.waist === "..." ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full ">
                      <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Waist
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Enter Location"
                        value={bigEscortData?.waist}
                        disabled
                      />
                    </div>
                  </>
                )}

                {bigEscortData?.hair_color === "..." ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-18 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Hair Color
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.hair_color}
                        disabled
                      />
                    </div>
                  </>
                )}

                <div className="w-full sm:ml-5">
                  {bigEscortData?.eye_color === "..." ? (
                    <></>
                  ) : (
                    <>
                      <div className="w-18 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Eye Color
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-sm sm:text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.eye_color}
                        disabled
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center ">
                <div className="w-full ">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      Ethnicity
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none text-white text-xs sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    placeholder="Enter Location"
                    value={bigEscortData?.ethnicity}
                    disabled
                  />
                </div>
                {bigEscortData?.ethnic_origin ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-18 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Ethnic origin
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        value={bigEscortData?.ethnic_origin}
                        disabled
                      />
                    </div>
                  </>
                )}
                <div className="w-full sm:ml-5">
                  <div className="w-18 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      Hourly_rate
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    value={bigEscortData?.hourly_rate}
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center ">
                <div className="w-full ">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      Country
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none text-white text-xs sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    placeholder="Enter Location"
                    value={bigEscortData?.country}
                    disabled
                  />
                </div>
                <div className="w-full sm:ml-5">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      State
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none remove-arrow text-white text-xs  sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14  pl-5 "
                    value={bigEscortData?.state}
                    disabled
                  />
                </div>
                <div className="w-full sm:ml-5">
                  <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                    <div className="text-center text-fuchsia-100 text-xs font-normal ">
                      City
                    </div>
                  </div>
                  <input
                    type="text"
                    className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-base md:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                    value={bigEscortData?.city}
                    // onChange={(e) => setAge(e.target.value)}
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                {bigEscortData?.cup_size === "..." ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full ">
                      <div className=" h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Cup Size
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                        placeholder="Enter Location"
                        value={bigEscortData?.cup_size}
                        disabled
                      />
                    </div>
                  </>
                )}
                {bigEscortData?.hips === "..." ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full sm:ml-5">
                      <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                        <div className="text-center text-fuchsia-100 text-xs font-normal ">
                          Hips
                        </div>
                      </div>
                      <input
                        type="text"
                        className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14  pl-5 "
                        value={bigEscortData?.hips}
                        disabled
                      />
                    </div>
                  </>
                )}
                {bigEscortData?.age === '...' ? (<></>) : (<>

                  <div className="w-full sm:ml-5">
                    <div className="w-14 h-6 px-2 py-1 bg-purplegray justify-center relative top-3 left-3 items-center  inline-flex">
                      <div className="text-center text-fuchsia-100 text-xs font-normal ">
                        Age
                      </div>
                    </div>
                    <input
                      type="text"
                      className="bg-transparent focus:outline-none remove-arrow text-white text-xs sm:text-lg w-[100%] border rounded-xl border-whitepink border-opacity-20 h-14 pl-5 "
                      value={bigEscortData?.age}
                      // onChange={(e) => setAge(e.target.value)}
                      disabled
                    />
                  </div>
                </>)}

              </div>
              <div className="flex flex-col gap-5 w-full  text-white">
                <div className="gap-4 text-white items-center w-full lg:w-[80%] justify-between mt-5 flex flex-col lg:flex-row md:flex-row">
                  <div className="flex flex-col gap-2 w-full lg:w-auto">
                    <div className="text-base sm:text-xl opacity-20 text-whitepink">
                      Online Services
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-sm sm:text-base">
                      {onlineServiceData?.length > 0 &&
                        onlineServiceData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="bg-purple flex items-center h-[35px] rounded-2xl justify-center p-4"
                            >
                              {item}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 w-full lg:w-auto">
                    {languageData?.filter((item) => item.trim() !== "").length >
                      0 && (
                        <>
                          <div className="text-base sm:text-xl opacity-20 text-whitepink">
                            Language Spoken
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-sm sm:text-base">
                            {languageData
                              .filter((item) => item.trim() !== "")
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="bg-purple flex items-center h-[35px] rounded-2xl justify-center p-3 "
                                >
                                  {item}
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                  </div>
                </div>
                <div className="flex flex-col gap-2 md:pr-0">
                  <div className="text-base sm:text-xl opacity-20 text-whitepink">
                    Availability
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6 gap-2 text-sm sm:text-base">
                    {availabilityData?.length > 0 &&
                      availabilityData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="bg-purple flex items-center  h-[35px] rounded-2xl justify-center p-4"
                          >
                            {item}
                          </div>
                        );
                      })}
                  </div>
                  {/* <div className="flex gap-4 flex-wrap h-auto text-sm sm:text-base">
                    <div className="bg-purple flex rounded-2xl items-center w-40 h-9 justify-center">
                      {bigEscortData?.Monday}
                    </div>
                    <div className="bg-purple flex items-center w-40 rounded-2xl h-9 justify-center">
                      {bigEscortData?.Wednesday}
                    </div>
                    <div className="bg-purple flex items-center w-40 rounded-2xl h-9 justify-center">
                      {bigEscortData?.Friday}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-whitepink">
        <div className="max-w-screen-lg   mx-[6%] mt-20 pb-20">
          <div className="w-full  flex flex-col   text-white  font-semibold  ">
            <div className="opacity-30 w-38p text-sm sm:text-lg">
              Description
            </div>
            <div className="text-white  text-xs sm:text-lg">
              {bigEscortData?.ad_description}
            </div>
          </div>
          <div className="opacity-30 w-38p pt-10 text-sm sm:text-lg">
            Allow message via whatsapp
          </div>
          {bigEscortData?.allow_message_via_whatsapp ? (
            <div>Yes</div>
          ) : (
            <div>No</div>
          )}
          {userLogin ? (
            <div className=" mt-4   w-full">
              {/* <div className="text-purple bg-white lg:w-48 md:w-48 sm:w-48 w-32  lg:text-xl md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center    cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50">
              Contact
            </div> */}
              {loader ? (
                <div
                  className="mt-5 text-white bg-purple lg:w-48 md:w-48 sm:w-48 w-40 lg:text-md md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center ml-2 cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                  onClick={handleFavoriteAd}
                >
                  <ClipLoader
                    color="#FFFF"
                    cssOverride={{
                      height: "25px",
                      width: "25px",
                    }}
                  />
                </div>
              ) : (
                <div>
                  {!removeFavorite ? (
                    <div
                      className="mt-5 text-white bg-purple lg:w-48 md:w-48 sm:w-48 w-40 lg:text-md md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center ml-2 cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                      onClick={handleFavoriteAd}
                    >
                      <img
                        src={images.heartOutline}
                        className="w-4 max-w-4 mr-1"
                      />
                      Add to favorites
                    </div>
                  ) : (
                    <div
                      className="text-white bg-purple lg:w-48 md:w-48 sm:w-48 w-40 lg:text-md md:text-base sm:text-sm text-xs lg:h-12 md:h-12 sm:h-10 h-10 flex items-center justify-center ml-2 cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                      onClick={handleFavoriteAd}
                    >
                      <img
                        src={images.heartFilled}
                        className="w-4 max-w-4 mr-1"
                      />
                      Remove from favorites
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="border-t border-whitepink border-opacity-10  mx-auto mt-10 "></div>
      <div className="">
        <div className="flex flex-col lg:flex-row md:flex-row sm:flex-row   justify-between pt-20 ">
          <div className="text-white font-bold lg:text-5xl md:text-4xl sm:text-3xl xs:text-4xl text-2xl pl-5 lg:pl-10 md:pl-10 sm:pl-10 pl-1 text-nowrap lg:pb-0 md:pb-0 sm:pb-0  ">
            Recommended Escort
          </div>
          <div className="flex lg:flex md:flex sm:flex flex lg:w-40p md:w-40p sm:w-40p   lg:justify-end md:justify-end sm:justify-end pr-2  sm:pr-13 w-full justify-end   ">
            <div
              className=" text-sm bg-purple rounded-sm px-3 sm:px3 sm:py-4 py-3 mr-1 font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handlePrevious}
            >
              <img src={images.arrowleft} className="w-4 max-w-4 " />
            </div>
            <div
              className="text-sm bg-whitepink text-black rounded-sm px-2 sm:px3  lg:py-0 md:py-0 sm:py-4 py-2  font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
              onClick={handleNext}
            >
              <img src={images.arrowright} className="w-4 max-w-4" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:pl-10 md:pl-10 sm:pl-10 lg:pr-10 md:pr-10 sm:pr-10 pb-20 ml-4 mr-4  ">
        <Escortslider images={imageUrl} ref={imageGridRef} />
      </div>
      {isEscortModalOpen && (
        <Escortmodal
          excortData={modalEscortdata}
          toggleModal={toggleEscortModal}
        />
      )}
    </div>
  );
}
