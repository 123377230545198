import React, { useRef, useState, useEffect } from "react";
import ResponsiveImageGrid from "../../components/cards/ResponsiveImageGrid";
import images from "../../services/images";
import Modelcards from "../../components/ModelCards";
import Modelfav from "../../components/ModelFav";
import ModelPop from "../../components/ModelPop";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getEscorts } from "../../services/API/index.js";

export default function Livemodels() {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;
  const [tabname, setTabname] = useState("newest");
  const [models, setModels] = useState([]);

  const handleTabChange = (tab) => {
    setselectedTab(tab);
    setTabname(tab);
    navigate(`/LiveModels/${tab}`);
    setDropdownVisible(false);
  };
  

  const getEscortsData = async () => {
    const data = await getEscorts();
    // console.log(data.escorts);
    const liveEscorts = data?.escorts?.filter(escorts => escorts.live === true);
    // console.log('=========???', liveEscorts);
    setModels(liveEscorts)
  };

  useEffect(() => {
    if (location.pathname === "/LiveModels/Newest") {
      getEscortsData();
    }
  }, []);

  const [toggleimages, settoggleimages] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [selectedTab, setselectedTab] = useState("Newest");

  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-purplegray">
      <div
        className="bg-cover bg-center h-36 sm:h-96"
        style={{
          backgroundImage: `url(${images.livemodalbg})`,
        }}
      >
        <div className="  flex justify-between items-center space-between bg-cover text-base">
          <div className="flex flex-col w-full items-center h-48  sm:h-52 lg:h-96 items-center  justify-end   ">
            <div className="text-white lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold pb-10 sm:pb-15 lg:pb-20">
              LIVE MODELS
            </div>
            {!models?.length && (
              <div className=" text-center  text-whitepink flex  text-2xl  justify-center ">
                We apologize, but there are no live models available at this time. Please refresh the page to see any updates.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" pt:6 sm:pt-20 lg:p-0 bg-purplegray">
        {activePath === "/LiveModels/Newest" && (
          <div className="border-gray-500 pt-0 sm:pt-10 pl-10 bg-purplegray flex flex-row">
            <div className="text-white font-bold text-3xl pb-10"></div>
          </div>
        )}
        {activePath === "/LiveModels/Popular" && (
          <div className="border-gray-500 pt-10 pl-10 bg-purplegray flex flex-row">
            <div className="text-white font-bold text-3xl pb-10"></div>
          </div>
        )}
        {activePath === "/LiveModels/Favorites" && (
          <div className="border-gray-500 pt-10 pl-10 bg-purplegray flex flex-row">
            <div className="text-white font-bold text-3xl pb-10"></div>
          </div>
        )}
        <div className="bg-purplegray ">
          <div>
            {activePath === "/LiveModels/Newest" && (
              <div>
                <Modelcards models={models} setModels={setModels} />
              </div>
            )}
            {activePath === "/LiveModels/Popular" && (
              <div>
                <ModelPop />
              </div>
            )}
            {activePath === "/LiveModels/Favorites" && (
              <div>
                <Modelfav />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
