import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { streamingToken } from "../../services/API";
import images from "../../services/images";

export default function UserLiveStream() {
  const location = useLocation();
  const navigate = useNavigate();
  const [joined, setJoined] = useState(false);

  const { escort } = location.state || {};
  const meetingId = escort?.meetingId; // Replace with your actual meeting ID
  const token = streamingToken;

  function ParticipantView({ participantId }) {
    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
      useParticipant(participantId);

    const videoStream = useMemo(() => {
      if (webcamOn && webcamStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);
        return mediaStream;
      }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
      if (micRef.current) {
        if (micOn && micStream) {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);

          micRef.current.srcObject = mediaStream;
          micRef.current
            .play()
            .catch((error) =>
              console.error("micRef.current.play() failed", error)
            );
        } else {
          micRef.current.srcObject = null;
        }
      }
    }, [micStream, micOn]);

    return (
      <div className="flex justify-center items-center w-full">

        {webcamOn && (
          <div className="relative w-full max-w-full sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-5xl">
            <div className="aspect-w-16 aspect-h-9">
              <ReactPlayer
                playsinline
                pip={false}
                light={false}
                controls={false}
                muted={true}
                playing={true}
                url={videoStream}
                width="100%"
                height="100%"
                onError={(err) => console.log(err, "participant video error")}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  function MeetingView() {
    const { join, participants, localParticipant, leave } = useMeeting({
      onMeetingJoined: () => setJoined(true),
      onMeetingLeft: () => {
        setJoined(false);
        navigate(-1); // Navigate back when the meeting is left
      },
    });

    const joinMeeting = () => join();

    return (
      <div className="flex justify-center items-center w-full ">
        {joined ? (
          <div className="relative w-full max-w-full sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-5xl">
            {[...participants.keys()]
              .filter((participantId) => participantId !== localParticipant.id)
              .map((participantId) => (
                <ParticipantView
                  participantId={participantId}
                  key={participantId}
                />
              ))}
          </div>
        ) : (

          <div
            onClick={joinMeeting}
            className="bg-purple text-nowrap lg:w-44 sm:w-40  w-32 py-3  flex items-center lg:text-md md:text-sm  text-xs justify-center  text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
          >
            Join
          </div>
        )}

      </div>
    );
  }
  return (
    <div className="pt-20 pb-10 ">
      <div className="flex w-full items-center justify-between mb-5">
        <div
          className="bg-white ml-10 flex justify-center items-center w-12 h-10 sm:w-14 sm:h-12 cursor-pointer active:opacity-50"
          onClick={() => navigate(-1)}
        >
          <img
            src={images.arrowleft}
            className="w-4 sm:w-6  "
            alt="Arrow Left"
          />
        </div>
        {!joined && (
          <>
            <div className="flex w-full items-center justify-center ">
              <div className=" text-white lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold text-nowrap mr-14 ">
                Live Stream
              </div>
            </div>
          </>
        )}
        {joined && (
          <div
            onClick={() => {
              navigate(-1);
            }}
            className=" bg-purple text-nowrap mr-10  sm:h-12 h-10 flex items-center lg:text-lg md:text-md sm:text-sm text-xs justify-center w-32 text-whitepink font-semibold pl-4 pr-4 cursor-pointer active:opacity-50"
          >
            Leave Stream
          </div>
        )}
      </div>
      {!joined && (
        <>
          <div className="flex justify-center  ">
            <img
              src={escort?.profilePhoto}
              alt=""
              className="  sm:h-28 md:h-32 lg:-36 sm:28 md:w-32 lg:w-36 w-24   h-24  w-24  rounded-full align-center"
            />
          </div>
          <div className="flex justify-center ">
            <div className="text-white pt-5 lg:text-2xl md:text-xl  text-lg font-semibold cursor-pointer whitespace-nowrap">
              {escort?.firstname} {escort?.lastname}
            </div>
          </div>
        </>
      )}

      <MeetingProvider
        config={{
          meetingId,
          micEnabled: false,
          webcamEnabled: false,
          name: "Viewer", // Viewer name
        }}
        token={token}
      >
        <MeetingView />
      </MeetingProvider>

      {joined && (
        <>
          <div className=" p-5 md:p-10 lg:flex  lg:flex-col lg:items-center  ">
            <div className="flex ml-5 lg:w-50p">
              <div
                className="flex items-center cursor-pointer text-white "
                style={{
                  width: "49px",
                }}
              >
                <div className="flex relative w-full mb-10  right-5 ">
                  <div className="absolute  w-16 h-16 rounded-full  border-2 border-purple ">
                    <div className="relative pl-1 pr-1 pt-1     ">
                      <img
                        src={escort?.profilePhoto}
                        alt=""
                        className="h-14 rounded-full w-14 "
                      />
                    </div>

                    <div
                      className="absolute top-12 w-10 left-2 text-sm text-center"
                      style={{
                        background: "rgb(190,66,222)",
                        backgroundImage:
                          "linear-gradient(90deg, rgba(190,66,222,1) 0%, rgba(74,3,141,1) 67%)",
                      }}
                    >
                      Live
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="flex flex-col ">
                <div className="text-white pt-5 lg:text-xl md:text-lg sm:text-md text-xs font-semibold cursor-pointer whitespace-nowrap ">
                  {escort?.firstname} {escort?.lastname}
                </div>
              </div>
            </div>
            <div className="flex  md:flex-row bg-aboutcolor w-full rounded-2xl mt-10 p-4 lg:w-50p ">
              <div className="text-white text-center lg:text-xl md:text-2xl sm:text-lg text-lg pl-2  ">
                <span className="font-bold p-1"> About</span> {escort?.about}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
