import React, { createContext, useContext, useEffect, useState } from "react";
import Layout from "../../screens/Layout";
import { useSelector } from "react-redux";
import { selectToken } from "../../store/token";

// Create the AuthContext
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {

  const authToken = useSelector(selectToken)

  const [isLoggedIn, setisLoggedIn] = useState(true);
  const [Completeform, setisCompletedform] = useState(false);
  const [otp, setOtp] = useState("");
  const [userType, setUserType] = useState("");
  const [takeUsername, setTakeUsername] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [takeAbout, settakeabout] = useState("");
  const [password, setPassword] = useState("");
  const [escortloggedin, setisescortloggedin] = useState(false);
  const [userloggedin, setisuserloggedin] = useState(true);
  const [both, setBoth] = useState(false);
  const [streamer, Setisstreamer] = useState(false);
  const [number, setNumber] = useState('');
  const [height, setAuthHeight] = useState("");
  const [hourlyRate, setAuthHourlyRate] = useState();
  const [age, setAuthAge] = useState();
  const [location, setAuthLocation] = useState('');
  const [description, setAuthDescription] = useState('')
  const [token, setToken] = useState('')
  const[forgotPassEmail , setForgotPassEmail] = useState('')

  useEffect(() => {
    if (authToken) {
      setisLoggedIn(false)
    } else {
      setisLoggedIn(true)
    }
  }, [authToken])

  const saveEmail = () => {
    setUserEmail();
  };

  const saveUser = () => {
    setTakeUsername("");
  };

  const toggleescortloggin = () => {
    setisescortloggedin(true);
    setisuserloggedin(false);
    Setisstreamer(false);
    setBoth(false);
    setUserType("escort");
  };

  const toggleboth = () => {
    setisescortloggedin(false);
    Setisstreamer(false);
    setBoth(true);
    setUserType("both");
  };

  const toggleStreamerLogin = () => {
    Setisstreamer(true);
    setisescortloggedin(false);
    setBoth(false);
    setUserType("streamer");
  };

  const toggleuserloggedin = () => {
    setisuserloggedin(true);
    setisescortloggedin(false);
  };

  const login = () => {
    setisLoggedIn(false);
  };

  const logout = () => {
    setisLoggedIn(true);
  };

  const loginEscort = () => {
    setisCompletedform(true);
  };

  const logoutEscort = () => {
    setisCompletedform(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setisLoggedIn,
        login,
        logout,
        loginEscort,
        logoutEscort,
        Completeform,
        userloggedin,
        escortloggedin,
        toggleuserloggedin,
        toggleescortloggin,
        toggleStreamerLogin,
        streamer,
        both,
        toggleboth,
        saveUser,
        takeUsername,
        UserEmail,
        saveEmail,
        setUserEmail,
        setTakeUsername,
        settakeabout,
        takeAbout,
        setOtp,
        otp,
        userType,
        setPassword,
        password,
        description,
        setAuthDescription,
        setNumber,
        setAuthHeight,
        setAuthHourlyRate,
        setAuthAge,
        setAuthLocation,
        location,
        height,
        hourlyRate,
        age,
        number,
        token,
        setForgotPassEmail,
        setToken,
        forgotPassEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to consume the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
