import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import images from "../../services/images";
import CreateProfile from "../CreateProfile";
import { useModal } from "../../screens/Layout";
import OtpInput from "react-otp-input";
import { useAuth } from "../../services/Authcontext/Authcontext";

export default function OTP({ onCloseModal, setloggedin }) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [otherscreen, setotherscreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("validated");
  const { setmodalcomponent, Modalcomponent } = useModal();
  const { forgotPassEmail } = useAuth()

  const validateForm = () => {
    if (!otp) {
      setError("Please enter the OTP.");
      return false;
    }

    // Validate OTP format
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      setError("Invalid OTP format. Please enter a 6-digit number.");
      return false;
    }
    // setError(error);
    setError("validated");
    return true; // Returns true if there are no errors, otherwise false
  };

  const containerStyle = {
    background: "none",
    // borderRadius: '8px',
    marginLeft: "10px",
    borderBottom: "10px",
    padding: "5px",
    borderBottom: "2px solid rgba(255, 255, 255, 0.5)", // Set 50% opacity white bottom border
    color: "rgba(255, 255, 255, 0.5)",
    outline: "none",
    width: "20%",
    // color:'white',
    opacity: "50",
  };

  const handleSignInClick = () => {
    setmodalcomponent("ResetPassword");
  };
  return (
    <div className="lg:w-50p h-full biggerlaptop:p-4 lg:p-3  md:p-1 sm:p-2 p-1 w-full ">
      <div
        className="bg-cover bg-center h-full flex flex-col p-10 items-start justify-between"
        style={{
          backgroundImage: `url(${images.Bluredimg})`,
        }}
      >
           <div className="w-full flex ">
          <div className="w-50p flex justify-start  flex   ">
            <img
              src={images.arrowwhiteleft}
              alt=""
              className="w-6 h-5 cursor-pointer "
              onClick={() => setmodalcomponent("forgotPass")}
            />
          </div>
          <div className="w-60p  flex justify-end" onClick={onCloseModal}>
            <img src={images.X} alt="" className="cursor-pointer md:h-7 sm:h-6 h-5" />
          </div>
        </div>
        <div className="flex w-full justify-between lg:flex-col md:flex-col md:flex-col sm:flex-col flex-col   ">
          <div className="lg:text-4xl md:text-2xl sm:text-2xl text-2xl text-white font-semibold text-nowrap opacity-90 ">
            Enter OTP
          </div>
          <div className="text-white w-87p pt-2 lg:text-base">
            {`You would've received an OTP on your email (${forgotPassEmail})`}
          </div>
        </div>
        <OtpInput
          value={otp}
          inputType={false}
          onChange={setOtp}
          numInputs={6}
          inputStyle={containerStyle}
          renderSeparator={<span className=""></span>}
          renderInput={(props) => <input {...props} className="" />}
          containerStyle="w-full "
        />
        <div className="text-white flex">
          <div className="opacity-60">Didn’t receive the OTP?</div>
          <div className="font-bold pl-1 cursor-pointer active:opacity-50 "
          onClick={() =>  setmodalcomponent("forgotPass")}
          >
            Try again
          </div>
        </div>
        <div
          className={
            error === "validated"
              ? "error cursor-pointer opacity-0"
              : "error cursor-pointer"
          }
        >
          *{error}
        </div>
        <div
          className="bg-purple font-semibold text-lg text-white text-center py-4 w-full cursor-pointer mb-8 mt-2  active:"
          onClick={() => {
            if (validateForm()) {
              handleSignInClick();
            }
          }}
        >
          Continue
        </div>
      </div>
    </div>
  );
}
