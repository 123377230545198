import React, { useRef, useState, useEffect } from "react";
import images from "../../services/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faLeaf } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";

export default function Escortmodal({ toggleModal, excortData }) {
  const textToCopyRef = useRef(null);
  const modalRef = useRef();

  const [Xchange, setXchange] = useState(true);

  const checkingmodal = () => {
    toggleModal();
  };

  const closepurple = () => {
    setXchange(!Xchange);
    toggleModal();
  };

  // const [isAgreed, setIsAgreed] = useState(false);
  // const [isAgreed2, setIsAgreed2] = useState(true);
  // const [changepress, setchangepress] = useState(false);

  // const togglechangepress = (value) => {
  //   setchangepress(!changepress);
  //   setIsAgreed((prevIsAgreed) => !prevIsAgreed);
  // };

  const [togglePayment, setTogglePayment] = useState(true);
  const [selectedImage1, setselectedImage1] = useState(1);
  const [selectedImage2, setselectedImage2] = useState(null);
  const [selectedImage3, setselectedImage3] = useState(null);
  const [changepanel, setchangepanel] = useState(true);

  const togglechangepanel = () => {
    setchangepanel(!changepanel);
  };

  const handleCopyClick = () => {
    // Select the text inside the div
    const range = document.createRange();
    range.selectNode(textToCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Deselect the text
    window.getSelection().removeAllRanges();
  };

  const paymentDataArray = [
    { bitTotal1: 100, bitAmount1: 0.0011, USD: "USD", BTC: "BTC" },
    { bit2Total: 200, bit2Amount: 0.0012, USD: "USD", BTC: "BTC" },
    { bit3Total: 300, bit3Amount: 0.0013, USD: "USD", BTC: "BTC" },
  ];

  const handletoggleImage1 = (index) => {
    setselectedImage1(index);
    setselectedImage2(null);
    setselectedImage3(null);
  };

  const handletoggleImage2 = (index) => {
    setselectedImage1(null);
    setselectedImage2(index);
    setselectedImage3(null);
  };

  const handletoggleImage3 = (index) => {
    setselectedImage1(null);
    setselectedImage2(null);
    setselectedImage3(index);
  };

  return (
    <div
      className="fixed  top-0 left-0 w-full  h-full flex items-center mt-10 justify-center  bg-gray-900 bg-opacity-70"
      onClick={checkingmodal}
    >
      <div
        className="bg-cover bg-no-repeat 1470:w-75p lg:pb-10 lg:pt-10 lg:pl-10 lg:pr-10 pt-2 pb-2 pl-2 pr-2 w-90p"
        style={{
          backgroundImage: `url(${images.escortmodal})`,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex lg:flex-row h-full ">
          {/* smaller Version */}

          <div className="lg:hidden flex w-full">
            {changepanel ? (
              <div className="bg-purplegray w-full ">
                <div className="lg:p-10 p-4">
                  <div className="w-full flex ">
                    <div className="" onClick={closepurple}>
                      <img
                        src={images.X}
                        alt=""
                        className="md:h-7 sm:h-6 h-5"
                      />
                    </div>
                    <div className="md:text-3xl sm:text-2xl text-sm text-white font-semibold pl-4  text-nowrap flex w-full ">
                      <div className="flex w-full justify-center ">
                        Booking Details
                      </div>
                      <div className="lg:hidden pr-3  md:flex flex justify-end items-center w-20p ">
                        <img
                          src={images.arrowwhiteright}
                          alt=""
                          className="cursor-pointer active:opacity-50 ml-5 md:h-7 sm:h-6 h-5"
                          onClick={togglechangepanel}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-col pt-10 lg:text-base md:text-base sm:text-sm text-xs pl-4 pr-4 pt-4 ">
                    <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col lg:w-full   md:w-full sm:w-full w-full space-y-2  text-white">
                      <div className="border-b">
                        <input
                          type="text"
                          placeholder="First name"
                          className="bg-transparent focus:outline-none text-white  pb-2 lg:pl-3 placeholder-gray-500"
                        />
                      </div>
                      <div className="border-b lg:ml-7 md:ml-7 sm:ml-7  lg:w-full  md:w-full sm:w-full pt-2 lg:pt-0 md:pt-0 sm:pt-0">
                        <input
                          type="text"
                          placeholder="Last name"
                          className="bg-transparent focus:outline-none  pb-2 border-none lg:pl-3 placeholder-gray-500"
                        />
                      </div>
                    </div>
                    <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col lg:w-full  md:w-full sm:w-full w-full space-y-2 pt-5 lg:pt-0 md:pt-0 sm:pt-0 text-white lg:pt-14  md:pt-14 sm:pt-14">
                      <div className="border-b ">
                        <input
                          type="Number"
                          placeholder="Contact Number"
                          className="bg-transparent focus:outline-none remove-arrow  pb-2 lg:pl-3 placeholder-gray-500"
                        />
                      </div>
                      <div className="border-b lg:ml-7 md:ml-7 sm:ml-7  lg:w-full  md:w-full sm:w-full pt-2 lg:pt-0 md:pt-0 sm:pt-0 ">
                        <input
                          type="text"
                          placeholder="Email"
                          className="bg-transparent focus:outline-none pb-2 border-none lg:pl-3 placeholder-gray-500"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="border-b pt-5 lg:pt-14 md:pt-14 sm:pt-14">
                        <input
                          type="text"
                          placeholder="Address"
                          className="bg-transparent focus:outline-none border-none lg:pl-3 text-white pb-2 placeholder-gray-500"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between items-center  pt-10 ">
                      <div className="text-white  text- text-nowrap lg:pr-0 pr-5  sm:text-lg md:text-lg lg:text-lg  font-semibold ">
                        Escort Details
                      </div>
                      <div className="flex items-center rounded-full border text-nowrap pl-1 pr-1 md:w-32 md:max-w-32 sm:text-sm md:text-base pb-2 pt-1 bg-darkerpurple justify-center 1470:w-32 1470:max-w-32 lg:w-28">
                        <Link to={"/Escorts/All"}>
                          <div className="flex items-center text-white border-b 1470:text-base lg:text-xs md:text-xs sm:text-xxs text-xxs pl-1 pr-1 cursor-pointer active:opacity-50">
                            Change Escort
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="flex-col lg:pt-14">
                      <div className="flex justify-between pt-4 md:w-68p sm:w-full w-full ">
                        <div className="flex-col flex ">
                          <div className="text-whitepink opacity-40 md:text-base sm:text-sm text-xs  ">
                            Name
                          </div>
                          <div className="text-white md:text-xl sm:text-lg text-sm ">
                            Florence J. Smith
                          </div>
                        </div>
                        <div className="">
                          <div className="text-whitepink opacity-40 md:text-base sm:text-sm text-xs ">
                            Age
                          </div>
                          <div className="text-white md:text-xl sm:text-lg text-sm text-end">
                            22
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between pt-4 md:w-70p  sm:w-full w-full ">
                        <div className="flex-col flex ">
                          <div className="text-whitepink opacity-40 md:text-base sm:text-sm text-xs">
                            Phone No
                          </div>
                          <div className="text-white md:text-xl sm:text-lg text-sm ">
                            +165489652
                          </div>
                        </div>
                        <div>
                          <div className="text-whitepink opacity-40 md:text-base sm:text-sm text-xs  ">
                            Height
                          </div>
                          <div className="text-white md:text-xl sm:text-lg text-sm  text-end">
                            5.2ft
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full justify-between pb-10">
                        <div className="flex-col flex pt-2">
                          <div className="text-whitepink opacity-40 md:text-base sm:text-sm text-xs">
                            Loctaion
                          </div>
                          <div className="text-white md:text-xl sm:text-lg text-sm ">
                            London
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" pt-5 border-t flex  justify-between">
                      <div className="text-white opacity-30 md:text-xl sm:text-lg text-sm">
                        Amount:
                      </div>
                      <div className="text-white md:text-xl sm:text-lg text-sm font-semibold">
                        100.USD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className=" bg-no-repeat lg:h-85vh  lg:w-50p md:w-full w-full"
                style={{
                  backgroundImage: `url(${images.bluredimg2})`,
                }}
              >
                <div className="">
                  <div className="p-3 lg:p-14 md:p-14 sm:p-14 flex flex-col w-full">
                    <div className="flex justify-between  items-center ">
                      <img
                        src={images.arrowwhiteleft}
                        alt=""
                        className="cursor-pointer active:opacity-50  md:h-7 sm:h-6 h-5"
                        onClick={togglechangepanel}
                      />
                      <div className="text-white font-semibold lg:text-3xl md:text-3xl sm:text-3xl text-lg ">
                        Payment Details
                      </div>
                      <div className="" onClick={closepurple}>
                        <img
                          src={images.X}
                          alt=""
                          className="md:h-7 sm:h-6 h-5"
                        />
                      </div>
                    </div>
                    <div className="flex md:flex-row sm:flex-row flex-row  items-center  lg:justify-start justify-between mt-10">
                      <div className="flex items-center">
                        <div
                          className="flex"
                          onClick={() => {
                            setTogglePayment(true);
                          }}
                        >
                          {togglePayment ? (
                            <div className="border-1  rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4  flex items-center justify-center bg-purple">
                              <div className="  ">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="1x"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    height: "10px",
                                    width: "10px",
                                    cursor: "pointer",
                                    color: "transparent",
                                    border: "2px solid #4c2f4d ",
                                    "@media(maxWidth: 444px)": {
                                      height: "7px",
                                      width: "7px",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center">
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  "@media(maxWidth: 444px)": {
                                    height: "7px",
                                    width: "7px",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="text-white lg:text-xl md:text-xl sm:text-sm text-xs text-nowrap  pl-3 font-semibold">
                          Credit Card
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div
                          className="flex  "
                          onClick={() => {
                            setTogglePayment(false);
                          }}
                        >
                          {!togglePayment ? (
                            <div className=" border-1  rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center bg-purple">
                              <div className="">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="1x"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    height: "10px",
                                    width: "10px",
                                    cursor: "pointer",
                                    color: "transparent",
                                    border: "2px solid #4c2f4d ",
                                    "@media(maxWidth: 444px)": {
                                      height: "7px",
                                      width: "7px",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="border-2 rounded-full lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-5 sm:h-5 w-4 h-4 flex items-center  justify-center">
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  "@media(maxWidth: 444px)": {
                                    height: "5px",
                                    width: "5px",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className=" ml-2 text-white lg:text-xl md:text-xl sm:text-sm text-xs text-nowrap">
                          Crypto
                        </div>
                      </div>
                    </div>
                    {togglePayment && (
                      <div>
                        <div className="mt-10 justify-center flex flex-col items-center space-y-10 text-purple font-semibold">
                          <div className="flex justify-start w-full lg:h-13 h-10">
                            <input
                              type="text"
                              placeholder="Card Number"
                              className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2"
                            />
                          </div>
                          <div className="flex justify-start w-full lg:h-13 h-10">
                            <input
                              type="text"
                              placeholder="Name on Card"
                              className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2"
                            />
                          </div>
                          <div className="w-full flex ">
                            <div className="flex justify-start w-full lg:h-13 h-10">
                              <input
                                type="text"
                                placeholder="Expiry Date"
                                className="placeholder-purple placeholder-opacity-40 w-90p h-full md:text-sm sm:text-sm  text-xxs focus:outline-none md:pl-5 sm:pl-2 pl-2 "
                              />
                            </div>
                            <div className="flex  justify-start w-full lg:h-13 h-10">
                              <input
                                type="text"
                                placeholder="CVC"
                                className="placeholder-purple placeholder-opacity-40 w-full h-full md:text-sm sm:text-sm  text-xxs  focus:outline-none md:pl-5 sm:pl-2 pl-2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-10 h-20 w-full flex justify-center items-center gap-2">
                          <img
                            src={images.VISA21}
                            alt=""
                            className="md:h-10 sm:h-8 h-7"
                          />
                          <img
                            src={images.AMEX21}
                            alt=""
                            className="md:h-10 sm:h-8 h-7"
                          />
                          <img
                            src={images.AMEX20}
                            alt=""
                            className="md:h-10 sm:h-8 h-7"
                          />
                        </div>
                        <div
                          className="bg-purple w-full mt-10 flex h-20 items-center justify-center cursor-pointer active:opacity-50"
                          onClick={checkingmodal}
                        >
                          <div className="text-white md:text-2xl sm:text-2xl text-sm font-semibold">
                            CONFIRM BOOKING & PAY
                          </div>
                        </div>
                      </div>
                    )}
                    {!togglePayment && (
                      <div className="pt-10">
                        <div className="text-white font-thin opacity-80 lg:text-base md:text-base sm:text-sm text-xs">
                          Select a cryptocurrency
                        </div>
                        <div className="w-full h-10 flex items-center pt-10 text-purple text-base ">
                          <div className="bg-white lg:h-14 md:h-14 sm:h-10 h-10 flex items-center pl-5 ">
                            <img
                              src={images.search2}
                              alt=""
                              className=" cursor-pointer "
                            />
                          </div>
                          <input
                            type="text"
                            placeholder="Find a Currency"
                            className="placeholder-purple placeholder-opacity-40 lg:text-base md:text-sm sm:text-xs text-xs w-full lg:h-14 md:h-14 sm:h-10 h-10 pl-5 focus:outline-none  "
                          />
                          <div className="flex bg-white lg:h-14 md:h-14 sm:h-10 h-10 items-center w-full justify-end pr-4">
                            <img
                              src={images.bitcoin1}
                              alt=""
                              className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                                selectedImage1 === 0 ? "active:opacity-50" : ""
                              }`}
                              onClick={() => {
                                handletoggleImage1(0);
                              }}
                            />

                            <img
                              src={images.bitcoin2}
                              alt=""
                              className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                                selectedImage2 === 1 ? "active:opacity-50" : ""
                              }`}
                              onClick={() => {
                                handletoggleImage2(1);
                              }}
                            />
                            <img
                              src={images.bitcoin3}
                              alt=""
                              className={`lg:w-7 lg:h-7 md:w-7 md:h-7 sm:w-5 sm:h-5 w-5 h-5 cursor-pointer ${
                                selectedImage3 === 2 ? "active:opacity-50" : ""
                              }`}
                              onClick={() => {
                                handletoggleImage3(2);
                              }}
                            />
                          </div>
                        </div>
                        <div className="">
                          {selectedImage1 !== null && (
                            <div className="flex text-white justify-between pt-20">
                              <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                                Total
                              </div>
                              <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                                {paymentDataArray[selectedImage1].bitTotal1}.
                                {paymentDataArray[selectedImage1].USD}
                              </div>
                            </div>
                          )}

                          {selectedImage2 !== null && (
                            <div className="flex text-white justify-between pt-20">
                              <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                                Total
                              </div>
                              <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                                {paymentDataArray[selectedImage2].bit2Total}.
                                {paymentDataArray[selectedImage2].USD}
                              </div>
                            </div>
                          )}

                          {selectedImage3 !== null && (
                            <div className="flex text-white justify-between pt-20">
                              <div className="opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                                Total
                              </div>
                              <div className="font-semibold lg:text-lg md:text-base sm:text-sm text-xs">
                                {paymentDataArray[selectedImage3].bit3Total}.
                                {paymentDataArray[selectedImage3].USD}
                              </div>
                            </div>
                          )}

                          <div className="flex text-white justify-between border-b pb-6  ">
                            <div className=" opacity-80 lg:text-lg md:text-base sm:text-sm text-xs">
                              Amount
                            </div>
                            {selectedImage1 !== null && (
                              <div className="flex lg:text-lg md:text-base sm:text-sm text-xs font-semibold ">
                                <div className="mr-1">
                                  {paymentDataArray[selectedImage1].bitAmount1}
                                </div>
                                {paymentDataArray[selectedImage1].BTC}
                              </div>
                            )}
                            {selectedImage2 !== null && (
                              <div className="flex lg:text-lg md:text-base sm:text-sm text-xs font-semibold ">
                                <div className="mr-1">
                                  {paymentDataArray[selectedImage2].bit2Amount}
                                </div>
                                {paymentDataArray[selectedImage2].BTC}
                              </div>
                            )}
                            {selectedImage3 !== null && (
                              <div className=" flex lg:text-lg md:text-base sm:text-sm text-xs font-semibold ">
                                <div className="mr-1">
                                  {paymentDataArray[selectedImage3].bit3Amount}
                                </div>
                                {paymentDataArray[selectedImage3].BTC}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pt-2 flex flex-col ">
                          <div className="text-white opacity-80 pb-2 lg:text-base md:text-sm sm:text-xs text-xs">
                            Please send to address:
                          </div>
                          <div className="flex lg:w-full md:w-full sm:w-full w-90p items-center">
                            <div className="border border-white border-dashed w-full  ">
                              <div className="bg-whitepink h-8 flex items-center justify-center">
                                <div
                                  className="text-purple truncate lg:text-base md:text-sm sm:text-xs text-xs "
                                  ref={textToCopyRef}
                                >
                                  1sdafuhiufg56d85hsjhbveds125sdlkjhgvjfddsg
                                </div>
                              </div>
                            </div>
                            <div className="lg:ml-5 md:ml-5 sm:ml-5 ml-1 cursor-pointer active:opacity-50 lg:w-8 lg:max-w-8 md:w-8 md:max-w-8 sm:w-8 sm:max-w-8 w-6 max-w-6 ">
                              <img
                                src={images.copy}
                                alt=""
                                className="lg:w-8 lg:max-w-8 md:w-8 md:max-w-8 sm:w-8 sm:max-w-8 w-5 max-w-5"
                                onClick={handleCopyClick}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-10">
                          <div
                            className="bg-purple w-full  flex h-20 items-center justify-center cursor-pointer active:opacity-50"
                            onClick={checkingmodal}
                          >
                            <div className="text-white lg:text-lg md:text-2xl sm:text-base text-base font-semibold">
                              OPEN IN WALLET
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Larger Version */}
          <div className="lg:flex   hidden w-full ">
            <div className=" bg-purplegray lg:w-full md:w-50   ">
              <div className="p-20">
                <div className="text-whitepink  text-4xl font-semibold">
                  Booking Details
                </div>
                <div className="flex pt-14 w-full">
                  <input
                    type="text"
                    placeholder="First name"
                    className="bg-transparent w-50p pl-2 pb-2 focus:outline-none  placeholder-white  placeholder-opacity-50 text-white border-opaicty-50 border-b"
                  />
                  <input
                    type="text"
                    placeholder="Last name"
                    className="bg-transparent ml-6 pl-2 w-50p pb-2 placeholder-white  placeholder-opacity-50   focus:outline-none text-white  border-b"
                  />
                </div>
                <div className="flex pt-14 w-full">
                  <input
                    type="Number"
                    placeholder="Contact Number"
                    className="bg-transparent remove-arrow placeholder-white  pb-2 w-50p pl-2  placeholder-opacity-50 focus:outline-none text-white border-opaicty-50 border-b"
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    className="bg-transparent ml-6 pl-3 pb-2 w-50p placeholder-white  placeholder-opacity-50 focus:outline-none text-white  border-b"
                  />
                </div>
                <div className="flex-col pt-14">
                  <input
                    type="text"
                    placeholder="Address"
                    className="bg-transparent  pl-2 pb-2 w-99p placeholder-white  placeholder-opacity-50   focus:outline-none text-white  border-b"
                  />
                  <div className="flex justify-between items-center  pt-10 ">
                    <div className="text-white  lg:text-2xl font-semibold 1470:text-3xl">
                      Escort Details
                    </div>
                    <div className=" flex items-center rounded-full border  pb-2 pt-1 bg-darkerpurple justify-center 1470:w-36 1470:max-w-36 lg:w-24">
                      <Link to={"/Escorts/All"}>
                        <div className="flex items-center text-white border-b 1470:text-base lg:text-xs cursor-pointer active:opacity-50">
                          Change Escort
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="flex justify-between pt-4 1470:w-67p lg:w-90p">
                    <div className="flex-col flex ">
                      <div className="text-gray-500 lg:text-lg 1470:text-xl ">
                        Name
                      </div>
                      <div className="text-white 1470:text-2xl lg:text-xl ">
                        {excortData?.firstname}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray-500 text-xl ">Age</div>
                      <div className="text-white 1470:text-2xl lg:text-xl">
                        {excortData?.listingDetails?.age}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-4 1470:w-70p lg:w-95p">
                    <div className="flex-col flex ">
                      <div className="text-gray-500 text-xl">Phone No</div>
                      <div className="text-white 1470:text-2xl lg:text-xl ">
                        + {excortData?.listingDetails?.number}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray-500 text-xl ">Height</div>
                      <div className="text-white 1470:text-2xl lg:text-xl ">
                      {excortData?.listingDetails?.height}ft
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-between pb-10">
                    <div className="flex-col flex pt-2">
                      <div className="text-gray-500 text-xl">Loctaion</div>
                      <div className="text-white 1470:text-2xl lg:text-xl">
                      {excortData?.listingDetails?.location}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" pt-5 border-t flex  justify-between">
                  <div className="text-white opacity-30 text-xl">Amount:</div>
                  <div className="text-white 1470:text-2xl lg:text-xl font-semibold">
                  {excortData?.listingDetails?.hourlyRate}.USD
                  </div>
                </div>
              </div>
            </div>

            <div
              className=" bg-no-repeat lg:h-90  lg:w-full md:w-full lg:flex   "
              style={{
                backgroundImage: `url(${images.bluredimg2})`,
              }}
            >
              <div className="w-full">
                <div className="pt-20 pl-10 pr-10 flex flex-col  ">
                  <div className="flex justify-between  items-center ">
                    <img
                      src={images.arrowwhiteleft}
                      alt=""
                      className="lg:hidden md:flex flex "
                      onClick={togglechangepanel}
                    />
                    <div className="text-white font-semibold text-4xl  ">
                      Payment Details
                    </div>
                    <div className="">
                      <div>
                        <img
                          src={Xchange ? images.X : images.purplex}
                          alt=""
                          className="w-full lg:h-6 h-4 cursor-pointer"
                          onClick={closepurple}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-start mt-10">
                    <div className="flex items-center">
                      <div
                        className="flex"
                        onClick={() => {
                          setTogglePayment(true);
                        }}
                      >
                        {togglePayment ? (
                          <div className="border-1  rounded-full w-5 h-5 flex items-center justify-center bg-purple">
                            <div className="  ">
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid #4c2f4d ",
                                }}
                              />{" "}
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 rounded-full w-5 h-5 flex items-center  justify-center">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "50%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-white text-xl pl-3 font-semibold">
                        Credit Card
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div
                        className="flex pl-20 items-center"
                        onClick={() => {
                          setTogglePayment(false);
                        }}
                      >
                        {!togglePayment ? (
                          <div className=" border-1  rounded-full w-5 h-5 flex items-center  justify-center bg-purple">
                            <div className="">
                              {" "}
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="1x"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  height: "10px",
                                  width: "10px",
                                  cursor: "pointer",
                                  color: "transparent",
                                  border: "2px solid #4c2f4d ",
                                }}
                              />{" "}
                            </div>
                          </div>
                        ) : (
                          <div className="border-2 rounded-full w-5 h-5 flex items-center  justify-center">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="1x"
                              style={{
                                borderRadius: "50%",
                                height: "10px",
                                width: "10px",
                                cursor: "pointer",
                                color: "transparent",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-white text-xl pl-3 font-semibold">
                        Crypto
                      </div>
                    </div>
                  </div>
                  {togglePayment && (
                    <div>
                      <div className="mt-10 justify-center flex flex-col items-center space-y-10 text-purple font-semibold">
                        <div className="flex  justify-start w-full h-13">
                          <input
                            type="text"
                            placeholder="Card Number"
                            className="placeholder-purple bg-whitepink placeholder-opacity-40 w-full h-full  text-sm focus:outline-none pl-5"
                          />
                        </div>
                        <div className="flex justify-start w-full h-13">
                          <input
                            type="text"
                            placeholder="Name on Card"
                            className="placeholder-purple bg-whitepink placeholder-opacity-40 w-full h-full text-sm focus:outline-none pl-5"
                          />
                        </div>
                        <div className="w-full flex ">
                          <div className="flex justify-start w-full h-13">
                            <input
                              type="text"
                              placeholder="Expiry Date"
                              className="placeholder-purple bg-whitepink placeholder-opacity-40 w-90p h-full text-sm focus:outline-none pl-5 "
                            />
                          </div>
                          <div className="flex  justify-start w-full h-13 text-">
                            <input
                              type="text"
                              placeholder="CVC"
                              className="placeholder-purple bg-whitepink placeholder-opacity-40 w-full h-full text-xs  focus:outline-none pl-5"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-10 h-20 w-full flex justify-center items-center gap-2">
                        <img src={images.VISA21} alt="" className="h-10" />
                        <img src={images.AMEX21} alt="" className="h-10" />
                        <img src={images.AMEX20} alt="" className="h-10" />
                      </div>
                      <div className="mt-10">
                        <div
                          className="bg-purple w-full  flex h-20 items-center justify-center cursor-pointer active:opacity-50"
                          onClick={checkingmodal}
                        >
                          <div className="text-white text-2xl font-semibold">
                            CONFIRM BOOKING & PAY
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!togglePayment && (
                    <div className="pt-10">
                      <div className="text-white  font-thin opacity-80">
                        Select a cryptocurrency
                      </div>
                      <div className="w-full h-10 flex items-center pt-10 text-purple text-base">
                        <div className="bg-white h-14 flex items-center pl-5 ">
                          <img
                            src={images.search2}
                            alt=""
                            className=" cursor-pointer "
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Find a Currency"
                          className="placeholder-purple placeholder-opacity-40 w-full h-14 pl-5 focus:outline-none  "
                        />
                        <div className="flex bg-white  h-14 items-center w-full justify-end pr-4">
                          <img
                            src={images.bitcoin1}
                            alt=""
                            className={`w-7 h-7 cursor-pointer ${
                              selectedImage1 === 0 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage1(0);
                            }}
                          />

                          <img
                            src={images.bitcoin2}
                            alt=""
                            className={`w-7 h-7 cursor-pointer ${
                              selectedImage2 === 1 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage2(1);
                            }}
                          />
                          <img
                            src={images.bitcoin3}
                            alt=""
                            className={`w-7 h-7 cursor-pointer ${
                              selectedImage3 === 2 ? "active:opacity-50" : ""
                            }`}
                            onClick={() => {
                              handletoggleImage3(2);
                            }}
                          />
                        </div>
                      </div>
                      <div className="space-y-2">
                        {selectedImage1 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 text-lg">Total</div>
                            <div className="font-semibold text-lg">
                              {paymentDataArray[selectedImage1].bitTotal1}.
                              {paymentDataArray[selectedImage1].USD}
                            </div>
                          </div>
                        )}

                        {selectedImage2 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 text-lg">Total</div>
                            <div className="font-semibold text-lg">
                              {paymentDataArray[selectedImage2].bit2Total}.
                              {paymentDataArray[selectedImage2].USD}
                            </div>
                          </div>
                        )}

                        {selectedImage3 !== null && (
                          <div className="flex text-white justify-between pt-20">
                            <div className="opacity-80 text-lg">Total</div>
                            <div className="font-semibold text-lg">
                              {paymentDataArray[selectedImage3].bit3Total}.
                              {paymentDataArray[selectedImage3].USD}
                            </div>
                          </div>
                        )}

                        <div className="flex text-white justify-between border-b pb-6  ">
                          <div className=" opacity-80 text-lg">Amount</div>
                          {selectedImage1 !== null && (
                            <div className="flex text-lg font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage1].bitAmount1}
                              </div>
                              {paymentDataArray[selectedImage1].BTC}
                            </div>
                          )}
                          {selectedImage2 !== null && (
                            <div className="flex text-lg font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage2].bit2Amount}
                              </div>
                              {paymentDataArray[selectedImage2].BTC}
                            </div>
                          )}
                          {selectedImage3 !== null && (
                            <div className=" flex text-lg font-semibold ">
                              <div className="mr-1">
                                {paymentDataArray[selectedImage3].bit3Amount}
                              </div>
                              {paymentDataArray[selectedImage3].BTC}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pt-2  flex flex-col">
                        <div className="text-white opacity-80 pb-2">
                          Please send to address:
                        </div>
                        <div className="flex w-full items-center">
                          <div className="border border-white border-dashed w-full  ">
                            <div className="bg-whitepink h-8 flex items-center justify-center">
                              <div className="text-purple " ref={textToCopyRef}>
                                1sdafuhiufg56d85hsjhbveds125sdlkjhgvjfddsg
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 cursor-pointer active:opacity-50">
                            <img
                              src={images.copy}
                              alt=""
                              className=""
                              onClick={handleCopyClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-20">
                        <div
                          className="bg-purple w-full  flex h-20 items-center justify-center cursor-pointer active:opacity-50"
                          onClick={checkingmodal}
                        >
                          <div className="text-white text-2xl font-semibold">
                            OPEN IN WALLET
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
