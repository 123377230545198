import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        userData: null
    },
    reducers: {
        setUsereData: (state, action) => {
            state.userData = action.payload;
        },
        removeUserData: (state) => {
            state.userData = null;
        },
        updateUserVerified: (state, action) => {
            if (state.userData) {
                state.userData.verify = action.payload
            }
        }
    }

})

export const { setUsereData, removeUserData, updateUserVerified } = userDataSlice.actions;
export const selectUserData = (state) => state.user.userData;
export default userDataSlice.reducer;