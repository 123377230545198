import { createSlice } from "@reduxjs/toolkit";

const logedInSlice = createSlice({
    name: 'logedIn',
    initialState: {
        userLogedIn: true
    },
    reducers: {
        setUserLogedIn: (state) => {
            state.userLogedIn = true;
        },
        removeUserLogedIn: (state) => {
            state.userLogedIn = false;
        }
    }

})

export const { setUserLogedIn, removeUserLogedIn } = logedInSlice.actions;
export const selectLogedIn = (state) => state.logedIn.userLogedIn;
export default logedInSlice.reducer;