import React, { useEffect, useState } from "react";
import images from "../../services/images.js";
import EscortAll from "../../components/EscortAll/index.jsx";
import EscortAvailable from "../../components/Escortavailable/index.jsx";
import Escortavailablenow from "../../components/Escortavailablenow/index.jsx";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../services/Authcontext/Authcontext.jsx";
import { getAds, getEscorts } from "../../services/API/index.js";
import moment from "moment-timezone";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import PhoneInput from "react-phone-input-2";
import { ClipLoader } from "react-spinners";

export default function Escort() {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;
  const [tabName, setTabName] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownImage, setDropdownImage] = useState(images.arrowdown);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams] = useState(["firstname", "lastname"]);
  const [extraFilters, setExtraFilters] = useState(false);
  const [showMore, setShowmore] = useState(false);
  const [availabilityAll, setAvailabilityAll] = useState("");
  const [avaialbleInCall, setAvailableInCall] = useState(false);
  const [availableOutCall, setAvailableOutCall] = useState(false);
  const [availableOnline, setAvailableOnline] = useState(false);
  const [lylaReviews, setLylaReviews] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [officialPhotoshoot, setOfficialPhotoshoot] = useState(false);
  const [hasPictures, setHasPictures] = useState(false);
  const [availableNow, setAvailableNow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = useState([18, 30]); // Default range from 18 to 30
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedEth, setSelectedEth] = useState([]);
  const [adDataCity, setAdDataCity] = useState([]);
  const [adDataEnth, setAdDataEnth] = useState([]);
  const [adDataCategories, setAdDataCategories] = useState([]);
  const [ageFilterUsed, setAgeFilterUsed] = useState(false); // New state to track if the age filter has been used
  const [isLoading, setIsLoading] = useState(false);
  const [checkIsVerified, setCheckIsVerified] = useState(false);

  useEffect(() => {
    handleScrollToTop();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getEscortsData = async () => {
    try {
      const data = await getEscorts();
      setEscortimages(data.escorts);
      return data;
    } catch (error) {
      console.error("Error fetching escort data:", error);
      // Handle error (e.g., show error message to user)
      // Optionally, you can rethrow the error to let the caller handle it
      throw error;
    }
  };

  const getAdsData = async () => {
    try {
      const data = await getAds();
      // console.log("data-=>", data);
      let addData = data?.advertisements?.slice().reverse();
      console.log(addData);
      
      const addCity = addData.map((ad) => ad?.city);
      setAdDataCity(addCity);
      const addEth = addData.map((ad) => ad?.ethnicity);
      setAdDataEnth(addEth);
      const addCat = addData.map((ad) => ad?.cateogires);
      setAdDataCategories(addCat);
      // console.log("ads data-=>", addData);

      if (searchQuery) {
        addData = searchData(addData);
      }

      if (isFilterActive()) {
        addData = filterAdData(addData);
      }

      const currentDateTime = moment(); // Use local time

      const parseDateTime = (date, time) => {
        if (!date || !time) {
          // console.error("Invalid date or time provided:", { date, time });
          return null;
        }
        const dateTimeString = `${date} ${time}`;
        try {
          return moment(dateTimeString, "MM/DD/YYYY h:mm A");
        } catch (error) {
          // console.error("Error parsing date with moment:", error);
          return null;
        }
      };

      const isBumpActive = (bumpTimes) => {
        return bumpTimes.some((time) => {
          const startTime = parseDateTime(
            currentDateTime.format("MM/DD/YYYY"),
            time
          );
          if (!startTime) {
            // console.error("Invalid start time:", time);
            return false;
          }
          const endTime = moment(startTime).add(30, "minutes");

          // Debugging logs to verify the times
          // console.log("Start Time:", startTime.format("MM/DD/YYYY h:mm A"));
          // console.log("End Time:", endTime.format("MM/DD/YYYY h:mm A"));
          // console.log(
          //   "Current Time:",
          //   currentDateTime.format("MM/DD/YYYY h:mm A")
          // );

          return currentDateTime.isBetween(startTime, endTime, undefined, "[]");
        });
      };

      const sortedAds = addData.sort((a, b) => {
        const aBumpActive =
          a.upgrades.bumps && isBumpActive(a?.upgrades?.bumps?.times);
        const bBumpActive =
          b.upgrades.bumps && isBumpActive(b?.upgrades?.bumps?.times);

        const aPageOne = a.upgrades.pageOne;
        const bPageOne = b.upgrades.pageOne;

        const aPageOneActive =
          aPageOne &&
          parseDateTime(aPageOne.startDate, aPageOne.startTime) <=
            currentDateTime &&
          parseDateTime(aPageOne.endDate, aPageOne.endTime) >= currentDateTime;
        const bPageOneActive =
          bPageOne &&
          parseDateTime(bPageOne.startDate, bPageOne.startTime) <=
            currentDateTime &&
          parseDateTime(bPageOne.endDate, bPageOne.endTime) >= currentDateTime;

        if (aBumpActive && !bBumpActive) return -1;
        if (!aBumpActive && bBumpActive) return 1;

        if (aPageOneActive && !bPageOneActive) return -1;
        if (!aPageOneActive && bPageOneActive) return 1;

        return new Date(b.createdAt) - new Date(a.createdAt); // Sort by most recent if all else is equal
      });

      setAdddata(sortedAds);
      // console.log("Sorted Ads Data:", sortedAds);

      setAdddata(sortedAds);
      // console.log("Sorted Ads Data:", sortedAds);

      return sortedAds;
    } catch (error) {
      // console.error("Error fetching ads data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // await getEscortsData();
        const adsData = await getAdsData();
        setAdddata(adsData); // Assuming this sets the data for ads
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchDataWithSearch(); // Assuming this is another data fetching function that needs to be called
  }, [
    searchQuery,
    availabilityAll,
    avaialbleInCall,
    availableOutCall,
    availableOnline,
    lylaReviews,
    hasVideo,
    officialPhotoshoot,
    hasPictures,
    availableNow,
    age,
    phoneNumber,
    selectedCity,
    checkIsVerified,
    selectedEth,
    selectedCategories,
  ]); // List of dependencies triggering the effect

  useEffect(() => {
    if (availabilityAll) {
      setAvailableInCall(false);
      setAvailableOutCall(false);
      setAvailableOnline(false);
    }
  }, [availabilityAll]);

  const fetchDataWithSearch = async () => {
    if (!isAnyFilterActive()) {
      setIsLoading(true); // Start loading
      try {
        const searchData = await getEscorts(searchQuery);
        setEscortimages(searchData.escorts);
      } catch (error) {
        // console.error("Error fetching data with search:", error);
        setEscortimages(null); // Optionally clear on error
      } finally {
        setIsLoading(false); // End loading regardless of success or failure
      }
    } else {
      setEscortimages(null);
    }
  };

  const handleTabName = (tab) => {
    setTabName(tab);
    navigate(`/Escorts/${tab}`);
    setSearchQuery(""); // Reset s
    setIsDropdownOpen(!isDropdownOpen);
  };

  const adSearchParams = ["ad_title", "name"];

  const TabnameHandler = (tab) => {
    setTabName(tab);
    navigate(`/Escorts/${tab}`);
    // getAdsData()
  };

  const [escortimages, setEscortimages] = useState(
    // getEscortsData()
    [
      {
        images: images.escortdetailimg,
        name: "Florence J. Smith",
        location: "LONDON",
        age: 27,
        rating: 4.5,
        service: "Lorem Ipsum, Lorem Ipsum",
        Search: "Bandi1",
        price: 20,
        Phone: +165489652,
        location1: "London",
        region: "Durham Region",
        race: "White/Caucasian",
        Height: "5.2ft",
        Hourly: "$20",
        Email: "florencej.smith@gmail.com",
        _id: 154894561218,
      },
      {
        images: images.escortimg10,
        name: "Florence J. Smith",
        location: "LONDON",
        age: 27,
        Phone: +165489652,
        location1: "London",
        Height: "5.2ft",
        Hourly: "$20",
        Email: "florencej.smith@gmail.com",
        rating: 4.5,
        region: "Durham Region",
        race: "White/Caucasian",
        price: 20,
        service: "Lorem Ipsum, Lorem Ipsum",
        Search: "Bandi2",
        _id: 154894561218,
      },
      {
        images: images.escortimg1,
        name: "Florence J. Smith",
        location: "LONDON",
        age: 27,
        rating: 4.5,
        price: 20,
        region: "Durham Region",
        race: "White/Caucasian",
        Phone: +165489652,
        location1: "London",
        Height: "5.2ft",
        Hourly: "$20",
        Email: "florencej.smith@gmail.com",
        service: "Lorem Ipsum, Lorem Ipsum",
        Search: "Bandi4",
        _id: 154894561218,
      },
      {
        images: images.blackwhitegirl,
        name: "Florence J. Smith",
        location: "LONDON",
        age: 27,
        rating: 4.5,
        Phone: +165489652,
        location1: "London",
        Height: "5.2ft",
        region: "Durham Region",
        race: "White/Caucasian",
        Hourly: "$20",
        Email: "florencej.smith@gmail.com",
        price: 20,
        service: "Lorem Ipsum, Lorem Ipsum",
        Search: "Bandi5",
        _id: 154894561218,
      },
    ]
  );

  const [escortimages1, setEscortimages1] = useState([
    {
      images: images.escortimg1,
      name: "Florence J. Smith",
      location: "LONDON",
      age: 27,
      rating: 4.5,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      region: "Durham Region",
      race: "White/Caucasian",
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi4",
      price: 20,
      _id: 154894561218,
    },
    {
      images: images.blackwhitegirl,
      name: "Florence J. Smith",
      location: "LONDON",
      age: 27,
      rating: 4.5,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      region: "Durham Region",
      race: "White/Caucasian",
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi5",
      price: 20,
      _id: 154894561218,
    },
  ]);

  const [escortimage2, setEscortimages2] = useState([
    {
      images: images.escortdetailimg,
      name: "Florence J. Smith",
      location: "LONDON",
      age: 27,
      rating: 4.5,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      region: "Durham Region",
      race: "White/Caucasian",
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi1",
      _id: 154894561218,
      price: 20,
    },
    {
      images: images.escortimg10,
      name: "Florence J. Smith",
      location: "LONDON",
      age: 27,
      rating: 4.5,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      region: "Durham Region",
      race: "White/Caucasian",
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi2",
      price: 20,
      _id: 154894561218,
    },
    {
      images: images.escortimg1,
      name: "Florence J. Smith",
      location: "LONDON",
      age: 27,
      Phone: +165489652,
      location1: "London",
      Height: "5.2ft",
      Hourly: "$20",
      Email: "florencej.smith@gmail.com",
      region: "Durham Region",
      race: "White/Caucasian",
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      Search: "Bandi4",
      price: 20,
      _id: 154894561218,
    },
  ]);

  const { isLoggedIn, Completeform, userloggedin } = useAuth();

  const [yellowBandidata, setYellowbandiData] = useState([
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
    {
      image: images.yellowbandi,
    },
  ]);

  const [addData, setAdddata] = useState([]);

  const filterEscortData = (data) => {
    try {
      return data.filter((escort) => {
        // Combine search parameters into a single string for searching
        const searchString = searchParams
          .map((param) => escort[param])
          .join(" ")
          .toLowerCase();
        // Check if the combined string includes the search query
        return searchString.includes(searchQuery.toLowerCase());
      });
    } catch {
      // console.error("NOdata");
    }
  };
  const searchData = (data) => {
    return data.filter((ad) => {
      const adSearchString = adSearchParams
        .map((param) => ad[param])
        .join(" ")
        .toLowerCase();
      return adSearchString.includes(searchQuery.toLowerCase());
    });
  };

  const resetFilters = () => {
    setAge(defaultAgeRange);
    setAgeFilterUsed(false);
    setAvailableInCall(false);
    setAvailableOutCall(false);
    setAvailableOnline(false);
    setLylaReviews(false);
    setHasVideo(false);
    setOfficialPhotoshoot(false);
    setHasPictures(false);
    setAvailableNow(false);
    setSelectedCity("");
    setSelectedEth("");
    setExtraFilters(false);
    setCheckIsVerified(false);
  };

  const handleAgeChange = (newAge) => {
    setAge(newAge);
    setAgeFilterUsed(true); // Set to true on any age change
  };


  const filterAdData = (ads) => {
    return ads.filter((ad) => {
      const matchesVideo = !hasVideo || (ad.videos && ad.videos.length > 0);
      const matchesPictures =
        !hasPictures || (ad.pictures && ad.pictures.length > 0);
      const matchesReviews = !lylaReviews || ad.show_lyla_ch_reviews;
      const matchesAvailability =
        availabilityAll === "All" ||
        (!avaialbleInCall && !availableOutCall && !availableOnline) ||
        (avaialbleInCall && ad.availability.includes("In-call")) ||
        (availableOutCall && ad.availability.includes("Out-call")) ||
        (availableOnline && ad.availability.includes("Online"));
      const normalizedAdPhoneNumber = ad.phone_number.replace(/\D/g, "");
      const normalizedInputPhoneNumber = phoneNumber.replace(/\D/g, "");
      const matchPhoneNumber =
        !phoneNumber || normalizedAdPhoneNumber === normalizedInputPhoneNumber;
      const matchesAge = extraFilters
        ? ad.age >= age[0] && ad.age <= age[1]
        : true;
      const matchesCity =
        selectedCity.length === 0 || selectedCity.includes(ad.city);
      const matchesCategories =
        selectedCategories.length === 0 || selectedCategories.includes(ad.cateogires);
      const matchesEthnicity =
        selectedEth.length === 0 || selectedEth.includes(ad.ethnicity);
      const matchesVerification = !checkIsVerified || ad.isVerified;

      // console.log("Filtering:", { ad, matchesVerification });

      return (
        matchesVideo &&
        matchesPictures &&
        matchesReviews &&
        matchesAvailability &&
        matchPhoneNumber &&
        matchesAge &&
        matchesCity &&
        matchesEthnicity &&
        matchesCategories &&
        matchesVerification
      );
    });
  };

  const handleCityChange = (city) => {
    setSelectedCity((prevCities) =>
      prevCities.includes(city)
        ? prevCities.filter((c) => c !== city)
        : [...prevCities, city]
    );
  };

  const handleEthnicityChange = (ethnicity) => {
    setSelectedEth((prevEthnicities) =>
      prevEthnicities.includes(ethnicity)
        ? prevEthnicities.filter((e) => e !== ethnicity)
        : [...prevEthnicities, ethnicity]
    );
  };

  const handleCategoriesChange = (categories) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(categories)
        ? prevCategories.filter((e) => e !== categories)
        : [...prevCategories, categories]
    );
  };

  const isAnyFilterActive = () => {
    // Check if any filter settings are active
    return (
      avaialbleInCall ||
      availableOutCall ||
      availableOnline ||
      checkIsVerified ||
      lylaReviews ||
      hasVideo ||
      officialPhotoshoot ||
      hasPictures ||
      availableNow ||
      phoneNumber.trim() !== "" ||
      selectedCity.length > 0 ||
      selectedEth.length > 0 ||
      selectedCategories.length > 0 ||
      (extraFilters && age[0] !== 18 && age[1] !== 30)
    ); // Assuming 18 and 30 are default values for age filter
  };

  useEffect(() => {
    fetchDataWithSearch(); // Call this function within useEffect or trigger based on specific conditions
  }, [
    searchQuery,
    avaialbleInCall,
    availableOutCall,
    availableOnline,
    lylaReviews,
    hasVideo,
    officialPhotoshoot,
    hasPictures,
    availableNow,
    phoneNumber,
    selectedCity,
    selectedEth,
    selectedCategories,
    extraFilters,
    age,
    checkIsVerified,
  ]);

  const defaultAgeRange = [18, 50]; // Assuming these are your default values

  // console.log(cities); // This will log all cities from the adsData array
  const uniqueCities = [...new Set(adDataCity)]; // Convert set back to array to get unique cities
  const uniqueCategories = [...new Set(adDataCategories)]; // Convert set back to array to get unique cities

  // const uniqueEthni =  [...new Set(adDataEnth.flat())] // Convert set back to array to get unique cities
  const uniqueEthni = [
    ...new Set(
      adDataEnth.flat().filter((ethnicity) => ethnicity.trim() !== "")
    ),
  ];

  // console.log("Eth dat", uniqueEthni);

  const isFilterActive = () => {
    const isAgeFilterActive =
      age[0] !== defaultAgeRange[0] || age[1] !== defaultAgeRange[1];
    return (
      availabilityAll ||
      avaialbleInCall ||
      availableOutCall ||
      availableOnline ||
      lylaReviews ||
      hasVideo ||
      officialPhotoshoot ||
      hasPictures ||
      availableNow ||
      isAgeFilterActive ||
      selectedCity ||
      checkIsVerified ||
      selectedCategories ||
      selectedEth
    );
  };

  const filteredEscortData = filterEscortData(escortimages);

  return (
    <div className="bg-purplegray ">
      <div className="flex pt-10 relative  justify-center   pb-20 lg:pb-0 ">
        <div className="flex flex-col w-74p items-center">
          <div className="flex lg:flex-row  lg:w-full  md:w-[710px]  sm:w-[600px] w-90p items-center justify-between">
            <div
              className=" gap-4 lg:flex hidden items-center justify-center  "
              onClick={() => setExtraFilters(!extraFilters)}
            >
              <div
                className="bg-purple  h-10 flex items-center justify-center w-10 cursor-pointer active:opacity-50  "
                onClick={() => setExtraFilters(true)}
              >
                <img src={images.filter} alt="" className="w-4 h-4" />
              </div>
            </div>
            <div className="flex flex-col items-end ">
              <div
                className={`lg:flex hidden bg-buttoncolor items-center lg:h-10 md:h-10 sm:h-10 h-7 lg:w-60 md:w-60 sm:w-54 w-40 lg:text-base md:text-base  sm:text-xs text-xs`}
              >
                <img
                  src={images.search}
                  alt=""
                  className="lg:w-4 lg:h-4 md:w-4 md:h-4 sm:w-4 sm:h-4 w-3 h-3 ml-5 mr-3 cursor-pointer active:opacity-50"
                />
                <input
                  type="text"
                  className="bg-buttoncolor focus:outline-none text-white w-full"
                  placeholder="Search "
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                ></input>
              </div>
              <div
                className=" gap-1 ml-2 text-nowrap flex-col     lg:hidden flex items-end justify-start  "
                onClick={() => setExtraFilters(true)}
              >
                <div className="bg-purple lg:h-10 md:h-8   sm:h-8 h-7 lg:hidden flex items-center justify-center lg:w-10 md:w-10 sm:w-7 w-7  cursor-pointer active:opacity-50">
                  <img
                    src={images.filter}
                    alt=""
                    className="lg:w-5 md:w-4   w-3 h-3 "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`w-full lg:hidden flex justify-center`}>
        <div
          className={`flex bg-buttoncolor items-center lg:h-10 md:h-10 sm:h-10 h-10 lg:w-90p md:w-[710px] sm:w-[610px] w-90p lg:text-base md:text-base  sm:text-xs text-xs`}
        >
          <img
            src={images.search}
            alt=""
            className="lg:w-4 lg:h-4 md:w-4 md:h-4 sm:w-4 sm:h-4 w-3 h-3 ml-5 mr-3 cursor-pointer active:opacity-50"
          />
          <input
            type="text"
            className="bg-buttoncolor focus:outline-none text-white w-full"
            placeholder="Search Location"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          ></input>
        </div>
      </div>
      <div className="flex w-full justify-center pt-5">
        {extraFilters && (
          <div className=" lg:w-74p w-90p text-whitepink lg:flex-row flex-col bg-buttoncolor flex p-2 sm:p-5 ">
            <div className="flex flex-col w-full   gap-2 pr-0 sm:pr-4 ">
              <div className="flex  flex-wrap gap-5 w-90p  justify-between pb-10 items-center  ">
                <div className="flex flex-col gap-3 ">
                  <div className="text-xl font-semibold">Ad Filter</div>
                  <div className="flex gap-2 flex-wrap">
                    {(avaialbleInCall ||
                      availableOutCall ||
                      availableOnline) && (
                      <div className="bg-purple px-2 rounded-xl  text-sm flex items-center">
                        Availability
                      </div>
                    )}
                    {(lylaReviews ||
                      hasVideo ||
                      officialPhotoshoot ||
                      hasPictures ||
                      availableNow) && (
                      <div className="bg-purple px-2  py-1 rounded-xl flex text-sm items-center">
                        Ad Details
                      </div>
                    )}
                    {selectedCity.length > 0 && (
                      <div className="bg-purple px-2 py-1  rounded-xl flex text-sm items-center">
                        Cities
                      </div>
                    )}
                    {selectedEth.length > 0 && (
                      <div className="bg-purple px-2  py-1 rounded-xl flex text-sm items-center">
                        Ethnicity
                      </div>
                    )}
                    {selectedCategories.length > 0 && (
                      <div className="bg-purple px-2  py-1 rounded-xl flex text-sm items-center">
                        Categories
                      </div>
                    )}
                    {ageFilterUsed && (
                      <div className="bg-purple px-2 py-1 rounded-xl text-sm flex items-center">
                        Age Range
                      </div>
                    )}
                    {phoneNumber && (
                      <div className="bg-purple px-2 py-1 rounded-xl text-sm flex items-center">
                        Phone Number
                      </div>
                    )}
                  </div>

                  <div className="font-bold">Availability</div>
                  <div className="flex gap-10 flex-wrap">
                    <div className="flex gap-3 text-sm items-center ">
                      <div className=" h-4 w-4">
                        <img
                          src={
                            availabilityAll === "All"
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() =>
                            setAvailabilityAll(
                              availabilityAll === "All" ? "" : "All"
                            )
                          }
                        />
                      </div>
                      All
                    </div>
                    <div className="flex gap-3 text-sm items-center">
                      <div className=" h-4 w-4">
                        <img
                          src={
                            avaialbleInCall
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => {
                            setAvailableInCall(!avaialbleInCall);
                            setAvailabilityAll("");
                          }}
                        />
                      </div>
                      Incall
                    </div>
                    <div className="flex gap-3 text-sm items-center">
                      <div className="h-4 w-4">
                        <img
                          src={
                            availableOutCall
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => {
                            setAvailableOutCall(!availableOutCall);
                            setAvailabilityAll("");
                          }}
                        />
                      </div>
                      Outcall
                    </div>
                    <div className="flex gap-3 text-sm items-center">
                      <div className="h-4 w-4">
                        <img
                          src={
                            availableOnline
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => {
                            setAvailableOnline(!availableOnline);
                            setAvailabilityAll("");
                          }}
                        />
                      </div>
                      Online
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="font-bold">Phone Number</div>
                  <div className="flex ">
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      className="text-white"
                      inputStyle={{
                        background: "transparent",
                        borderColor: "#635873",
                        borderWidth: "1px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      buttonStyle={{
                        background: "#312845",
                        borderColor: "#635873",
                        borderWidth: "1px",
                        borderRadius: "4px",
                      }}
                      dropdownStyle={{
                        background: "#312845",
                        ":hover": {
                          background: "#312845", // Same as normal background
                          borderColor: "#635873", // Same as normal border color
                        },
                      }}
                      onChange={setPhoneNumber}
                      disableDropdown={true}
                      country={"us"} // Default country set to 'us'
                      enableLongNumbers={true} // Allows longer numbers to be entered
                      autoFormat={true} // Automatically formats the input
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full justify-between gap-5  border-t border-whitepink border-opacity-20 pt-8 pb-4">
                <div className="w-full flex  gap-3 justify-center flex-col">
                  <div className="font-semibold">Age Range</div>
                  <div className="w-full items-center flex gap-3  justify-center">
                    <div className="border border-whitepink border-opacity-20 px-4 text-sm ">
                      {age[0]}
                    </div>
                    <RangeSlider
                      id="range-slider-yellow"
                      value={age}
                      onInput={handleAgeChange}
                      min={18}
                      max={50}
                      step={1}
                      onChange={(values) => handleAgeChange(values)}
                    />
                    <div className="border border-whitepink border-opacity-20 px-4 text-sm">
                      {age[1]}+
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-2 ">
                  <div className="font-semibold">Ad Details</div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-1">
                    <div className="flex gap-3 items-center text-xs sm:text-sm">
                      <div className="h-4 w-4 ">
                        <img
                          src={
                            lylaReviews
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => setLylaReviews(!lylaReviews)}
                        />
                      </div>
                      Must have Lyla Reviews
                    </div>
                    <div className="flex gap-3 items-center text-xs sm:text-sm">
                      <div className="h-4 w-4">
                        <img
                          src={
                            hasVideo
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => setHasVideo(!hasVideo)}
                        />
                      </div>
                      Must have a video
                    </div>
                    <div className="flex gap-3 items-center text-xs sm:text-sm">
                      <div className="h-4 w-4 ">
                        <img
                          src={
                            checkIsVerified
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => setCheckIsVerified(!checkIsVerified)}
                        />
                      </div>
                      Verified Ads Only
                    </div>

                    <div className="flex gap-3 items-center text-xs sm:text-sm ">
                      <div className="h-4 w-4">
                        <img
                          src={
                            officialPhotoshoot
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() =>
                            setOfficialPhotoshoot(!officialPhotoshoot)
                          }
                        />
                      </div>
                      Pleasury Official Photoshoot
                    </div>
                    <div className="flex gap-3 items-center text-xs sm:text-sm">
                      <div className="h-4 w-4 ">
                        <img
                          src={
                            hasPictures
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50"
                          onClick={() => setHasPictures(!hasPictures)}
                        />
                      </div>
                      Must have pictures
                    </div>
                    <div className="flex gap-3 text-xs sm:text-sm items-center">
                      <div className="h-4 w-4">
                        <img
                          src={
                            availableNow
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                          className="cursor-pointer active:opacity-50 "
                          onClick={() => setAvailableNow(!availableNow)}
                        />
                      </div>
                      Available Now
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex w-full justify-between gap-3">
                <div
                  className="bg-purple flex items-center justify-center 
                  cursor-pointer active:opacity-50 py-1 sm:text-base text-xs sm:py-2 w-full font-semibold"
                  onClick={() => setExtraFilters(false)}
                >
                  Show Ads
                </div>
                <div
                  className="bg-transparent border border-whitepink border-opacity-40 flex items-center justify-center 
                  cursor-pointer active:opacity-50 py-1 sm:text-base text-xs text-nowrap px-2 sm:py-2 w-40p font-semibold"
                  onClick={resetFilters}
                >
                  Reset Filters
                </div>
              </div>
            </div>
            <div className="pt-6 flex flex-col gap-2 lg:border-l border-whitepink w-full lg:w-40p border-opacity-40">
              <div className="flex flex-col gap-3  lg:pl-9 font-semibold ">
                <div className="border-b border-whitepink border-opacity-40 py-2">
                  Cities
                </div>
                <div
                  className="text-xs overflow-auto gap-2 border-b border-whitepink border-opacity-40 h-[100px]
                show-scrollbar pb-3"
                >
                  {uniqueCities.map((city, index) => (
                    <div
                      key={index}
                      onClick={() => handleCityChange(city)}
                      className="flex items-center gap-3 pt-2 cursor-pointer active:opacity-50 pl-1"
                    >
                      <div className="w-4 h-4">
                        <img
                          src={
                            selectedCity.includes(city)
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                        />
                      </div>
                      <div>{city}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3  lg:pl-9 font-semibold ">
                <div className="border-b border-whitepink border-opacity-40 py-2">
                  Ethnicity
                </div>
                <div
                  className="text-xs overflow-auto gap-2 border-b border-whitepink border-opacity-40 h-[100px]
                show-scrollbar pb-3"
                >
                  {uniqueEthni.map((ethnicity, index) => (
                    <div
                      key={index}
                      onClick={() => handleEthnicityChange(ethnicity)}
                      className="flex items-center cursor-pointer active:opacity-50 gap-2 pt-2 pl-1"
                    >
                      <div className="h-4 w-4 ">
                        <img
                          src={
                            selectedEth.includes(ethnicity)
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                        />
                      </div>
                      <div>{ethnicity}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3  lg:pl-9 font-semibold ">
                <div className="border-b border-whitepink border-opacity-40 py-2">
                  Categeories
                </div>
                <div
                  className="text-xs overflow-auto gap-2 border-b border-whitepink border-opacity-40 h-[100px]
                show-scrollbar pb-3"
                >
                  {uniqueCategories.map((categories, index) => (
                    <div
                      key={index}
                      onClick={() => handleCategoriesChange(categories)}
                      className="flex items-center cursor-pointer active:opacity-50 gap-2 pt-2 pl-1"
                    >
                      <div className="h-4 w-4 ">
                        <img
                          src={
                            selectedCategories.includes(categories)
                              ? images.filterChecked
                              : images.filterunchecked
                          }
                          alt=""
                        />
                      </div>
                      <div>{categories}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="max-w-screen-xl mx-auto flex flex-col  items-start px-5 mt-10  ">
        <div className="w-full flex flex-col items-center  ">
          {activePath === "/Escorts/All" && (
            <div>
              {isLoading ? (
                <div className="w-full flex items-center justify-center pb-20">
                  <ClipLoader
                    color="#BE42DE"
                    cssOverride={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <EscortAll
                escortimages={
                  showMore
                    ? filteredEscortData // Show all data when 'Show More' is active
                    : filteredEscortData?.slice(0, 10) // Limit to first 10 items otherwise
                }
                addData={addData}
              />
            </div>
          )}
          {activePath === "/Escorts/AvailableToday" && (
            <div>
              <EscortAvailable
                yellowBandidata={yellowBandidata}
                escortimages1={escortimages1}
              />
            </div>
          )}
          {activePath === "/Escorts/AvailableNow" && (
            <div>
              <Escortavailablenow
                yellowBandidata={yellowBandidata}
                escortimage2={escortimage2}
              />
            </div>
          )}
        </div>
      </div>

      <div className="bg-purplegray flex justify-center font-bold lg:text-base md:text-sm sm:text-sm text-xxs pt-20 pb-20">
        {filteredEscortData ? (
          <>
            {showMore ? (
              <div
                className="bg-whitepink w-40 py-3 flex items-center justify-center hover:bg-purple cursor-pointer active:opacity-50 text-nowrap"
                onClick={() => setShowmore(false)} // When clicking "Show Less"
              >
                Show Less
              </div>
            ) : (
              <div
                className="bg-whitepink w-40 py-3 flex items-center justify-center hover:bg-purple cursor-pointer active:opacity-50 text-nowrap"
                onClick={() => setShowmore(true)} // When clicking "View More"
              >
                View More
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
