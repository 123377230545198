import React, { useRef, useState } from "react";
import images from "../../services/images";
import ImageSlider from "../ImageSlider";
import { useModal } from "../../screens/Layout";
import ResponsiveImageGrid from "../cards/ResponsiveImageGrid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../services/Authcontext/Authcontext";
import { useDispatch, useSelector } from "react-redux";
import { selectLogedIn } from "../../store/logedIn";
import { selectToken } from "../../store/token";
import "../../App.css";

export default function HomeIntro({ loggedin }) {
  const imageGridRef = useRef();

  const { setIsModalOpen, setmodalcomponent } = useModal();

  const dispath = useDispatch();
  const userLogin = useSelector(selectLogedIn);
  const authToken = useSelector(selectToken);

  const [imagesArray, setImagesArray] = useState([
    {
      images: images.escortimg1,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    // {
    //   images: images.silder3,
    //   name: "Florence J. Smith",
    //   location: "LONDON",
    //   location1: "London",
    //   age: 27,
    //   rating: 4.5,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    //   Email: "florencej.smith@gmail.com",
    //   Phone: +165489652,
    //   Height: "5.2ft",
    //   Hourly: "$20",
    // },
    {
      images: images.silder4,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.silder1,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.silder5,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.silder3,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    {
      images: images.escortimg2,
      name: "Florence J. Smith",
      location: "LONDON",
      location1: "London",
      age: 27,
      rating: 4.5,
      service: "Lorem Ipsum, Lorem Ipsum",
      _id: 154894561218,
      Email: "florencej.smith@gmail.com",
      Phone: +165489652,
      Height: "5.2ft",
      Hourly: "$20",
    },
    //     {
    //   images: images.silder6,
    //   name: "Florence J. Smith",
    //   location: "LONDON",
    //   location1: "London",
    //   age: 27,
    //   rating: 4.5,
    //   service: "Lorem Ipsum, Lorem Ipsum",
    //   _id: 154894561218,
    //   Email: "florencej.smith@gmail.com",
    //   Phone: +165489652,
    //   Height: "5.2ft",
    //   Hourly: "$20",
    // },
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const { isLoggedIn, escortloggedin } = useAuth();

  // const [displayedImages, setDisplayedImages] = useState(
  //   imagesArray.slice(0, 4)
  // );

  // const changeImages = () => {
  //   const newIndex = (currentIndex + 1) % (imagesArray.length - 3);
  //   setCurrentIndex(newIndex);
  //   setDisplayedImages(imagesArray.slice(newIndex, newIndex + 4));
  // };

  const handleJoinNowClick = () => {
    setmodalcomponent("signup");
    setIsModalOpen(true);
    // Use navigate to push the route
  };
  const handlePrevious = () => {
    imageGridRef.current.handlePrevious();
  };

  const handleNext = () => {
    imageGridRef.current.handleNext();
  };

  return (
    <div className="bg-purplegray md:mt-5 mt-20">
      <div
        className="bg-purplegray px-7 py-0 sm:py-10 md:py-5 bg-no-repeat pt-4 bg-cover bg-center relative  h-screen  homeBg mt:10 "
        // style={{
        //   backgroundImage: `url(${images.headerBG})`,
        //   alignItems: "center",
        //   backgroundColor: "#1A112E",
        //   backgroundSize: "100% 35vh",
        //   backgroundPosition: "center", // Add this
        //   height: "80vh", // Add thi
        // }}
      >
        <div className="w-full flex flex-col lg:w-full md:mt-10 ">
          <div className="text-white w-100px sm:w-70p lg:w-70p xl:w-100p text-left justify-center font-bold">
            <div className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl">
              <span className="text-purple">Uncover</span> the Pleasure,
            </div>
            <div className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl">
              Unbox the <span className="text-purple">Fun</span>
            </div>
          </div>
          <div className="w-full ">
            <div className="flex text-white w-full sm:w-70p md:w-65p lg:w-55p mt-5 lg:text-2xl md:text-lg sm:text-lg text-lg justify-center lg:text-left ">
              Embark on an exhilarating journey with our website, boasting a
              cutting-edge escorts advertisement system alongside seamless live
              streaming.
            </div>
            <div className="lg:hidden md:flex flex sm:flex md:justify-start   lg:justify-start text-center ">
              {/* {isLoggedIn ? (
              <>
                <div
                  className="bg-whitepink  pl-3   flex  lg:text-base md:text-base sm:text-sm text-xs  justify-center lg:w-60  md:w-60 sm:w-60 w-32  lg:py-4 md:py-4 sm:py-4 py-2 pr-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50"
                  onClick={handleJoinNowClick}
                >
                  JOIN NOW
                </div>
              </>
            ) : (
              <>
                {escortloggedin ? (
                  <div className="w-full flex justify-center">
                    <Link to={"/forms"}
                     className="bg-whitepink pl-3  flex  lg:text-base md:text-base sm:text-sm text-sm  justify-center lg:w-60  md:w-60 sm:w-60 w-32  lg:py-4 md:py-4 sm:py-4 py-2 pr-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50"
                    >
                      JOIN NOW
                    </div>
                  </>
                ) : (
                  <>
                    {!userLogin ? (
                      <div className="w-full">
                        <Link
                          to={"/forms"}
                          className="bg-whitepink  flex justify-center w-24 text-sm md:w-60 py-3 sm:py-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50"
                        >
                          POST AD
                        </Link>
                      </div>
                    ) : (
                      <div className="w-full">
                        <Link to={"/LiveModels/Newest"}>
                          <div className="bg-whitepink  flex justify-center w-24 text-sm md:w-60 py-3 sm:py-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50">
                            Join stream
                          </div>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </>
            )} */}
            </div>
            <div className="w-full pt-10">
              <div className="flex md:justify-start  lg:justify-start  ">
                {!authToken ? (
                  <>
                    <div
                      className="bg-whitepink   text-center px-10 py-3 rounded-sm font-bold mt-10 cursor-pointer active:opacity-50 text-sm"
                      onClick={handleJoinNowClick}
                    >
                      JOIN NOW
                    </div>
                  </>
                ) : (
                  <>
                    {!userLogin ? (
                      <div className="w-full">
                        {/* <Link to={"/forms"}> */}
                        <Link
                          to={"/forms"}
                          className="bg-whitepink  text-center px-10 py-3 rounded-sm font-bold mt-10 cursor-pointer active:opacity-50 text-sm"
                        >
                          POST AD
                        </Link>
                        {/* </Link> */}
                      </div>
                    ) : (
                      <div className="w-full flex">
                        <Link to={"/LiveModels/Newest"}>
                          <div className="bg-whitepink  flex justify-center px-10 py-3  text-sm text-center rounded-sm font-bold cursor-pointer active:opacity-50">
                            Join stream
                          </div>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="flex sm:mb-5 sm:flex flex  flex-row  justify-end pr-2 mt-5  ">
                <div
                  className="ml-2 text-sm bg-purple text-black rounded-sm h-10 w-10  sm:w-16 sm:h-14 font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                  onClick={handlePrevious}
                >
                  <img src={images.arrowleft} className="w-4 max-w-4" />
                </div>
                <div
                  className="ml-2 text-sm bg-whitepink text-black rounded-sm h-10 w-10 sm:w-16 sm:h-14  font-semibold flex items-center justify-center cursor-pointer transition-colors duration-300 active:bg-purple active:text-white active:opacity-50"
                  onClick={handleNext}
                >
                  <img src={images.arrowright} className="w-4 max-w-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full">
          <div className="md:justify-start lg:pt-0 md:pt-0 sm:pt-20 pt-20 lg:pb-0 md:pb-0 sm:pb-20 pb-20 lg:justify-start text-center ">
            {!authToken ? (
              <>
                <div
                  className="bg-whitepink  flex pl-1 justify-center lg:text-base md:text-base sm:text-sm text-sm  lg:w-60  md:w-60 sm:w-60 w-40  lg:py-4 md:py-4 sm:py-4 py-3 lg:pr-4 md:pr-4 sm:pr-0 pr-0 text-center rounded-sm font-bold cursor-pointer active:opacity-50"
                  onClick={handleJoinNowClick}
                >
                  JOIN NOW
                </div>
              </>
            ) : (
              <>
                {!userLogin ? (
                  <div className="w-full">
              
                    <Link
                      to={"/forms"}
                      className="bg-whitepink  flex justify-center w-40 md:w-60 py-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50"
                    >
                      POST AD
                    </Link>
                 
                  </div>
                ) : (
                  <div className="w-full">
                    <Link to={"/LiveModels/Newest"}>
                      <div className="bg-whitepink  flex pl-1 justify-center w-60 py-4 pr-4 text-center rounded-sm font-bold cursor-pointer active:opacity-50">
                        Join stream
                      </div>
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="ml-2 text-sm active:bg-purple rounded-sm bg-whitepink text-black   lg:w-14 lg:h-14 w-10 h-10  font-semibold flex items-center justify-center cursor-pointer active:opacity-50"
            // onClick={changeImages}
            onClick={handlePrevious}
          >
            <img src={images.arrowright} className="w-4" />
          </div>
        </div> */}
        <div className="lg:pt-0 md:pt-0 sm:pt-6 pt-6 ">
          <ImageSlider images={imagesArray} ref={imageGridRef} />
        </div>
      </div>
      <div className="flex biggerlaptop:pb-40 lg:pb-40 md:pb-20 sm:pb-20 pb-10  bg-purplegray "></div>
    </div>
  );
}
